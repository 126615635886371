import React, { createContext, useState, useEffect, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { API, FREE_MINUTES, FREE_TRIAL_DURATION, REFERRAL_CODE_USED_KEY, dbRefs, stripeSubscriptionStatusStates, SLACK_WEBHOOK_CHANNELS } from '../misc/constants';
import axios from 'axios';
import { generateReferralCode, sendSlackNotification, validateEmail } from '../misc/utils';
import mixpanel from 'mixpanel-browser';

// Create the context
export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [ userLoaded, setUserLoaded ] = useState(false)
  const [ openPermissionDeniedModal, setOpenPermissionDeniedModal ] = useState(false)
  const auth = getAuth();
  const isActiveSubscription = (userData) => userData?.stripeSubscription?.status === stripeSubscriptionStatusStates.active;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      await handleAuthStateChange(firebaseUser);
      setUserLoaded(true);
    });
    
    return () => unsubscribe();
  }, [auth]);


  //////////////////// HANDLE AUTH STATE CHANGE ////////////////////

  const handleAuthStateChange = async (firebaseUser) => {
    try {
      if (!firebaseUser) {
        throw new Error('No Firebase user found');
      }

      const emailCheck = validateEmail(firebaseUser.email);
      if ( !emailCheck ) {
        alert(`Your email is not permitted. It may be invalid or not allowed by our system. Please use a different email. Email ryan@effortlessnotes.com if you need help or believe this is an error.`)
        await sendSlackNotification(`Invalid Email Sign In Blocked:\nattempted: ${firebaseUser?.email}`, SLACK_WEBHOOK_CHANNELS.signups);
        await logout();
        return;
      }
    
      const localStorage_id = localStorage.getItem('_id');
      const isUniqueAccount = await checkUserForUniqueAccount(firebaseUser, localStorage_id);
      await getUserData(firebaseUser, localStorage_id, isUniqueAccount);
    }
    catch (err) {
      await logout()
      console.error(err);
    }
  };


  const checkUserForUniqueAccount = async (firebaseUser, localStorage_id) => {
    try {

      if ( localStorage_id !== firebaseUser.uid ) {
 
        localStorage.removeItem('_id');

        const [fbUser, localStorageUser ] = await Promise.all([ 
          fetchUserData(firebaseUser.uid),
          fetchUserData(localStorage_id),
        ]);

        if ( !isActiveSubscription(fbUser) && !isActiveSubscription(localStorageUser) ) {

          if ( localStorageUser && localStorageUser._id ) {
            await triggerDuplicateAccountAlert(firebaseUser, localStorageUser);
            return false;
          }
        }
      }

      return true;
    }
    catch (err) {
      console.error(err);
      return true;
    }
  }


  const triggerDuplicateAccountAlert = async (firebaseUser, localStorageUser) => {
    try {
      await Promise.all([
        sendSlackNotification(`Duplicate User Sign In Detected:\nnew: ${firebaseUser?.email}\nexisting: ${localStorageUser?.email}`, SLACK_WEBHOOK_CHANNELS.paywall),
        axios.put(`${API}/generalUpdateOne`, {
          matchObj: { email: firebaseUser.email, ['stripeSubscription.status']: { $ne: stripeSubscriptionStatusStates.active} },
          updateObj: { $set: { ['stripeSubscription.status']: stripeSubscriptionStatusStates.duplicate_account }, $unset: { freeTrialExpiration: 1 } },
          dbRef: dbRefs.users,
        })
      ]);

      alert(`You already created an account with: ${localStorageUser.email}. You may use this account but it won't be eligible for the free trial. Email ryan@effortlessnotes.com if you need help or believe this is an error.`)
    }
    catch (err) {
      console.error(err);
    }
  }

  
  //////////////////// GET USER DATA ////////////////////

  const fetchUserData = async (_id) => {
    try {
      let result = await axios.get(`${API}/generalFindOne`, { params: { queryObj: JSON.stringify({ _id: _id }), dbRef: dbRefs.users }})
      let results = result.data
      return results
    }
    catch (err) {
      console.error(err);
      return null;
    }
  }

  const getUserData = async (firebaseUser, localStorage_id, isUniqueAccount = true ) => {
    
    if ( !localStorage_id ) { localStorage.setItem('_id', firebaseUser.uid); }

    try {
      let results = await fetchUserData(firebaseUser.uid)

      if ( results && results._id && results.email ) {
        setUser({ ...results, isMember: isActiveSubscription(results) });
        mixpanelIdentifyUser(results)
        mixpanel.track('User Signed In', { email: results.email, createdAt: results.createdAt });
        console.log("existing user: ", results._id)

        if ( !results.freeTrialExpiration && results.stripeSubscription.status === stripeSubscriptionStatusStates.free_trial && isUniqueAccount ) {
          const freeTrialExpiration = Date.now() + FREE_TRIAL_DURATION;
          console.log("Now", Date.now(), "expiration", freeTrialExpiration, "duration", FREE_TRIAL_DURATION, "the difference", freeTrialExpiration - Date.now())

          await axios.put(`${API}/generalUpdateOne`, {
            matchObj: { _id: results._id },
            updateObj: { $set: { freeTrialExpiration: freeTrialExpiration } },
            dbRef: dbRefs.users,
          })
          setUser({ ...results, freeTrialExpiration: freeTrialExpiration, isFirstTimeUser: true })
        }
      }
      else {
        createUser(firebaseUser, isUniqueAccount)
        console.log("new user: ", firebaseUser.uid)
      }
      
    }
    catch (err) {
      console.error(err);
    }
  }

  const createUser = async (firebaseUser, isUniqueAccount = true) => {
    const referralCode = generateReferralCode();
    const referralCodeUsed = localStorage.getItem(REFERRAL_CODE_USED_KEY) || '';
    const statusEffective = isUniqueAccount ? stripeSubscriptionStatusStates.free_trial : stripeSubscriptionStatusStates.duplicate_account;
    const freeTrialExpiration = isUniqueAccount ? Date.now() + FREE_TRIAL_DURATION : 0;

    try {

      let userDoc = {
        _id: firebaseUser.uid,
        email: firebaseUser.email,
        emailVerified: firebaseUser.emailVerified,
        createdAt: firebaseUser.metadata.createdAt,
        categories: [],
        stripeSubscription: {
          status: statusEffective,
          minutesUsed: 0,
          plan: {
            minutes: FREE_MINUTES,
          }
        },
        referralCode: referralCode,
        referralCodeUsed: referralCodeUsed,
        emailSentCount: 0,
        termsAccepted: Date.now(),
        freeTrialExpiration: freeTrialExpiration,
      }

      const message = `New user created\nEmail: ${userDoc?.email}\nUnique: ${isUniqueAccount}`;

      await Promise.all([
        axios.post(`${API}/generalInsertOne`, { doc: userDoc, dbRef: dbRefs.users }),
        sendSlackNotification(message, SLACK_WEBHOOK_CHANNELS.signups),
      ]);

      mixpanelIdentifyUser(userDoc)
      mixpanel.track('User Created', {
        email: userDoc.email,
        createdAt: userDoc.createdAt,
      });

      setUser({ ...userDoc, isFirstTimeUser: true, isMember: false });
      localStorage.removeItem(REFERRAL_CODE_USED_KEY);
      console.log("created user")
    }
    catch (err) {
      console.error(err);
    }
  }

  const mixpanelIdentifyUser = useCallback((userObj) => {
    mixpanel.identify(userObj._id);
    mixpanel.people.set({
      $email: userObj.email,
      $created: userObj.createdAt,
      'Subscription Status': userObj.stripeSubscription.status,
    });
  }, []);

  const logout = useCallback(async () => {
    try {
      await auth.signOut();
      setUser(null);
      mixpanel.reset()
    } 
    catch (error) {
      console.error(error);
    }
  }, [auth]);


  const checkUserPermission = () => {
    if (user?.isMember) return true;
    
    const userStatus = user?.stripeSubscription?.status;
    const freeTrialExpiration = user?.freeTrialExpiration;
    const isFreeTrialActive = Date.now() < freeTrialExpiration;
    
    const isAllowed = userStatus === stripeSubscriptionStatusStates.free_trial && isFreeTrialActive;
    
    if (!isAllowed) {
      setOpenPermissionDeniedModal(true);
    }
    
    return isAllowed;
  };


  return (
      <UserContext.Provider value={{ 
          user, setUser,
          userLoaded, setUserLoaded,
          logout,
          openPermissionDeniedModal, setOpenPermissionDeniedModal,
          checkUserPermission,
        }}>
          {children}
      </UserContext.Provider>
  );
};








    // // Check if it's a sign-in with email link operation
    // if (isSignInWithEmailLink(auth, window.location.href)) {
    //   let storedEmail = window.localStorage.getItem('emailForSignIn');
    //   if (!storedEmail) {
    //     storedEmail = window.prompt('Please provide your email for confirmation');
    //   }
  
    //   signInWithEmailLink(auth, storedEmail, window.location.href)
    //     .then((result) => {
    //       window.localStorage.removeItem('emailForSignIn');
    //     console.log(result)        
  
    //       if (result?._tokenResponse?.isNewUser) {
    //         createUser(result.user);
    //         console.log("new user: ", result.user.uid);
    //       } else {
    //         console.log("Existing user signing in: ", result.user.uid);
    //         getUserData(result.user);
    //       }
  
    //     })
    //     .catch((error) => {
    //       console.error("Error signing in: ", error.message);
    //     });
  
    //   // Return early as we don't need to set up onAuthStateChanged
    //   return;
    // }