import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../contexts/UserProvider';
import ScreenHeader from '../components/ScreenHeader';
import Essay_Evaluation from '../components/Essay_Evaluation';
import Essay_EvaluationInput from '../components/Essay_EvaluationInput2';

const EssayEvaluationScreen = () => {
  const { user, } = useContext(UserContext)
  const tabs = { input: "Input Essay", evaluation: "Evaluation" }
  const [ activeTab, setActiveTab ] = useState(tabs.input);
  const [ responses, setResponses ] = useState([]);

  return (
    <div id="wrapper">          
      <div id="main">
        <div className="inner" >

          <ScreenHeader title="Essay Evaluation" />

          <Essay_EvaluationInput 
              isActive={activeTab === tabs.input} 
              responses={responses} 
              setResponses={setResponses} 
              tabs={tabs}
              setActiveTab={setActiveTab}
            />

            <Essay_Evaluation 
              isActive={activeTab === tabs.evaluation} 
              responses={responses} 
              tabs={tabs}
              setActiveTab={setActiveTab}
            />
          
        </div>
      </div>
    </div>
  );  
}


export default EssayEvaluationScreen;




