import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { UserContext } from '../contexts/UserProvider';
import ScreenHeader from '../components/ScreenHeader';
import { API, RESOURCES_UPLOAD_METHODS, SEARCH_PARAMS, dbRefs } from '../misc/constants';
import { useBreakpoint } from '../misc/useBreakpoint';
import Teach_Message from '../components/Teach_Message';
import axios from 'axios';
import Teach_Footer from '../components/Teach_Footer';
import Teach_Sidebar from '../components/Teach_Sidebar';
import { OPENAI_CHAT_ROLES } from '../misc/constants';
import ChatGptInterface from '../components/ChatGPTInterface';
import { getTextFromResource, initResourceObj, uploadResourceToS3 } from '../misc/utils';
import { useSearchParams } from 'react-router-dom';

const FOOTER_HEIGHT = "5.5rem"

const MessagesInterface = ({ mindMap, activeMindMapIndex, messagesStartRef, messagesEndRef, isPhoneAndBelow }) => {

  useEffect(() => {
    if ( messagesStartRef.current ) {
      messagesStartRef.current.scrollIntoView({ block: 'start' });
    }
  }, [activeMindMapIndex])


  if ( activeMindMapIndex === null || mindMap?.[activeMindMapIndex]?.messages?.length === 0 ) return null

  return (
    <>
      <div ref={messagesStartRef} />
      {mindMap[activeMindMapIndex].messages.map((message, index) => (
        <Teach_Message key={index} item={message} index={index} isPhoneAndBelow={isPhoneAndBelow} />
      ))}
      <div ref={messagesEndRef} />
    </>
  )
}

const TeachScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams();  // Add the setter
  const [ activeDocumentId, setActiveDocumentId ] = useState('')
  const { user, checkUserPermission } = useContext(UserContext)
  const { isPhoneAndBelow, } = useBreakpoint()
  

  const [ mindMap, setMindMap ] = useState([])
  const [ activeMindMapIndex, setActiveMindMapIndex ] = useState(0)
  const [ loadingResponse, setLoadingResponse ] = useState(false)
  const [ loadingMindMap, setLoadingMindMap ] = useState(false)
  const [ error, setError ] = useState(false);
  const messagesStartRef = useRef(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    setActiveDocumentId(searchParams.get(SEARCH_PARAMS._id) || '')
    console.log('activeDocumentId', activeDocumentId)
  }, [searchParams])

  useEffect(() => {
    getData()
  }, [activeDocumentId])

  useEffect(() => {
    if ( mindMap?.[activeMindMapIndex]?.messages && mindMap?.[activeMindMapIndex]?.messages?.length === 0) {
      handleSubmit({ role: OPENAI_CHAT_ROLES.user, content: "Begin" })
    }
  }, [mindMap, activeMindMapIndex])

  const getData = async () => {
    try {
      if ( activeDocumentId ) {
        const result = await axios.get(`${API}/generalFindOne`, { params: { queryObj: JSON.stringify({ _id: activeDocumentId }), dbRef: dbRefs.resources }})
        const results = result?.data
        setMindMap(results?.teach?.content)
      }
    }
    catch (error) {
      setMindMap([])
      throw error
    }
  }


  const handleCreateContent = async ({ resourceObj, file, textInput }) => {      
    if (!checkUserPermission()) return;

    try {
      setLoadingMindMap(true)
      let topic = ''
      let text = ''

      if ( resourceObj ) {
        if ( resourceObj?.teach?.content ) {
          setActiveDocumentId(resourceObj._id)
          return
        }
        else {
          text = await getTextFromResource(resourceObj)
          await axios.post(`${API}/generateTeach`, { text: text, topic: topic, resource_id: resourceObj._id });
          setActiveDocumentId(resourceObj._id)
          return
        }
      }

      else if ( file ) {
        const doc = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
        const resource = await uploadResourceToS3({ resource_id: doc._id, file, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
        const sourceContent = await axios.post(`${API}/generateSourceContent`, { resource_id: doc._id, userCategories: user.categories  })
        text = sourceContent?.data?.content
        await axios.post(`${API}/generateTeach`, { text: text, topic: topic, resource_id: doc._id });
        setActiveDocumentId(doc._id)
        return
      }
      else if ( textInput ) {
        
        const doc = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
        const resource = await uploadResourceToS3({ resource_id: doc._id, file: textInput, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
        const sourceContent = await axios.post(`${API}/generateSourceContent`, { resource_id: doc._id, userCategories: user.categories  })
        topic = textInput
        await axios.post(`${API}/generateTeach`, { text: text, topic: topic, resource_id: doc._id });
        setActiveDocumentId(doc._id)
        return
      }
      
    } 
    catch (error) {
      throw error
    }
    finally {
      setLoadingMindMap(false)
    }
  }

  const handleSubmit = async (newMessage, clearMessages = false) => {
    setLoadingResponse(true);
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })

    const currentMindMap = mindMap[activeMindMapIndex];
    const currentMessages = currentMindMap.messages;
    const updatedMessages = clearMessages ? [newMessage] : [...currentMessages, newMessage];
    
    // Add empty assistant message placeholder
    const initialMindMap = mindMap.map((item, idx) =>
      idx === activeMindMapIndex 
        ? { ...item, messages: [...updatedMessages, { role: OPENAI_CHAT_ROLES.assistant, content: '' }] } 
        : item
    );
    setMindMap(initialMindMap);
    const TIMEOUT_DURATION = 8000;
    let timeoutId;

    try {
      const response = await fetch(`${API}/streamTeach`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          user_id: user?._id, 
          all_topics: mindMap.map(item => item.title),
          current_topic: currentMindMap.title, 
          current_topic_description: currentMindMap.description, 
          messages: updatedMessages 
        }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedContent = ''; // Keep track of the full message

      const processStream = async () => {
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          
          const chunk = decoder.decode(value);
          const lines = chunk.split('\n');
          
          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const json = line.replace('data: ', '');
              if (json.trim() !== '') {
                const data = JSON.parse(json);
                if (data.error) {
                  throw new Error('Server error');
                }

                // Reset timeout
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                  throw new Error('Response timeout');
                }, TIMEOUT_DURATION);

                // Update accumulated content
                accumulatedContent += data.content || '';

                // Update state with the full accumulated content
                setMindMap((prevMindMap) => {
                  const updatedMindMap = [...prevMindMap];
                  const currentMessages = updatedMindMap[activeMindMapIndex].messages;
                  const lastMessage = currentMessages[currentMessages.length - 1];
                  if (lastMessage.role === OPENAI_CHAT_ROLES.assistant) {
                    lastMessage.content = accumulatedContent; // Set the complete content
                  }
                  return updatedMindMap;
                });
              }
            }
          }
        }
      };

      timeoutId = setTimeout(() => {
        throw new Error('Initial response timeout');
      }, TIMEOUT_DURATION);

      await processStream();
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      handleError();

    } finally {
      clearTimeout(timeoutId);
      setLoadingResponse(false);
    }
  };

  const handleError = () => {
    setError(true);
  }

  const resetStates = () => {
    setActiveDocumentId('')
    setMindMap([])
    setActiveMindMapIndex(0)
    searchParams.delete(SEARCH_PARAMS._id)
    setSearchParams(searchParams)
  }

  const LoadingComponent = ({ loadingMindMap }) => {
    if ( loadingMindMap ) return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', alignSelf: 'center' }}>
        <div style={{ width: '30px', height: '30px', borderTop: '3px solid var(--color-primary)', borderRadius: '50%', animation: 'spin 1s linear infinite', marginTop: '1.5rem' }}>
          <style>{`@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }`}</style>
        </div>
      </div>
    )
    return null
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }


  return (
    <div id="wrapper" style={{ backgroundColor: "var(--color-backgroundSecondary)", position: "relative", display: "flex", flexDirection: "row", }}>          

      <ChatGptInterface
        // onSubmitText={(item) => handleCreateContent({ textInput: item })}
        // onSubmitFile={(item) => handleCreateContent({ file: item })}
        // onSubmitResource={(item) => handleCreateContent({ resourceObj: item })}
        onSubmit={handleCreateContent}
        isActive={mindMap.length === 0 && !loadingMindMap} 
        header="What do you want to learn?"
        placeholder="Teach me about..."
      />

      <LoadingComponent loadingMindMap={loadingMindMap} />

      { mindMap && mindMap?.[activeMindMapIndex]?.messages && mindMap?.[activeMindMapIndex]?.messages?.length > 0 && (
        <>

          <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", }}>

            <div id="main" style={{ overflowY: 'auto', paddingBottom: 0, flex: 1}}>
              <div style={{ margin: '0 auto', flex: 1, display: 'flex', flexDirection: 'column', minHeight: '100%', position: 'relative', width: "100%" }}>
              
                <ScreenHeader title="Teach Me" />

                <MessagesInterface 
                  mindMap={mindMap} 
                  activeMindMapIndex={activeMindMapIndex} 
                  messagesStartRef={messagesStartRef} 
                  messagesEndRef={messagesEndRef} 
                  isPhoneAndBelow={isPhoneAndBelow} 
                />
                
              </div>
            </div>

            <Teach_Footer 
              handleSubmit={handleSubmit} 
              loadingResponse={loadingResponse} 
              scrollToBottom={scrollToBottom} 
              FOOTER_HEIGHT={FOOTER_HEIGHT} 
            />

          </div>

          <Teach_Sidebar 
            mindMap={mindMap} 
            activeMindMapIndex={activeMindMapIndex} 
            setActiveMindMapIndex={setActiveMindMapIndex} 
            resetStates={resetStates} 
            FOOTER_HEIGHT={FOOTER_HEIGHT} 
          />

        </>
      )}

    </div>
  );  
}


export default TeachScreen;



