import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserProvider';
import { Routes } from './routes';
import { useState } from 'react';
import { NotebookPen, FileText, MessageCircleMore, Cog, GraduationCap, DollarSign, HandCoins, ArrowUp, ListCheck, FileCheck, SquarePen, ChevronLeft, Menu, MessageSquareQuote, BookOpenText, Zap, Mic, PanelLeftClose, TableProperties, ShieldCheck, Infinity, User, BrainCog, Rabbit, Upload, BookOpenCheck, Sparkles } from 'lucide-react';
import SchoolGoat from '../assets/schoolgoat.webp';
import { useLocation } from 'react-router-dom';
import { BREAKPOINTS } from '../misc/constants';
import { useBreakpoint } from '../misc/useBreakpoint';
import PermissionDeniedModal from '../modals/PermissionDeniedModal';
import NewUserModal from '../modals/NewUserModal';

const ICON_SIZE = 20
const ICON_COLOR = 'var(--color-text3)'


const TileTab = ({ item, index, activeTab, handleTabClick,  }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer", padding: ".6rem", backgroundColor: activeTab === item.route ? "var(--color-background)" : "transparent", color: activeTab == item.route ?  "var(--color-primary)" : ICON_COLOR, transition: "0.3s ease-in-out", borderRadius: ".75rem", border: activeTab == item.route ? `1px solid var(--color-separatorOpaque)` : "1px solid transparent", overflow: "hidden", 
      ...(item.isHighlighted && { backgroundColor: "var(--color-primary)", color: "white", border: `1px solid transparent` })
     }}
    onClick={() => handleTabClick(item.route)}
    key={index}
  >
    <div style={{ minWidth: "1.5rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
      {React.cloneElement(item.icon, { size: "1.2rem", strokeWidth: 1.5, color: activeTab == item.route ? "var(--color-primary)" : ICON_COLOR, ...(item.isHighlighted && { color: "white" }), style: { transition: "color 0.3s ease-in-out" } })}
    </div>
      <span style={{ fontSize: "1rem", marginLeft: ".7rem", whiteSpace: "nowrap", textOverflow: "ellipsis",  }}>{item.title}</span>
    </div>
  )
}


const TileSidebarGroup = ({ item, index, activeTab, handleTabClick, openSidebar }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", color: 'var(--color-text3)',opacity: openSidebar ? 1 : 0, marginBottom: "1rem", transition: "opacity 0.3s ease-in-out" }}
      key={index}
    >
      {item.title && (
      <span style={{ fontSize: ".9rem", marginLeft: ".6rem", whiteSpace: "nowrap", fontWeight: "400", color: 'var(--color-text3)', marginBottom: ".5rem" }}
        >{item.title}
        </span>
      )}
      {item.tabs.map((item, index) => (
        <TileTab 
          key={index}
          item={item}
          index={index}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
        />
      ))}
    </div>
  )
}


const ProtectedRoute = ({ children }) => {
  const { user, userLoaded } = useContext(UserContext);
  const navigate = useNavigate();
  const { isTabletAndBelow } = useBreakpoint();
  const [ openSidebar, setOpenSidebar ] = useState( isTabletAndBelow ? false : true);
  const [ underlayIsVisible, setUnderlayIsVisible ] = useState(false);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('');
  const [openFullAccessModal, setOpenFullAccessModal] = useState(false);


  useEffect(() => {
    let pathname = location.pathname;
    let pathnameEffective = pathname.replace('/app/', '');
    setActiveTab(pathnameEffective);
  }, [location]);

  useEffect(() => {
    if (isTabletAndBelow) {
      setOpenSidebar(false);
    }
    else {
      setOpenSidebar(true);
    }
  }, [isTabletAndBelow]);

  useEffect(() => {
    if (isTabletAndBelow && openSidebar) {
      setUnderlayIsVisible(true);
    }
    else {
      setUnderlayIsVisible(false);
    }
  }, [isTabletAndBelow, openSidebar]);

  const handleTabClick = (tab) => {
    navigate(tab);

    if (isTabletAndBelow) {
      setOpenSidebar(false);
    }
  }


  const data = [
    { title: "Class", tabs: [
      { title: "Solver", icon: <FileCheck />, route: Routes.SOLVE, },
      { title: "Take notes", icon: <Mic />, route: Routes.NOTES_IN_CLASS, },
    ]},
    { title: "Files", tabs: [
      { title: "Upload", icon: <Upload />, route: Routes.NOTES_CONVERT, },
      { title: "Your library", icon: <FileText />, route: Routes.RESOURCES, },
    ]},
    { title: "Study", tabs: [
      { title: "Teach me", icon: <Rabbit />, route: Routes.TEACH, },
      { title: "Flashcards", icon: <Zap />, route: Routes.FLASHCARDS, },
      { title: "Practice tests", icon: <BookOpenCheck/>, route: Routes.QUIZ, },
    ]},
    { title: "Writing", tabs: [
      { title: "Grade essay", icon: <ShieldCheck />, route: Routes.ESSAY_EVALUATION, },
      // { title: "Write essay", icon: <SquarePen />, route: Routes.ESSAY, },
      // { title: "Essay outline", icon: <ListTree />, route: Routes.ESSAY_OUTLINE, },
    ]},
    { title: "Other", tabs: [
      { title: "Get Paid", icon: <HandCoins />, route: Routes.REFERRALS, },
      { title: "Options", icon: <Cog />, route: Routes.OPTIONS, },
    ]},
  ]

  if ( !userLoaded ) return null
  else if ( !user ) return <Navigate to={Routes.LANDING} />
  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'hidden', position: 'relative', backgroundColor: 'var(--color-background)' }}>

      <div style={{ position: isTabletAndBelow ? 'fixed' : 'relative', width: openSidebar ? '260px' : '0px', backgroundColor: 'var(--color-backgroundSecondary)', display: 'flex', flexDirection: 'column', borderRight: '1px solid var(--color-separatorOpaque)', padding: openSidebar ? '0px 10px' : '0px 0px', overflowY: 'auto', transition: "all 0.3s ease-in-out", left: 0, top: 0, bottom: 0, zIndex: 6 }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginLeft: "-1rem" }}>
          <img src={SchoolGoat} style={{ width: "8rem", aspectRatio: "1/1", borderRadius: "15px" }} />
          <button onClick={() => setOpenSidebar(false)} style={{ background: "none", border: "none", cursor: "pointer", padding: "5px", }}>
            <PanelLeftClose size={24} style={{ color: 'var(--color-text1)' }} />
          </button>
        </div>

        { !user?.isMember && (
          <TileSidebarGroup 
            key={0} 
            item={{ tabs: [{ title: "Membership", icon: <Sparkles />, route: Routes?.MEMBERSHIP, isHighlighted: true }], }} 
            index={0} 
            activeTab={activeTab} 
            handleTabClick={handleTabClick} 
            openSidebar={openSidebar}
          />
        )} 

        {data.map((item, index) => (
          <TileSidebarGroup 
            key={index} 
            item={item} 
            index={index} 
            activeTab={activeTab} 
            handleTabClick={handleTabClick} 
            openSidebar={openSidebar}
          />
        ))}
      </div>


      <div style={{ flex: 1, position: "relative", overflow: "hidden", height: "100vh", backgroundColor: 'var(--color-background)' }}>

        <div style={{ position: 'fixed', top: 0, left: 0, width: isTabletAndBelow ? '100%' : '100px', height: 'var(--header-height)', display: 'flex', alignItems: 'center', zIndex: 4, transition: "all 0.3s ease-in-out", padding: '.8rem', 
          ...(isTabletAndBelow 
            ? { backgroundColor: 'var(--color-background)', borderBottom: '1px solid var(--color-separatorOpaque)' } 
            : { backgroundColor: "transparent" }
          )
        }}>
            
          <button style={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0, margin: 0 }}
            onClick={() => setOpenSidebar(true)} 
            ><Menu size={28} style={{ color: 'var(--color-text1)' }} strokeWidth={1.5} />

            { isTabletAndBelow && (
              <h1 style={{ fontSize: '1.2rem', fontWeight: '400', color: 'var(--color-text3)', marginLeft: '.7rem' }}
                >{data.map(item => item.tabs).flat().find(item => item.route === activeTab)?.title || (activeTab === Routes.MEMBERSHIP ? "Become a member" : "")}
              </h1>
            )}
          </button>
        </div>

        {/* UNDERLAY */}
        { isTabletAndBelow && (
          <div style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 5, opacity: underlayIsVisible ? 1 : 0, visibility: underlayIsVisible ? 'visible' : 'hidden', transition: "opacity 0.3s ease-in-out, visibility 0.3s ease-in-out"}}
            onClick={() => setOpenSidebar(false)}
          />
        )}



        <Outlet />
        <PermissionDeniedModal />
        <NewUserModal />
      </div>
    </div>
  )
};

export default ProtectedRoute;


// PREVIEW MODE
          {/* { !user?.isMember && (
            <div style={{ position: 'absolute', right: '1rem', top: '50%', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <span style={{ color: 'var(--color-text3)' }}>(Preview Mode)</span>
            </div>
          )} */}

        {/* <div style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 3, opacity: 1, visibility: 'visible', transition: "opacity 0.3s ease-in-out, visibility 0.3s ease-in-out"}}>

          <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, padding: '1rem', backgroundColor: 'var(--color-primary)', color: 'white', textAlign: 'center', cursor: 'pointer', zIndex: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem', height: 'var(--header-height)' }}
            onClick={() => setOpenFullAccessModal(true)}
          >
            <span>Get Full Access</span>
            <ArrowUp size={16} />
          </div>
        </div> */}







// const data = [

//   // { title: "Assignments", tabs: [
//   //   { title: "Solver", icon: <FileCheck />, route: Routes.SOLVE, },
//   //   { title: "In class", icon: <Mic />, route: Routes.NOTES_IN_CLASS, },
//   //   // { title: "Shortcut reading", icon: <BookOpenText />, route: Routes.SHORTCUT_READING, },
//   // ]},
//   // { title: "Notes", tabs: [
//   //   { title: "In class", icon: <Mic />, route: Routes.NOTES_IN_CLASS, },
//   //   { title: "Upload files", icon: <Upload />, route: Routes.NOTES_CONVERT, },
//   //   // { title: "Create notes", icon: <NotebookPen />, route: Routes.NOTES_CONVERT, },
//   //   { title: "Your library", icon: <FileText />, route: Routes.RESOURCES, },
//   //   // { title: "Your notes", icon: <FileText />, route: Routes.NOTES, },
//   // ]},
//   { title: "Class", tabs: [
//     { title: "Solver", icon: <FileCheck />, route: Routes.SOLVE, },
//     { title: "Take notes", icon: <Mic />, route: Routes.NOTES_IN_CLASS, },
//   ]},
//   { title: "Files", tabs: [
//     { title: "Upload", icon: <Upload />, route: Routes.NOTES_CONVERT, },
//     { title: "Your library", icon: <FileText />, route: Routes.RESOURCES, },
//   ]},
//   { title: "Study", tabs: [
//     { title: "Teach me", icon: <Rabbit />, route: Routes.TEACH, },
//     { title: "Flashcards", icon: <Zap />, route: Routes.FLASHCARDS, },
//     { title: "Practice tests", icon: <BookOpenCheck/>, route: Routes.QUIZ, },
//   ]},
//   { title: "Writing", tabs: [
//     // { title: "Essay", icon: <SquarePen />, route: Routes.ESSAY, },
//     { title: "Grade essay", icon: <ShieldCheck />, route: Routes.ESSAY_EVALUATION, },
//     // { title: "Outline generator", icon: <TableProperties />, route: Routes.ESSAY_OUTLINE, },
    
//   ]},
//   { title: "Other", tabs: [
//     { title: "Get Paid", icon: <HandCoins />, route: Routes.REFERRALS, },
//     { title: "Options", icon: <Cog />, route: Routes.OPTIONS, },
//   ]},
// ]


///////////////////////////////////// OLD NAVIGATION ///////////////////////////////////////
    // const data = [
    //   { title: "Take notes", icon: <NotebookPen />, route: Routes.TAKE_NOTES, },
    //   { title: "Your notes", icon: <FileText />, route: Routes.NOTES, },
    //   { title: "Homework", icon: <FileCheck />, route: Routes.SOLVE, },
    //   { title: "Study", icon: <GraduationCap />, route: Routes.STUDY, },
    //   { title: "Essay", icon: <SquarePen />, route: Routes.ESSAY, },
    //   { title: "Get Paid", icon: <HandCoins />, route: Routes.REFERRALS, },
    //   { title: "Options", icon: <Cog />, route: Routes.OPTIONS, },
    // ]

    // <>

    //   <nav style={{ position: "fixed", top: "0", width: "100%", backgroundColor: `var(--color-backgroundSecondary)`, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", border: "1px solid var(--color-separatorOpaque)", gap: "1rem", zIndex: 1000, }}
    //     role="navigation" aria-label="Main navigation"
    //   >
    //     {data.map((item, index) => <TileHeader key={index} item={item} index={index} isActive={item.route === activeTab} handleTabClick={handleTabClick}/>)}
    //   </nav>

    //   <Outlet />

    // </>


// const TileSidebar = ({ item, index, isActive, handleTabClick, openSidebar }) => {
//   return (
//     <div style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer", padding: "1rem", backgroundColor: isActive ? "var(--color-primary)" : "transparent", color: isActive ? "white" : ICON_COLOR, transition: "0.3s ease-in-out", borderRadius: "10px", opacity: openSidebar ? 1 : 0 }}
//       onClick={() => handleTabClick(item.route)}
//       key={index}
//     >
//       {React.cloneElement(item.icon, { size: 20, strokeWidth: 1.5, color: isActive ? "white" : ICON_COLOR })}
//       <span style={{ fontSize: "1.1rem", marginLeft: "1rem", whiteSpace: "nowrap" }}>{item.title}</span>
//     </div>
//   )
// }



///////////////////////////////////// OTHER ///////////////////////////////////////

// const TileHeader = ({ item, isActive, handleTabClick }) => {
//   return (
//     <button
//       style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer", border: "none", background: "none", borderBottom: isActive ? `2px solid var(--color-primary)` : `2px solid transparent`, paddingTop: ".6rem", paddingBottom: ".3rem", width: "5rem", transition: "border-bottom 0.3s ease-in-out", margin: 0, padding: "0.6rem 0 0.3rem 0", font: "inherit" }}
//       onClick={() => handleTabClick(item.route)}
//       onKeyDown={(e) => { if (e.key === 'Enter' || e.key === ' ') handleTabClick(item.route) }}
//       aria-current={isActive ? "page" : undefined}
//       aria-label={`${item.title} tab`}
//     >
//       {React.cloneElement(item.icon, { 
//         size: ICON_SIZE, 
//         color: ICON_COLOR, 
//         strokeWidth: 1.5,
//         role: "img",
//         "aria-hidden": "true"
//       })}
//       <span style={{ color: ICON_COLOR, fontWeight: "500", fontSize: ".8rem", marginTop: ".3rem" }}>
//         {item.title}
//       </span>
//     </button>
//   );
// };




{/* <span style={{ fontSize: "1.5rem", fontWeight: "bold", background: "linear-gradient(to right, rgba(0,0,0,.3), black)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", lineHeight: "1.25" }}
  >{`School\nG.O.A.T.`}
</span> */}




        {/* { !user?.isMember && (
          <div style={{ position: "fixed", bottom: 0, width: "100%", backgroundColor: "var(--color-primary)", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "1rem", zIndex: 1000, cursor: "pointer" }}
            onClick={() => setOpenFullAccessModal(true)}
          >
            <div style={{ maxWidth: "1000px", display: "flex", alignItems: "center", justifyContent: "center", flex: 1, width: "100%", gap: "1rem"}}>
              <span style={{ color: "white", fontWeight: "bold" }}>Get Full Access</span>
              <ArrowUp size={24} color="white" />
            </div>
          </div>  
        )}  */}



      {/* <div style={{ position: "fixed", top: "0", width: "100%", backgroundColor: `var(--color-backgroundSecondary)`, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderBottom: "1px solid var(--color-separatorOpaque)", gap: "1rem", zIndex: 1000, 
        // ...isSmallScreen && { top: null, bottom: "0", borderBottom: "none", borderTop: "1px solid var(--color-separatorOpaque)", borderRadius: "1rem 1rem 0 0"}  
      }}>
        {data.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              borderBottom: item.isActive ? `2px solid var(--color-primary)` : `2px solid transparent`,
              paddingTop: ".6rem",
              paddingBottom: ".3rem",
              width: "5rem",
              transition: "border-bottom 0.3s ease-in-out"
            }}
            onClick={item.onClick}
          >
            {React.cloneElement(item.icon, { size: ICON_SIZE, color: ICON_COLOR, strokeWidth: 1.5 })}
            <span style={{ color: ICON_COLOR, fontWeight: "500", fontSize: ".8rem", marginTop: ".3rem"}}
              >{item.title}
            </span>
          </div>
        ))}
      </div> */}


  // <div style={{position: 'fixed', top: 0, bottom: 0, left: 0, width: '250px', backgroundColor: 'white', boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)', transform: isSmallScreen ? (isOpen ? 'translateX(0)' : 'translateX(-100%)') : 'translateX(0)', transition: 'transform 0.3s ease-in-out', zIndex: 1000, padding: '20px'}}>
  //       <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
  //         <img src="/logo.svg" alt="Puzzler" style={{height: '32px'}} />
  //         {isMobile && <button onClick={toggleSidebar} style={{background: 'none', border: 'none', cursor: 'pointer'}}><X size={24} /></button>}
  //       </div>
        
  //       <nav>
  //         <ul style={{listStyle: 'none', padding: '0'}}>
  //           {menuItems.map((item, index) => (
  //             <li key={index}>
  //               <a href="#" style={{display: 'flex', alignItems: 'center', color: 'var(--color-text3)', textDecoration: 'none', marginBottom: '35px', cursor: 'pointer'}}>
  //                 <item.icon size={20} style={{marginRight: '15px', color: 'var(--color-text3)'}} color={'var(--color-text3)'} />
  //                 <span>{item.text}</span>
  //               </a>
  //             </li>
  //           ))}
  //         </ul>
  //       </nav>
  //     </div>