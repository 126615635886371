import axios from "axios";
import { API, dbRefs, stripeSubscriptionStatusStates, S3_URL_PREFIX, SOURCE_FILE_TYPES, RESOURCES_UPLOAD_METHODS, SLACK_WEBHOOK_CHANNELS } from "./constants";
import { v4 as uuidv4 } from 'uuid';



export const getSignedS3Urls = async (storagePathsArray) => {
  try {
    let urls = await axios.get(`${API}/getSignedS3Urls`,{ params: { storagePathsArray: storagePathsArray.join(",") }},)
    return urls.data
  }
  catch (err) {
    console.log("Error getting s3 urls ", err)
    throw err;
  }
  
}


//////////////////// RESOURCES ////////////////////

export const initResourceObj = async({ user, resource_id, uploadMethod }) => {
  if (!user?._id || !user?.email) {
    throw new Error('User data required');
  }

  try {
    let dateObj = new Date()

    let doc = {
      _id: resource_id || uuidv4(),
      user_id: user._id,
      user_email: user.email,
      title: "",
      category: '', 
      uploadMethod: uploadMethod,
      date: dateObj,
      updatedAt: dateObj,
      deleted: false,
      sources: [],
      sourceContent: {
        text: "",
        textUrl: "",
      },
    }

    await axios.post(`${API}/generalInsertOne`, { doc, dbRef: dbRefs.resources });

    return doc
  }
  catch (err) {
    console.log("Error creating resource object", err)
    throw err;
  }
}

export const uploadResourceToS3 = async ({ resource_id, file, uploadMethod, fileType = null }) => {
  let sourceObj = {}
  let title = ""
  console.log("uploadResourceToS3", resource_id, file, uploadMethod)

  try {
    // TEXT
    if (uploadMethod === RESOURCES_UPLOAD_METHODS.text) {
      const response = await axios.post(`${API}/createAndUploadTextFile`, { resource_id: resource_id, text: file });
      const { url } = response.data;
      
      sourceObj = { 
        fileType: SOURCE_FILE_TYPES.txt.label, 
        url: url, 
        name: "Source-Text"
      };
    }

    // FILES
    else if (uploadMethod === RESOURCES_UPLOAD_METHODS.file) {
      const fileName = `source-${Date.now()}${getFileExtension(file)}`
      const storagePath = `resources/${resource_id}/${fileName}`
      const url = `${S3_URL_PREFIX}${storagePath}`
      const [ signedUrl ] = await getSignedS3Urls([storagePath]);
      await axios.put(signedUrl, file, { headers: { 'Content-Type': file.type }});


      console.log(file, file.type)

      title = file?.name || ""
      
      sourceObj = { 
        fileType: getResourceFileType(file), 
        url: url, 
        name: getFileTitle(file)
      };
    }

    // RECORDING
    else if (uploadMethod === RESOURCES_UPLOAD_METHODS.recording) {
      const fileName = `source-${Date.now()}${getFileExtension(file)}`
      const storagePath = `resources/${resource_id}/${fileName}`
      const url = `${S3_URL_PREFIX}${storagePath}`
      const [ signedUrl ] = await getSignedS3Urls([storagePath]);
      await axios.put(signedUrl, file.data, { headers: { 'Content-Type': file.type }});
      
      sourceObj = { 
        fileType: SOURCE_FILE_TYPES.audio.label, 
        url: url, 
        name: getFileTitle(file)
      };
    }

    else {
      console.log("uploadResourceToS3 unsupported file type", uploadMethod)
      throw new Error(`Unsupported file type: ${uploadMethod}`);
    }

    await axios.put(`${API}/generalUpdateOne`, { 
      matchObj: { _id: resource_id }, 
      updateObj: { $push: { sources: sourceObj }, $set: { title: title } },
      dbRef: dbRefs.resources
    });

    console.log(title)
    
    return sourceObj
  } 
  catch (error) {
    console.error('Error in uploadResource:', error);
    throw error;
  }
};

export const getFileExtension = (file) => {
  // Handle cases where filename has no extension
  const parts = file.name.split('.');
  return parts.length > 1 ? `.${parts.pop()}` : '';
};

export const getFileTitle = (file) => {
  return file?.name || `Source-${Date.now()}`
}

export const getResourceFileType = (file) => {
  const extension = file.name.toLowerCase().split('.').pop();
  
  // Look through each file type
  for (const [_, typeInfo] of Object.entries(SOURCE_FILE_TYPES)) {
    if (typeInfo.extensions.includes(extension)) {
      return typeInfo.label;
    }
  }
  
  return 'unknown';
};

export const getTextFromResource = async (resourceObj) => {
  let notesUrlText = resourceObj?.notes?.urlText
  let sourceUrlText = resourceObj?.sourceContent?.url
  let urlEffective = notesUrlText || sourceUrlText

  console.log({
    notesUrlText,
    sourceUrlText,
    urlEffective,
    type: typeof urlEffective
  });

  if ( urlEffective ) {
    try {
      const response = await axios.get(urlEffective)
      return response.data
    }
    catch (error) {
      console.log("Error getting text from resource", error)
      throw error
    }
  }
  else {
    throw new Error("No text found in resource")
  }
}


//////////////////// NOTES TABLE ////////////////////

export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

export const formatSecondsToDuration = (secondsStr) => {
  const totalSeconds = parseInt(secondsStr, 10);
    
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // Padding minutes and seconds with leading zeros if they are less than 10
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = seconds.toString().padStart(2, '0');

  // If hours is 0, we skip it for the formatting
  if (hours > 0) {
      return `${hours}:${paddedMinutes}:${paddedSeconds}`;
  } else {
      return `${paddedMinutes}:${paddedSeconds}`;
  }
}


//////////////////// STRIPE CHECKOUT ////////////////////

export const createCheckoutSession_v3 = async (priceId, email, promotionCode) => {
  const promotionCodeEffective = promotionCode ? 'promo_1QDPuILkURBDGvXaKNpjCfHJ' : null

  try {

    const response = await axios.post(`${API}/stripe/create-checkout-session-v3`, { 
      priceId: priceId,
      email: email,
      promotionCode: promotionCodeEffective,
    });

    const sessionData = response.data;
    
    if (sessionData.url) {
      const fullMessage = `Going to checkout\nEmail: ${email}\nPrice: ${priceId}`;
      sendSlackNotification(fullMessage, SLACK_WEBHOOK_CHANNELS.stripe);
      window.location.href = sessionData.url;
    } 
    else {
      console.error('Failed to create checkout session:', sessionData.error);
    }
  } 
  catch (error) {
    console.error('Error calling the API:', error.response?.data || error.message);
  }
};

//////////////////// USER CREATION ////////////////////

export const generateReferralCode = () => {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let code = '';
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters[randomIndex];
  }
  return code;
}

export const validateEmail = (email) => {

  const sanitizedEmail = email.toLowerCase().trim();

  const prohibitedEmailAddresses = [
    "hthlm.com", "nbmbb.com", "abatido.com", "rinseart.com", "aiworldx.com", "ytnhy.com", "internetkeno.com"
  ]
  
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!re.test(sanitizedEmail)) {
    return false;
  }

  for (const prohibited of prohibitedEmailAddresses) {
    if (sanitizedEmail.includes(prohibited)) {
      return false;
    }
  }

  return true;
};


//////////////////// SLACK ////////////////////


export const sendSlackNotification = async (message = "", channel = SLACK_WEBHOOK_CHANNELS.general) => {
  try {
    await axios.post(`${API}/postSlackNotification`, { message, channel });
  }
  catch (error) {
    console.error("Error sending slack notification", error)
  }
}