export const Routes = {
  LANDING: '/',
  APP: '/app',
  TAKE_NOTES: 'take-notes',

  TERMS: '/terms',
  TERMS_PARTNER_PROGRAM: '/terms-partner-program',
  PRIVACY: '/privacy',
  OPTIONS: 'options',
  REFERRALS: 'referrals',
  NOTES_FEEDBACK: 'notes-feedback/:file_id',
  
  NOTES_IN_CLASS: 'notes-in-class',
  NOTES_CONVERT: 'notes-convert',
  NOTES: 'notes',
  NOTES_FILE: 'notes-file/:file_id',

  SOLVE: 'solve',
  SHORTCUT_READING: 'shortcut-reading',

  STUDY: 'study',
  QUIZ: 'quiz',
  FLASHCARDS: 'flashcards',

  ESSAY: 'essay',
  ESSAY_EVALUATION: 'essay-evaluation',
  ESSAY_OUTLINE: 'essay-outline',

  MEMBERSHIP: 'membership',
  TEACH: 'teach',
  RESOURCES: 'resources',
  RESOURCES_PROFILE: 'resources/:resource_id',
};
