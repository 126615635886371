import React, { useContext, useEffect, useRef, useState } from 'react';
import { API, dbRefs, } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import ScreenHeader from '../components/ScreenHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, FileDown, ArrowDownToLine, Loader2 } from 'lucide-react';
import FeatureBlockedModal from '../modals/FeatureBlockedModal';
import Subheader from '../components/Subheader';
import Resource_Notes from '../components/Resource_Notes';
import Study_Quiz from '../zArchive/Study_Quiz';
import Resource_Quiz from '../components/Resource_Quiz';
import Resource_Flashcards from '../components/Resource_Flashcards';
import Resource_Teach from '../components/Resource_Teach';
import { Routes } from '../navigation/routes';

const Button_Padding = "10px 15px"
const Button_Style = { padding: Button_Padding, cursor: 'pointer', borderRadius: '20px', display: 'inline-flex', alignItems: 'center', border: '1px solid var(--color-separatorOpaque)', backgroundColor: 'transparent', transition: 'all 0.3s', fontSize: '14px', fontWeight: '500', color: 'var(--color-backgroundTertiary)' }

const tabs = {
  notes: "Notes",
  quiz: "Quiz",
  flashcards: "Flashcards",
  teach: "Teach",
}

const ResourcesProfileScreen = ({ }) => {
  const { resource_id } = useParams();
  const navigate = useNavigate();
  const { user, } = useContext(UserContext)
  const [ data, setData ] = useState({})
  const [ refreshData, setRefreshData ] = useState(false)
  const [ openFeatureBlockedModal, setOpenFeatureBlockedModal ] = useState(false)
  const [ activeTab, setActiveTab ] = useState(tabs.notes)

  ///////////////////////// GET DATA /////////////////////////
  useEffect(() => {
    getData()
  }, [resource_id, refreshData])

  const getData = async () => {
    try {
      let result = await axios.get(API + '/generalFindOne', { params: {
        queryObj: JSON.stringify({ _id: resource_id }),
        dbRef: dbRefs.resources
      }})
      let results = result.data
      setData(results)
    } 
    catch (error) {
      console.error('Error fetching text content:', error);
    }
  }


  ///////////////////////// FUNCTIONS /////////////////////////





  const BackButton = () => {
    return (
      <button
        style={Button_Style}
        onClick={() => navigate(`${Routes.APP}/${Routes.RESOURCES}`)}
        onFocus={(e) => { e.currentTarget.style.outline = '2px solid var(--color-focus)'; }}
        onBlur={(e) => { e.currentTarget.style.outline = 'none'; }}
        aria-label="Go back to previous page"
      >
        <ArrowLeft size={16} color="var(--color-text3)" style={{ marginRight: '5px' }} aria-hidden="true" />
        <span style={{ color: 'var(--color-text3)' }}>Back</span>
      </button>
    );
  };
  
  const DownloadButtons = ({ }) => {
    const [ loading, setLoading ] = useState(false)
    const notesTypes = { pdf: "PDF", docx: "DOCX" }

    const handleDownload = async (type, url) => {
      try {
        setLoading(type)
        let newUrl = ''

      if ( user.isMember ) {
        // if ( url ) {
        //   return window.open(url, '_blank');
        // }
        if ( type === notesTypes.pdf ) {
          const result = await axios.post(API + '/generateNotesPdf', {
            resource_id: data._id,
            resource_title: data.title,
            notes_urlText: data.notes.urlText,
          })
          newUrl = result.data.url
          setData({ ...data, notes: { ...data.notes, urlPdf: newUrl } })

        }
        else if ( type === notesTypes.docx ) {
          const result = await axios.post(API + '/generateNotesDocx', {
            resource_id: data._id,
            resource_title: data.title,
            notes_urlText: data.notes.urlText,
          })
          newUrl = result.data.url
          setData({ ...data, notes: { ...data.notes, urlDocx: newUrl } })
        }

        return window.open(newUrl, '_blank');
      } 
      else {
        setOpenFeatureBlockedModal(true);
      }
    }
    catch (error) {
      alert('Error downloading notes. Please try again.')
      console.error('Error downloading notes:', error);
      }
      finally {
        setLoading(false)
      }
    };

    const loadingSpinner = (type) => {
      return loading == type ? (
        <div style={{ width: '16px', height: '16px', border: '2px solid currentColor', borderTopColor: 'transparent', borderRadius: '50%', animation: 'loading-spinner 1s linear infinite', }} />
      ) : <ArrowDownToLine size={16} />
    }

    if ( data?.notes?.urlText ) return (
      <div role="group" aria-label="Download options">

        <button style={{ ...Button_Style, backgroundColor: 'var(--color-primary)', color: 'white', marginLeft: '10px', gap: '5px' }}
          onClick={async () => await handleDownload(notesTypes.pdf, data?.notes?.urlPdf)}
          aria-label="Download PDF"
          onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-primaryLight)'; }}
          onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-primary)'; }}
        >
          {loadingSpinner(notesTypes.pdf)}
          PDF
        </button>

        <button style={{ ...Button_Style, backgroundColor: 'var(--color-primary)', color: 'white', marginLeft: '10px', gap: '5px' }}
          onClick={async () => await handleDownload(notesTypes.docx, data?.notes?.urlDocx)}
          aria-label="Download DOCX"
          onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-primaryLight)'; }}
          onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-primary)'; }}
        >
          {loadingSpinner(notesTypes.docx)}
          Docx
        </button>

      </div>
    );
    else return null
  };

  return (
    <div id="wrapper">          
      <main id="main">
        <div className="inner">

          <ScreenHeader title={null} children={<DownloadButtons />} leftChildren={<BackButton />} />
          
          <Subheader tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

          <Resource_Notes isActive={activeTab === tabs.notes} data={data} setRefreshData={setRefreshData} />
          <Resource_Quiz isActive={activeTab === tabs.quiz} data={data} setData={setData} setRefreshData={setRefreshData} />
          <Resource_Flashcards isActive={activeTab === tabs.flashcards} data={data} setData={setData} setRefreshData={setRefreshData} />
          <Resource_Teach isActive={activeTab === tabs.teach} data={data} setData={setData} setRefreshData={setRefreshData} />

        </div>
      </main>

      <FeatureBlockedModal open={openFeatureBlockedModal} setOpen={setOpenFeatureBlockedModal} />
    </div>
  );  
}


export default ResourcesProfileScreen;
