import React, { useState, useEffect } from 'react';

const TermsScreen = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setIsLoading(true);
      const res = await fetch('https://academicweapon.s3.amazonaws.com/legal/Terms+of+Service+(2024.09.29).txt');
      if (!res.ok) {
        throw new Error('Failed to fetch terms of service');
      }
      const termsData = await res.text();
      setData(termsData);
      console.log(termsData)
    } 
    catch (err) {
      setError(err.message);
    } 
    finally {
      setIsLoading(false);
    }
  }

  return (
    <main style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "50px"}}>
      <h1 id="terms-title">Terms of Service</h1>
      {isLoading && <p aria-live="polite">Loading terms of service...</p>}
      {error && <p aria-live="assertive" role="alert">Error: {error}</p>}
      {data && (
        <section aria-labelledby="terms-title">
          <p style={{whiteSpace: "pre-wrap", fontSize: "16px", color: "var(--color-text)", maxWidth: "1000px", margin: "0 auto", padding: "20px", borderRadius: "5px", color: "black"}}
            tabIndex="0" 
            role="region" 
            aria-label="Terms of Service content"
            
          >
            {data}
          </p>
        </section>
      )}
    </main>
  );
};

export default TermsScreen;