import React, { useCallback } from 'react';

const Subheader = ({ tabs, activeTab, setActiveTab }) => {

  const Tile = ({ item, index, isActive, }) => {
    return (
      <button style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', padding: '0.75rem 0px', textAlign: 'center', cursor: 'pointer', backgroundColor: isActive ? 'var(--color-backgroundSecondary)' : 'transparent', color: isActive ? 'var(--color-primary)' : 'var(--color-text3)', borderRadius: '.5rem', transition: '.3s ease-in-out', fontSize: '1rem' }} 
        onClick={() => setActiveTab(item)}
        >{item}
      </button>
    )
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: 'var(--color-background)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '.5rem', marginBottom: '1rem'}}>
      { Object.values(tabs).map((item, index) => <Tile key={index} item={item} index={index} isActive={activeTab === item} />) }
    </div>
  );  
}


export default Subheader;