import React, { useState } from 'react';
import { ArrowUpIcon, ArrowDown, CircleHelp, Expand } from 'lucide-react';
import { OPENAI_CHAT_ROLES } from '../misc/constants';
import { useBreakpoint } from '../misc/useBreakpoint';


const Tile = ({ item, index }) => {

  const isHighlighted = item?.isHighlighted
  const backgroundColor = isHighlighted ? 'var(--color-primary)' : 'var(--color-backgroundSecondary)'
  const backgroundColorActive = isHighlighted ? 'var(--color-primaryLight)' : 'var(--color-background)'
  const textColor = isHighlighted ? 'var(--color-textButton)' : 'var(--color-text3)'
  const iconColor = isHighlighted ? 'var(--color-textButton)' : 'var(--color-primary)'

  return (
    <button style={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '1rem', background: backgroundColor, border: '1px solid var(--color-separatorOpaque)', borderRadius: '30px', cursor: 'pointer', fontSize: '1rem', color: '#444', transition: 'background 0.2s', flex: isHighlighted ? 1 : null, ...item?.style }}
      onClick={item.onClick}
      key={index}
      onMouseEnter={() => { document.body.style.backgroundColor = backgroundColorActive; }}
      onMouseLeave={() => { document.body.style.backgroundColor = '' }}
    >
      {React.cloneElement(item.icon, { size: 18, color: iconColor })}
      <span style={{ color: textColor }}
        >{item.title}
      </span>
    </button>
  )
}


const Teach_Footer = ({ handleSubmit, loadingResponse, scrollToBottom, FOOTER_HEIGHT }) => {
  const { isPhoneAndBelow, } = useBreakpoint()
  const [ question, setQuestion ] = useState('')
  const [ openQuestionModal, setOpenQuestionModal ] = useState(false);
  const actionButtons = [
    { icon: <Expand />, title: "Expand", onClick: () => handleSubmit({ role: OPENAI_CHAT_ROLES.user, content: "Expand on the section you just talked about. I really want to understand it better" }) },
    { icon: <ArrowDown />, title: "Continue", onClick: () => handleSubmit({ role: OPENAI_CHAT_ROLES.user, content: "Continue" }), isHighlighted: true },
    { icon: <CircleHelp />, title: "Question", onClick: () => setOpenQuestionModal(true) },
  ]

  const submitQuestion = async (e) => {
    e.preventDefault()
    setQuestion('')
    setOpenQuestionModal(false)
    handleSubmit({ role: OPENAI_CHAT_ROLES.user, content: question })
  }

  return (  
    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center",  backgroundColor: 'var(--color-backgroundSecondary)', gap: ".3rem", borderTop: '1px solid var(--color-separatorOpaque)', height: FOOTER_HEIGHT, padding: "0 1rem", }}>

        { loadingResponse && (
          <Tile item={{ 
            icon: <ArrowDown />, 
            title: "Scroll for more...", 
            onClick: () => scrollToBottom(), 
            style: { border: "none", cursor: "default" } 
          }}/>
        )}

      { !loadingResponse && !openQuestionModal && (
        <>
          { actionButtons.map((item, index) => <Tile item={item} index={index} /> )}
        </>
      )}

      { !loadingResponse && openQuestionModal && (
        <form style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: ".3rem", flex: 1, borderRadius: '30px', border: '1px solid #ddd', overflow: 'hidden', padding: ".4rem", }}
          onSubmit={submitQuestion}
        >
          <button style={{ height: "100%", backgroundColor: 'var(--color-background)', color: 'var(--color-text3)', borderRadius: '30px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: ".6rem", marginRight: "0rem", border: '1px solid var(--color-separatorOpaque)', fontSize: '1rem' }}
            type="button"
            onClick={() => setOpenQuestionModal(false)}
            >Cancel
          </button>
          <input style={{ flex: 1, outline: 'none', border: 'none', backgroundColor: 'transparent', fontSize: isPhoneAndBelow ? '16px' : '1rem' }} 
            type="text" 
            placeholder="Type your question here..." 
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />

          <button style={{ aspectRatio: 1, backgroundColor: loadingResponse ? '#ccc' : 'var(--color-primary)', color: 'white', borderRadius: '50%', cursor: 'pointer', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            type="submit"
            disabled={loadingResponse || !question}
          >
            <ArrowUpIcon size={ isPhoneAndBelow ? 16 : 20 } />
          </button>
        </form>
      )}

    </div>
  );  
}


export default Teach_Footer;



