import React, { useContext, useEffect, useState, useCallback } from 'react';
import { UserContext } from '../contexts/UserProvider';
import ScreenHeader from '../components/ScreenHeader';
import { Check, CircleCheck, CircleCheckBig } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import { createCheckoutSession_v3 } from '../misc/utils';
import { STRIPE_PRICE_OBJ } from '../misc/constants';


const MembershipScreen = () => {
  const { user, } = useContext(UserContext)
  const { isPhoneAndBelow, isMobileAndBelow, isTabletAndBelow } = useBreakpoint();

  const freeFeatures = [
    'Limited access',
    'Eligible for referral program',
  ];

  const plusFeatures = [
    'Assignment solves **- unlimited**',
    'In-class note taking **- unlimited**',
    'File uploads **- unlimited**',
    'Flash cards **- unlimited**',
    'Practice tests **- unlimited**',
    'Teaching sessions **- unlimited**',
    'Essay evaluations **- unlimited**',
  ];

  const formatTitle = (title) => {
    const parts = title.split('**');
    return parts.map((part, index) => 
      index % 2 === 0 ? (
        <span key={index}>{part}</span>
      ) : (
        <span key={index} style={{ fontStyle: 'italic', color: 'var(--color-text4)' }}>{part}</span>
      )
    );
  };

  const handleUpgrade = () => {
    createCheckoutSession_v3(STRIPE_PRICE_OBJ.monthly, user?.email, user?.referralCodeUsed)
  }

  const data = [
    {
      // title: 'Current plan',
      title: "Guest",
      price: '$0',
      description: 'Explore how School Goat can help you with your schoolwork',
      features: freeFeatures,
      buttonText: 'Your current plan',
      buttonSubText: 'Free with limited access',
      isShown: !isMobileAndBelow,
    },
    {
      // title: 'Unlimited',
      title: "Member",
      price: user?.referralCodeUsed ? '$5' : '$10',
      description: 'Get unlimited access to School Goat and maximize your school experience',
      features: plusFeatures,
      // buttonText: 'Upgrade to Unlimited',
      buttonText: 'Become a member',
      buttonSubText: 'No payment due now, cancel any time',
      buttonOnClick: handleUpgrade,
      isShown: true,
      isHighlighted: true,
    },
  ]



  const PlanContainer = ({ item }) => {
    if (!item.isShown) return null;

    return (
      <div style={{ display: 'flex', flexDirection: "column", gap: '1rem', justifyContent: 'flex-start', flexBasis: '300px', maxWidth: '400px', flexGrow: 1, border: '1px solid var(--color-separatorOpaque)', borderRadius: '1rem', padding: '2rem', background: 'var(--color-backgroundSecondary)', textAlign: "left",
        // ...(isMobileAndBelow && { border: 'none', padding: 0, background: 'transparent' })
      }}>
        <h3 style={{ fontSize: '1.4rem', fontWeight: '500', marginBottom: '8px' }}
          >{item.title}
        </h3>
        <div style={{ marginBottom: '0.5rem' }}>
          <span style={{ fontSize: '2.5rem',  }}
            >{item.price}
          </span>
          <span style={{ color: 'var(--color-text3)', marginLeft: '4px' }}
            >USD/month
          </span>
          <p style={{ color: 'var(--color-text3)', fontSize: "1rem", marginTop: ".5rem", lineHeight: "1.5" }}>
            {item.description}
          </p>
        </div>
        
        <div style={{ display: 'flex', flexDirection: 'column', gap: '.8rem' }}>
          <button style={{ backgroundColor: item.isHighlighted ? 'var(--color-primary)' : 'var(--color-background)', color: item.isHighlighted ? 'var(--color-white)' : 'var(--color-text4)', padding: '1rem', borderRadius: '30px', textAlign: 'center', fontSize: '1.1rem', cursor: item.isHighlighted ? 'pointer' : 'not-allowed' }}
            onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = item.isHighlighted ? 'var(--color-primaryLight)' : 'var(--color-background)' }}
            onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = item.isHighlighted ? 'var(--color-primary)' : 'var(--color-background)' }}
            onClick={item.buttonOnClick}
          >
            { item.buttonText }
          </button>

          {/* { ( item?.isHighlighted || !isPhoneAndBelow ) && (
            <p style={{ color: 'var(--color-text3)', fontSize: '0.9rem', textAlign: 'center',  }}>
              { item.buttonSubText }
            </p>
          )} */}
        </div>

        <ul style={{ listStyle: 'none', padding: 0, margin: 0, }}>
          {item.features.map((feature, index) => (
            <li key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '1.2rem' }}>
              <Check style={{ width: '1.5rem', height: '1.5rem', color: 'var(--color-primary)', marginRight: '.7rem',  }} />
              <span style={{ fontSize: '1rem', lineHeight: 1.4 }}
                >{formatTitle(feature)}
              </span>
            </li>
          ))}
        </ul>

      </div>
    )
  }

  return (
    <div id="wrapper">          
      <div id="main" style={{ 
        // backgroundColor: isMobileAndBelow ? 'var(--color-backgroundSecondary)' : 'transparent',
      }}>
        <div className="inner" style={{ fontFamily: 'system-ui, -apple-system, sans-serif', paddingTop: isMobileAndBelow ? '0rem' : '1.5rem', paddingBottom: '2rem' }} >
          
          {/* { !isMobileAndBelow && (
            <h1 style={{ textAlign: 'center', fontSize: '2rem', fontWeight: '500', marginBottom: '2rem' }}>
              Membership
            </h1>
          )} */}

          <ScreenHeader title="Membership" />

          <div style={{ display: 'flex', gap: '24px', justifyContent: isTabletAndBelow ? 'center' : 'flex-start', flexWrap: 'wrap' }}>
            <PlanContainer item={data[0]} />
            <PlanContainer item={data[1]} />
          </div>
        </div>
      </div>
    </div>
  );  
}


export default MembershipScreen;





      {/* <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '32px' }}>
        <div style={{ display: 'inline-flex', backgroundColor: '#f3f4f6', padding: '4px', borderRadius: '9999px' }}>
          <button
            style={{ padding: '8px 24px', borderRadius: '9999px', fontSize: '14px', fontWeight: '500', border: 'none', cursor: 'pointer', background: planType === 'personal' ? 'white' : 'none', boxShadow: planType === 'personal' ? '0 1px 3px rgba(0,0,0,0.1)' : 'none' }}
            onClick={() => setPlanType('personal')}
          >
            Personal
          </button>
          <button
            style={{ padding: '8px 24px', borderRadius: '9999px', fontSize: '14px', fontWeight: '500', border: 'none', cursor: 'pointer', background: planType === 'business' ? 'white' : 'none', boxShadow: planType === 'business' ? '0 1px 3px rgba(0,0,0,0.1)' : 'none' }}
            onClick={() => setPlanType('business')}
          >
            Business
          </button>
        </div>
      </div> */}
