

//////////////////// API & S3 ////////////////////
export const API = process.env.REACT_APP_BACKEND_API
export const S3_URL_PREFIX = `https://academicweapon.s3.amazonaws.com/`;
export const REFERRAL_CODE_USED_KEY = 'referralCodeUsed';


//////////////////// FREE TRIAL ////////////////////
export const FREE_TRIAL_DURATION = 4 * 24 * 60 * 60 * 1000; // 4 days

//////////////////// NAVIGATINO & URL SCHEME ////////////////////
export const SEARCH_PARAMS = {
  _id: "_id",
}

///////////////////////// MONGO /////////////////////////
export const dbRefs = {
  users: "USERS",
  files: "FILES",
  quizzes: "QUIZZES",
  flashcards: "FLASHCARDS",
  messages: "MESSAGES",
  resources: "RESOURCES",
};


//////////////////// SLACK ////////////////////
export const SLACK_WEBHOOK_CHANNELS = {
  issues: "issues",
  general: "general",
  stripe: "stripe",
  messages: "messages",
  resources: "resources",
  signups: "signups",
  paywall: "paywall",
}


//////////////////// DISPLAY & CSS ////////////////////

export const BREAKPOINTS = {
  DESKTOP: '(min-width:981px)',          // Desktops, laptops
  TABLET_AND_ABOVE: '(min-width:737px)', // iPads, tablets
  TABLET_AND_BELOW: '(max-width:980px)', // iPads, tablets
  MOBILE_AND_BELOW: '(max-width:736px)', // This would catch phones in landscape mode
  PHONE_AND_BELOW: '(max-width:480px)'      // This catches most phones in portrait, including large iPhones (430px)
};


//////////////////// RESOURCES ////////////////////
export const RESOURCES_UPLOAD_METHODS = {
  text: "text",
  file: "file",
  recording: "recording",
}
export const SOURCE_FILE_TYPES = {
  pdf: {
    extensions: ['pdf'],
    label: 'pdf',
    isAllowed: true,
  },
  ppt: {
    extensions: ['ppt', 'pptx', 'pptm'],
    label: 'ppt',
    isAllowed: false,
  },
  doc: {
    extensions: ['doc', 'docx', 'docm'],
    label: 'doc',
    isAllowed: false,
  },
  txt: {
    extensions: ['txt', 'rtf', 'md', 'csv'],
    label: 'txt',
    isAllowed: false,
  },
  audio: {
    extensions: ['mp3', 'wav', 'ogg', 'm4a', 'aac', 'wma', 'flac'],
    label: 'audio',
    isAllowed: true,
  }
};


///////////////////////// AUDIO RECORDING /////////////////////////
export const FREE_MINUTES = 60;
export const recordingIntervalDuration = parseInt(process.env.REACT_APP_RECORDING_INTERVAL_DURATION)
export const audioMimeTypes = {
  'audio/webm': '.webm',
  'audio/wav': '.wav',
  'audio/ogg': '.ogg',
  'audio/ogg; codecs=opus': '.opus',
  'audio/mp4': '.m4a',
  'audio/m4a': '.m4a',
  'audio/aac': '.aac',
  'audio/mp3': '.mp3',
  'audio/mpeg': '.mp3',
  'audio/flac': '.flac'
};


///////////////////////// STRIPE /////////////////////////
export const STRIPE_BILLING_PORTAL_URL = "https://billing.stripe.com/p/login/eVa15GeYedHs7AI5kk" // live
// export const STRIPE_BILLING_PORTAL_URL = "https://billing.stripe.com/p/login/test_bIY5od6oqa3zgDK000" // test
export const STRIPE_PRICE_OBJ = {
  // annual: "price_1Pma9OLkURBDGvXahFL5lhZl",   // EN
  // monthly: "price_1OssCALkURBDGvXaWifzcJw1", // EN
  annual: "price_1QDPmjLkURBDGvXaXiwKUucI",  
  monthly: "price_1QDPmALkURBDGvXapsqHSfwO",  
  standard: "price_1Q06x6LkURBDGvXaymUfjgjG",
}
export const STRIPE_PROMOTION_CODE = "promo_1QDPuILkURBDGvXaKNpjCfHJ"
export const stripeSubscriptionStatusStates = {
  trialing: "trialing",
  active: "active",
  incomplete: "incomplete",
  incomplete_expired: "incomplete_expired",
  past_due: "past_due",
  canceled: "canceled",
  unpaid: "unpaid",
  paused: "paused",
  free_trial: "free_trial",
  duplicate_account: "duplicate_account",
}

///////////////////////// DESIGN /////////////////////////

export const COLOR_ARRAY = [
  // "#AF52DE", 
  "#5656ff",
  // "#8b5cf6",
"#FF3B30", "#34C759", "#30B0C7", "#007aff", "#FF9500", "#FFCC00", "#00C7BE", "#32ADE6", "#5856D6", "#FF2D55"]

///////////////////////// MIXPANEL /////////////////////////
export const MIXPANEL_EVENTS = {
  solver: "Solver", // Solver         
  user_created: "User Created",
  essay_evaluation: "Essay Evaluation", //
  flashcard_next: "Flashcard Next",
  quiz_next: "Quiz Next",
}

///////////////////////// OPENAI /////////////////////////
export const OPENAI_CHAT_ROLES = { assistant: 'assistant', user: 'user', system: 'system' }