import React, { useState, useEffect } from 'react';
import ScreenHeader from './ScreenHeader';
import { Menu } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';


const Tile = ({ item, index, activeMindMapIndex, onTileClick }) => {
  const isActive = activeMindMapIndex === index

  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer", padding: ".6rem", backgroundColor: isActive ? "var(--color-background)" : "transparent", color: isActive ?  "var(--color-primary)" : "var(--color-text3)", transition: "0.3s ease-in-out", borderTop: isActive ? `1px solid var(--color-separatorOpaque)` : "1px solid transparent", borderBottom: isActive ? `1px solid var(--color-separatorOpaque)` : "1px solid transparent", overflow: "hidden", flexShrink: 0 }}
      onClick={() => onTileClick(index)}
      key={index}
    >
      <div style={{ minWidth: "1.5rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {index + 1})
      </div>
      <span style={{ fontSize: "1rem", marginLeft: ".5rem", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100%" }}
        >{item.title}
      </span>
    </div>
  )
}


const Teach_Sidebar = ({ mindMap, activeMindMapIndex, setActiveMindMapIndex, resetStates, FOOTER_HEIGHT }) => {
  const { isMobileAndBelow, isTabletAndBelow } = useBreakpoint()
  const [ isOpen, setIsOpen ] = useState( isMobileAndBelow ? false : true)

  useEffect(() => {
    if ( !isMobileAndBelow ) {
      setIsOpen(true)
    }
    else {
      setIsOpen(false)
    }
  }, [isMobileAndBelow])

  const onTileClick = (index) => {
    setActiveMindMapIndex(index)

    if ( isMobileAndBelow ) {
      setIsOpen(false)
    }
  }

  return (
    <>
      <div style={{ height: '100%', width: isOpen ? '260px' : 0, backgroundColor: 'var(--color-backgroundSecondary)', display: 'flex', flexDirection: 'column', borderLeft: '1px solid var(--color-separatorOpaque)', padding: '10px 0px', overflowY: 'auto', transition: "all 0.3s ease-in-out", flexShrink: 0, 
        ...(isTabletAndBelow && { paddingTop: 'var(--header-height)' }),
        ...(!isTabletAndBelow && { paddingTop: '10px' }),
        ...(isMobileAndBelow && { position: 'fixed', top: 0, bottom: 0, right: 0, zIndex: 1000 })
      }}>
        <ScreenHeader title="Topics" style={{ padding: '0 1rem', marginTop: ".5rem" }} />
        {mindMap.map((item, index) => (
          <Tile item={item} index={index} activeMindMapIndex={activeMindMapIndex} onTileClick={onTileClick} />
        ))}

        <button style={{ margin: '1rem', padding: '0.5rem 1rem', backgroundColor: 'var(--color-background)', color: 'var(--color-text3)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '5px', cursor: 'pointer', fontSize: "1rem" }}
          onClick={resetStates}
        >
          Start New
        </button>
      </div>

      {/* UNDERLAY */}
      { isMobileAndBelow && (
        <div style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 4, opacity: isOpen ? 1 : 0, visibility: isOpen ? 'visible' : 'hidden', transition: "opacity 0.3s ease-in-out, visibility 0.3s ease-in-out"}}
          onClick={() => setIsOpen(false)}
        />
      )}

      {/* FLOATING BUTTON */}
      { !isOpen && (
        <button style={{ position: 'fixed', bottom: `calc(${FOOTER_HEIGHT} + 1rem)`, right: '1rem', backgroundColor: 'var(--color-primary)', color: 'var(--color-textButton)', border: 'none', borderRadius: '50%', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', zIndex: 1000 }}
          onClick={() => setIsOpen(!isOpen)}
        >
          { <Menu size={24} style={{ color: 'var(--color-textButton)' }} />}
        </button>
      )}
    </>
  )
}

export default Teach_Sidebar