'use client';
import React, { useContext, useRef, useEffect } from 'react';
import axios from 'axios';
import { Drawer } from 'vaul';
import { UserContext } from '../contexts/UserProvider';
import { API, dbRefs } from '../misc/constants';
import { Routes } from '../navigation/routes';

const PartnerProgramTermsModal = ({ open, setOpen }) => {
  const { user, setUser } = useContext(UserContext);
  const acceptButtonRef = useRef(null);

  useEffect(() => {
    if (open && acceptButtonRef.current) {
      setTimeout(() => acceptButtonRef.current.focus(), 100);
    }
  }, [open]);

  const handleSubmit = async () => {
    await axios.put(`${API}/generalUpdateOne`, {
      matchObj: { _id: user._id },
      updateObj: { $set: { termsAcceptedPartnerProgram: Date.now() } },
      dbRef: dbRefs.users,
    });
    setUser({ ...user, termsAcceptedPartnerProgram: Date.now() });
    setOpen(false);
  };

  return (
    <Drawer.Root open={open} onOpenChange={setOpen}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.4)', zIndex: 1000 }} />
        <Drawer.Content style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', position: 'fixed', bottom: 0, left: 0, right: 0, maxHeight: '82vh', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', zIndex: 1001 }}
          aria-labelledby="drawer-title"
          role="alertdialog"
          aria-describedby="drawer-description"
          aria-modal="true"
        >
          <div style={{ maxWidth: '28rem', width: '100%', margin: '0 auto', overflowY: 'auto', padding: '1rem', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingBottom: '2rem' }}>
            <Drawer.Handle />
            <Drawer.Title id="drawer-title" className="modalTitle">
              Referral Program
            </Drawer.Title>
            <Drawer.Description className="modalDescription">
              In order to participate in the partner program and receive payments for referrals, first click below to accept the partner agreement. You can find the full terms{' '}
              <a 
                href={`${process.env.REACT_APP_FRONTEND_URL}${Routes.TERMS_PARTNER_PROGRAM}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ color: 'var(--color-primary)', textDecoration: 'underline' }}
                aria-label="View full terms of the partner program (opens in a new tab)"
              >
                here
              </a>.
            </Drawer.Description>
            <button className="modalButton"
              ref={acceptButtonRef}
              onClick={handleSubmit}
              aria-label="Accept partner program terms"
            >
              Accept
            </button>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default PartnerProgramTermsModal;