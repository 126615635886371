'use client';
import { useContext, useEffect, useState } from 'react';
import { Drawer } from 'vaul';
import { UserContext } from '../contexts/UserProvider';
import { BookOpen, Check, CheckCircle, Clock, FileText, Library, Rabbit, Upload, Zap } from 'lucide-react';

const NewUserModal = ({ }) => {
  const { user } = useContext(UserContext)
  const [ open, setOpen ] = useState(false)

  useEffect(() => {
    if (user?.isFirstTimeUser && user?.freeTrialExpiration > Date.now()) {
      setOpen(true)
    }
  }, [user?.isFirstTimeUser, user?.freeTrialExpiration])

  const onClose = () => {
    setOpen(false)
  }

  const features = [
    { icon: CheckCircle, text: "Auto-solve your homework" },
    { icon: FileText, text: "Take notes in class with one click" },
    { icon: Rabbit, text: "Turn dense reading into easy notes" },
    { icon: BookOpen, text: "Create practice tests for any class" },
    { icon: Library, text: "Use the in-built tutor to learn anything" },
    { icon: Zap, text: "Get flashcards for any topic" }
  ];

  const Tile =({ item }) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <item.icon style={{ width: '1.25rem', height: '1.25rem', color: 'var(--color-primary)' }} />
        <span style={{ fontSize: '1rem', lineHeight: 1.4, color: 'var(--color-text2)' }}>{item.text}</span>
      </div>
    )
  }

  return (
    <Drawer.Root dismissable={false} open={open} onOpenChange={setOpen}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.4)', zIndex: 1000 }} />
        <Drawer.Content style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', position: 'fixed', bottom: 0, left: 0, right: 0, maxHeight: '90vh', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', zIndex: 1001, outline: 'none' }}
          aria-labelledby="drawer-title"
          role="alertdialog"
          aria-describedby="drawer-description"
          aria-modal="true"
        >
          <div style={{ maxWidth: "450px", width: '100%', margin: '0 auto', overflowY: 'auto', padding: '32px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingBottom: '16px' }}>
            <Drawer.Title className="modalTitle"
              id="drawer-title"
              style={{ fontSize: '2rem', fontWeight: 600, color: 'var(--color-primary)', textAlign: 'center', lineHeight: 1.2, marginBottom: '1rem'  }}
              >Your 4-days of <br/>free access starts now!
            </Drawer.Title>

            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '.75rem' }}>
                <Clock style={{ width: '1.25rem', height: '1.25rem', color: 'var(--color-text3)' }} />
                <span style={{ fontSize: '1rem', fontWeight: "500", lineHeight: 1.4, color: 'var(--color-text3)' }}>4 days remaining</span>
              </div>
            </div>

            <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column', gap: '1.45rem', padding: "1.5rem", 
              borderRadius: '1rem', backgroundColor: 'var(--color-background)', border: '1px solid var(--color-separatorOpaque)' 
              }}>
              <div style={{ fontSize: '1rem', fontWeight: 600, color: 'var(--color-primary)',  }}
              >To make the most of it
              </div>
              {features.map((feature, index) => (
                <Tile key={index} item={feature} />
              ))}
            </div>

            <button className="modalButton"
              onClick={() => onClose()}
              onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-primaryLight)'}
              onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-primary)'}
              >Get started
            </button>

          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export default NewUserModal;





            {/* <Drawer.Description className="modalDescription"
              id="drawer-description"
              style={{ marginTop: '0.5rem', color: 'var(--color-text2)', lineHeight: 1.5 }}
              >Make the most of your free trial by exploring these powerful features. See how School Goat can transform your learning experience.
            </Drawer.Description> */}
