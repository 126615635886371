import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import './App.css';
// import './screens/LandingScreen.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import mixpanel from "mixpanel-browser";

const firebaseConfig = {
  apiKey: "AIzaSyDb5amfXNLLk5F0Jj9N-aONjChX3ISKfHo",
  authDomain: "academicweapon-add38.firebaseapp.com",
  projectId: "academicweapon-add38",
  storageBucket: "academicweapon-add38.appspot.com",
  messagingSenderId: "347924000168",
  appId: "1:347924000168:web:4964fc7971b56f087b019d",
  measurementId: "G-54F08M4G3Z"
};

const app = initializeApp(firebaseConfig);

mixpanel.init("4fc38a7858f18bfb6a5f3e4d053a851c", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);


reportWebVitals();
