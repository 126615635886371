import React, { useState, useRef, useEffect } from 'react';
import { Lightbulb, Book, PenTool, Layout, MessageSquare, Check } from 'lucide-react';
import { COLOR_ARRAY } from '../misc/constants';
import { ChevronDown, ChevronUp, CheckCircle, XCircle, Wrench, ArrowLeft } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';

const dummyMetrics = [
  { title: 'Overall', grade: 0, icon: Book },
  { title: 'Potential', grade: 95, icon: Lightbulb },
  { title: 'Creativity', grade: 82, icon: PenTool },
  { title: 'Structure', grade: 91, icon: Layout },
  { title: 'Clarity', grade: 92, icon: MessageSquare },
  { title: 'Grammar', grade: 50, icon: Check },
  { title: 'Grammar2', grade: 50, icon: Check }
];

const getColorForGrade = (grade) => {
  if (grade >= 90) return COLOR_ARRAY[2];
  if (grade >= 80 ) return COLOR_ARRAY[0];
  if (grade >= 70) return COLOR_ARRAY[6];
  if (grade >= 1) return COLOR_ARRAY[1];
  return '#CACACA';
};

const TileScore = ({ item }) => {
  const color = getColorForGrade(item.grade);

  return (
    <div style={{ backgroundColor: 'var(--color-background)', padding: '1.5rem', borderRadius: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '0.5rem', flexGrow: 1, flexBasis: '200px', border: '1px solid var(--color-separatorOpaque)', backgroundColor: `${color}20` }}>
      <span style={{ fontSize: '2rem', color: color, textAlign: 'left' }}
        >{item.grade}
      </span>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-end', textAlign: 'left', flex: 1, }}>
        <span style={{ fontSize: '1rem', color: 'var(--color-text3)', textAlign: 'left', lineHeight: '1.2' }}
          >{item.title}
        </span>
        
      
        <div style={{ height: '8px', backgroundColor: `${color}33`, borderRadius: '4px', overflow: 'hidden', width: "100%", marginTop: '.5rem' }}>
          <div style={{height: '100%', width: `${item.grade}%`, backgroundColor: color, borderRadius: '4px', transition: 'width 0.5s ease-out'}} />
        </div>
      </div>

    </div>
  )
}

const TileFeedbackExpandable = ({ item, index }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);
  const color = getColorForGrade(item.grade);

  useEffect(() => {
    if (isExpanded) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  return (
    <div style={{ marginTop: index !== 0 ? '0px' : '1.5rem', borderTop: `1px solid var(--color-separatorOpaque)`, paddingTop: '1.5rem', }}
      // onClick={() => setIsExpanded(!isExpanded)}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
          <h3 style={{ fontSize: '1rem', color: 'var(--color-text1)', fontWeight: '600' }}
            >{item.title}
          </h3>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ fontSize: '1.5rem', color: color, 
            // marginRight: '1rem'
           }}
            >{item.grade}
          </span>
          {/* {isExpanded ? <ChevronUp /> : <ChevronDown />} */}
        </div>
      </div>
      <div style={{ 
        // height: isExpanded ? `${height}px` : 0, 
        overflow: 'hidden', 
        transition: '0.3s ease-out', 
        opacity: isExpanded ? 1 : 0, 
      }}>
        <div ref={contentRef} style={{ display: 'flex', flexDirection: 'column', gap: '1rem', paddingTop: '1rem' }}>
          {item && (
            <>
              <TileFeedbackBullets title="Strengths" items={item?.strengths} icon={CheckCircle} />
              {/* <TileFeedbackBullets title="Areas for Improvement" items={item.areasForImprovement} icon={XCircle} /> */}
              <TileFeedbackBullets title="Actionable Advice" items={item?.actionableAdvice} icon={Wrench} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const TileFeedbackBullets = ({ title, items, icon: Icon }) => {
  if ( !items || items.length === 0 ) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    <h4 style={{ display: 'flex', alignItems: 'center', color: 'var(--color-text3)', marginBottom: '10px' }}>
      <Icon size={20} style={{ marginRight: '10px' }} />
      {title}
    </h4>
    <ul style={{ paddingLeft: '3rem', margin: 0 }}>
      {items.map((item, index) => (
        <li key={index} style={{ marginBottom: '5px', textAlign: 'left', fontSize: '1rem', listStyleType: 'disc', lineHeight: '1.5' }}
          >{item}
        </li>
      ))}
    </ul>
    </div>
  );
};




const Essay_Evaluation = ({ isActive, responses, tabs, setActiveTab }) => {
  const { isPhoneAndBelow } = useBreakpoint()

  if (!Array.isArray(responses) || responses.length === 0) {
    responses = dummyMetrics;
  }

  const overallGrade = Math.round(responses.filter(item => item.grade > 0).reduce((sum, item) => sum + item.grade, 0) / responses.filter(item => item.grade > 0).length) || 0;

  const color = getColorForGrade(overallGrade);

  if ( !isActive ) {
    return null;
  }

  return (
    <div style={{ backgroundColor: 'var(--color-backgroundSecondary)', padding: isPhoneAndBelow ? '1rem' : '2rem', borderRadius: '1rem', border: '1px solid var(--color-separatorOpaque)', display: 'flex', flexDirection: 'column', position: 'relative' }}>

      <button style={{ padding: '.5rem 1rem', backgroundColor: 'var(--color-background)', color: 'var(--color-text3)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '30px', cursor: 'pointer', fontSize: '1rem', display: 'flex', alignItems: 'center', gap: '5px', position: 'absolute', top: '2rem', left: '2rem', zIndex: 2 }}
        onClick={() => setActiveTab(tabs.input)}
      >
        <ArrowLeft size={16} />
        Back
      </button>

      <div style={{ fontFamily: 'Arial, sans-serif', width: "100%", maxWidth: '800px', margin: '0 auto' }}>
      
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px' }}>
          <div style={{ width: '10rem', height: '10rem', borderRadius: '50%', background: `conic-gradient(${color} ${overallGrade}%, ${color}33 ${overallGrade}% 100%)`, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
            <div style={{ width: '8.7rem', height: '8.7rem', borderRadius: '50%', background: 'var(--color-backgroundSecondary)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <span style={{ fontSize: '3rem', color: color }}
                >{overallGrade}
              </span>
              <span style={{ fontSize: '1rem', color: '#666' }}
                >Overall
              </span>
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: '1rem' }}>
          {responses.map((metric, index) => <TileScore key={index} item={metric} /> )}
        </div>

      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', }}>
        {responses.map((metric, index) => (
          <TileFeedbackExpandable key={index} item={metric} index={index} />
        ))}
      </div>
    </div>
  );
};

export default Essay_Evaluation;
