import React, { useContext, useEffect, useState, useCallback } from 'react';
import { API, dbRefs, MIXPANEL_EVENTS, SEARCH_PARAMS, } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import { Check, CheckCircle, Circle, Upload, X, RotateCcw } from 'lucide-react';
import { COLOR_ARRAY } from '../misc/constants';
import { useBreakpoint } from '../misc/useBreakpoint';
import mixpanel from 'mixpanel-browser';
import { GenerateComponent } from './Resource_Components';
import { getTextFromResource } from '../misc/utils';
import { Routes } from '../navigation/routes';
import { useNavigate } from 'react-router-dom';

const Resource_Teach = ({ isActive = true, data = {}, setData, setRefreshData }) => {
  const { user, } = useContext(UserContext)
  const navigate = useNavigate()
  const [ content, setContent ] = useState([])
  const { isPhoneAndBelow } = useBreakpoint()
  const [ selectedQuestionIndex, setSelectedQuestionIndex ] = useState(0)

  useEffect(() => {
    if ( isActive && data?.teach?.content ) {
      setContent(data?.teach?.content)
      console.log("content", content)
    }
  }, [data, isActive])

  const handleOnGenerate = async () => {
    try {
      const text = await getTextFromResource(data)

      if ( text ) {
        await axios.post(`${API}/generateTeach`, { text: text, topic: '', resource_id: data?._id });
        setRefreshData((prev) => !prev)
      }
    }
    catch (error) {
      throw error
    }
  }

  if ( !isActive ) return null
  if ( !content || content.length === 0 ) return <GenerateComponent handleOnGenerate={handleOnGenerate} />

  return (
    <>
      <div style={{ textAlign: "left", alignItems: "flex-start", padding: isPhoneAndBelow ? '1.5rem' : '2rem', backgroundColor: 'white', borderRadius: '1rem', border: '1px solid var(--color-separatorOpaque)',  }}>

        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '2rem' }}>
          <button 
            style={{ backgroundColor: 'var(--color-primary)', color: 'var(--color-textButton)', padding: '1rem 2rem', borderRadius: '30px', border: 'none', fontSize: '1rem', fontWeight: '500', cursor: 'pointer' }}
            onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-primaryLight)'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-primary)'}
            // onClick={() => navigate(`${Routes.TEACH}?${SEARCH_PARAMS._id}=${data?._id}`)}
            onClick={() => navigate(`${Routes.APP}/${Routes.TEACH}?${SEARCH_PARAMS._id}=${data?._id}`)}
          >
            Jump to teach me
          </button>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", gap: "2rem", alignItems: "flex-start" }}>
          {content.map((item, index) => (
            <div key={index} style={{ flexBasis: '320px', flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left',  }}>
              <h3 style={{ margin: '0 0 0.5rem 0', fontSize: '1.1rem', fontWeight: '600', color: 'var(--color-text1)' }}>
              {index + 1}) {item.title}
              </h3>
              <p style={{ margin: 0, fontSize: '1rem', lineHeight: '1.5', color: 'var(--color-text3)' }}>
                {item.description}
              </p>
            </div>
          ))}
        </div>



      </div>
      <span style={{ fontSize: '.9rem', color: 'var(--color-text3)', marginTop: '1rem', fontWeight: '500' }}>{data?.title}</span>
    </>

    )
}

export default Resource_Teach;






        {/* {content.map((item, index) => (
          <div key={index} style={{ marginBottom: '2rem' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '1rem' }}>
              <div style={{ minWidth: '2rem', height: '2rem', borderRadius: '50%', backgroundColor: 'var(--color-primary)', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1rem', fontWeight: '500' }}>
                {index + 1}
              </div>
              <div>
                <h3 style={{ margin: '0 0 0.5rem 0', fontSize: '1.1rem', fontWeight: '600', color: 'var(--color-text1)' }}>
                {index + 1}) {item.title}
                </h3>
                <p style={{ margin: 0, fontSize: '1rem', lineHeight: '1.5', color: 'var(--color-text2)' }}>
                  {item.description}
                </p>
              </div>
            </div>
          </div>
        ))} */}
