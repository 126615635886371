'use client';
import React, { useRef, useState, useContext, useEffect, useCallback } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { API, BREAKPOINTS, COLOR_ARRAY, S3_URL_PREFIX } from '../misc/constants';
import { ArrowUpIcon, PaperclipIcon, Upload, RotateCcw } from 'lucide-react';
import ScreenHeader from '../components/ScreenHeader';
import SolveMessage from '../components/SolveMessage';
import { Command, Fullscreen } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_EVENTS, dbRefs, OPENAI_CHAT_ROLES } from '../misc/constants';
import { v4 as uuidv4 } from 'uuid';
import { getSignedS3Urls } from '../misc/utils';

const MAX_SIZE_MB = .8; // Adjust this to match your server limit
const BYTE_TO_MB = 1024 * 1024;


const SolveScreen = ({  }) => {
  const { user, checkUserPermission } = useContext(UserContext);

  const fileInputRef = useRef(null);
  const fileInputMobileRef = useRef(null);
  const messagesEndRef = useRef(null);

  const { isPhoneAndBelow } = useBreakpoint();  
  
  const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/webp'];
  const validImageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp'];

  const [ chatInput, setChatInput ] = useState('');
  const [ chatMessages, setChatMessages ] = useState([ 
    // { role: OPENAI_CHAT_ROLES.assistant, content: 'Hello! I\'m SchoolGOAT. How can I help heyeheyeheye heyeyeyeheyeh whatththa wlk;ejkl;ahkf wha;hwk' },
    // { role: OPENAI_CHAT_ROLES.user, content: 'Hello! I\'m SchoolGOAT. How can I help?\n\n\n\n\n\nheyeheyeheye\n\n\nheyeyeyeheyeh\n\n\n\n\n\n\whatththa\n\n\n\nwlk;ejkl;ahkf\n\n\n\wha;hwk' },
    // { role: OPENAI_CHAT_ROLES.assistant, content: 'Hello! I\'m SchoolGOAT. How can I help?\n\n\n\n\n\nheyeheyeheye\n\n\nheyeyeyeheyeh\n\n\n\n\n\n\whatththa\n\n\n\nwlk;ejkl;ahkf\n\n\n\wha;hwk' }
  ]);
  const [isDragging, setIsDragging] = useState(false);
  const [ loadingResponse, setLoadingResponse ] = useState(false);
  const [ error, setError ] = useState(false);


  /////////////////////////////////////// FILE HANDLING ///////////////////////////////////////

  const checkFileTypeAndSize = (file) => {
    if ( !validImageTypes.includes(file.type) && !validImageExtensions.some(ext => file.name.toLowerCase().endsWith(ext)) ) {
      alert(`Invalid file type. Please select one of the following: ${validImageExtensions.join(', ')}. Contact support if you need more file types.`);
      return false
    }
    else if (file.size > 100 * 1024 * 1024) {
      alert('File is too large (max 100 MB). Let us know if you need more storage.');
      return false
    }
    else {
      return true
    }
  }

  
  const processImage = (file, maxDimension = 800, quality = 0.7) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target.result;
      
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        let newWidth, newHeight;
        
        if (img.width > img.height) {
          newWidth = Math.min(maxDimension, img.width);
          newHeight = Math.round(newWidth / aspectRatio);
        } else {
          newHeight = Math.min(maxDimension, img.height);
          newWidth = Math.round(newHeight * aspectRatio);
        }

        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;
        
        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        const base64String = canvas.toDataURL('image/jpeg', quality);
        
        // Check processed image size
        const processedSize = Math.round((base64String.length * 3) / 4);
        if (processedSize > MAX_SIZE_MB * BYTE_TO_MB) {
          // If still too large, try processing again with lower quality
          if (quality > 0.3) {
            console.log(`Image still too large, retrying with lower quality...`);
            resolve(processImage(file, maxDimension, quality - 0.2));
          } else {
            reject(new Error(`Unable to compress image below ${MAX_SIZE_MB}MB limit. Consider using a smaller image.`));
          }
          return;
        }

        const originalSize = Math.round(file.size / 1024);
        const newSize = Math.round(processedSize / 1024);
        console.log(`Original dimensions: ${img.width}x${img.height}`);
        console.log(`New dimensions: ${newWidth}x${newHeight}`);
        console.log(`Original size: ${originalSize}KB`);
        console.log(`New size: ${newSize}KB`);
        resolve(base64String);
      };

      img.onerror = (error) => reject(new Error('Error loading image'));
    };

    reader.onerror = (error) => reject(new Error('Error reading file'));
    reader.readAsDataURL(file);
  });
  };

  const handleImage = async (file) => {

    const checkFile = checkFileTypeAndSize(file)

    if ( checkFile ) {

      try {
        const base64Image = await processImage(file);

        const contentType = base64Image.match(/^data:([^;]+);base64,/)?.[1] || 'image/jpeg';
        const blobData = await fetch(base64Image).then(res => res.blob());
        const fileName = `${uuidv4()}`
        const storagePath = `messages/${fileName}`
        const url = `${S3_URL_PREFIX}${storagePath}`
        const [signedUrl] = await getSignedS3Urls([storagePath]);
        await axios.put(signedUrl, blobData, { headers: { 'Content-Type': contentType }});

        const newMessage = {
          role: OPENAI_CHAT_ROLES.user,
          content: [{ type: "image_url", image_url: { url: url } }]
        };

        handleSubmit(newMessage, true)
      } 
      catch (error) {
        console.error('Error converting file to base64:', error);
        setError(true);
        throw error;
      }
    }
  };

  const handleMessage = async () => {
    const newMessage = {
      role: OPENAI_CHAT_ROLES.user,
      content: chatInput
    };

    handleSubmit(newMessage)
  }

  const handleSubmit = async (newMessage, clearMessages = false) => {
    if (!checkUserPermission()) return;

    let updatedMessages = clearMessages ? [newMessage] : [...chatMessages, newMessage];
    setChatMessages([...updatedMessages, { role: OPENAI_CHAT_ROLES.assistant, content: '' }]);
    setLoadingResponse(true);

    const TIMEOUT_DURATION = 8000; // 30 seconds timeout
    let timeoutId;

    try {
      setChatInput('');

      const response = await fetch(`${API}/streamSolver`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: user?._id, messages: updatedMessages }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      const processStream = async () => {
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const message = decoder.decode(value);
          const lines = message.split('\n');

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const json = line.replace('data: ', '');
              if (json.trim() !== '') {
                const data = JSON.parse(json);

                if (data.error) {
                  throw new Error('Server error');
                }

                // Reset the timeout on each received message
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                  throw new Error('Response timeout');
                }, TIMEOUT_DURATION);

                setChatMessages((prev) => {
                  const lastMessage = prev[prev.length - 1];
                  if (lastMessage.role === OPENAI_CHAT_ROLES.assistant) {
                    return [
                      ...prev.slice(0, -1),
                      { ...lastMessage, content: lastMessage.content + (data.content || '') }
                    ];
                  }
                  return prev;
                });
              }
            }
          }
        }
      };

      // Set initial timeout
      timeoutId = setTimeout(() => {
        throw new Error('Initial response timeout');
      }, TIMEOUT_DURATION);

      await processStream();

      await axios.post(`${API}/generalInsertOne`, { 
        doc: {
          _id: uuidv4(),
          user_id: user?._id,
          user_email: user?.email,
          text: typeof newMessage.content === 'string' ? newMessage.content : '',
          imageUrl: newMessage?.content?.[0]?.image_url?.url || '',
          date: new Date()
        }, 
        dbRef: dbRefs.messages 
      });

      mixpanel.track(MIXPANEL_EVENTS.solver, {
        user_id: user?._id,
        user_email: user?.email,
      });
      
    } 
    catch (error) {
      console.error('Error in handleSubmit:', error);
      handleError();
    }
    finally {
      clearTimeout(timeoutId);
      setLoadingResponse(false);
    }
  };

  const handleError = () => {
    setChatMessages((prev) => {
      const updatedMessages = [...prev];
      if (updatedMessages[updatedMessages.length - 1].role === OPENAI_CHAT_ROLES.assistant) {
        updatedMessages.pop();
      }
      return [...updatedMessages];
    });
    setError(true);
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        e.preventDefault();
        setChatInput(prevInput => prevInput + '\n');
      } 
      else {
        if ( !loadingResponse && chatInput.trim() !== '') {
          e.preventDefault();
          handleMessage();
        }
      }
    }
  }



  /////////////////////////////////////// DRAG AND DROP ///////////////////////////////////////

  const overlayRef = useRef(null);

  const useDocumentFileDrag = () => {
    const dragCounter = useRef(0);
    useEffect(() => {
      const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current++;
        if (e.dataTransfer.types.includes('Files')) {
          setIsDragging(true);
        }
      };
  
      const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
      };
  
      const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current--;
        if (dragCounter.current === 0) {
          setIsDragging(false);
        }
      };
  
      const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        dragCounter.current = 0;
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
          handleImage(e.dataTransfer.files[0])
        }
      };
  
      document.addEventListener('dragenter', handleDragEnter);
      document.addEventListener('dragover', handleDragOver);
      document.addEventListener('dragleave', handleDragLeave);
      document.addEventListener('drop', handleDrop);
  
      return () => {
        document.removeEventListener('dragenter', handleDragEnter);
        document.removeEventListener('dragover', handleDragOver);
        document.removeEventListener('dragleave', handleDragLeave);
        document.removeEventListener('drop', handleDrop);
      };
    }, []);
  
    return isDragging;
  };

  const isFileOver = useDocumentFileDrag();


  /////////////////////////////////////// COMPONENTS ///////////////////////////////////////

  const OpeningComponent = () => {

    const containerStyle = { display: 'flex', height: "100%", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '2rem', backgroundColor: '#EEE7FE85', borderRadius: '15px', border: '2px dashed var(--color-primary)', textAlign: 'center', lineHeight: '1.2', width: '100%', }
    const headerStyle = { fontSize: '1.2rem', fontWeight: 'bold', color: 'var(--color-text1)', lineHeight: '1.5' }
    const secondaryTextStyle = { fontSize: '1rem', color: 'var(--color-text3)', marginTop: '2rem' }
    const shortCutItemContainerStyle = { display: 'flex', flexDirection: 'column', gap: '4px', alignItems: 'center', justifyContent: 'center', flexBasis: "200px", padding: "1rem", borderRadius: "1rem", border: "1px solid var(--color-primaryLight)"   }

    if ( chatMessages.length == 0 ) {

      if ( !isPhoneAndBelow ) {
        return (
          <div style={containerStyle} > 
            <Fullscreen size={60} style={{color: 'var(--color-primary)', marginBottom: '15px'}} strokeWidth={1.5} />
            
            <p style={headerStyle}
              >Solve a problem for me
            </p>

            <p style={{fontSize: '1rem', color: '#666', marginTop: '1rem', lineHeight: '1.5', width: "23rem"}}
              >Screenshot the problem you are working on and drop it here, or just ask the question below
            </p>
            
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '1rem', marginTop: '1.5rem', width: "100%",  }}>

              <div style={shortCutItemContainerStyle}>
                <h4 style={{ fontSize: '1rem', fontWeight: '500', color: 'var(--color-text3)', marginBottom: '4px',  }}
                  >Apple screenshot
                </h4>
                <span style={{ fontSize: '.9rem', fontFamily: 'monospace', color: 'var(--color-text1)' }}
                  ><Command size={14} /> + Shift + 4
                </span>
              </div>

              <div style={shortCutItemContainerStyle}>
                <h4 style={{ fontSize: '1rem', fontWeight: '500', color: 'var(--color-text3)', marginBottom: '4px',  }}
                  >Windows screenshot
                </h4>
                <span style={{ fontSize: '.9rem', fontFamily: 'monospace', color: 'var(--color-text1)' }}
                  >Win + Shift + S
                </span>
              </div>
            </div>

          </div>
        )
      }

      else {
        return (
          <div style={containerStyle} >
            <Fullscreen size={60} style={{color: 'var(--color-primary)', marginBottom: '15px'}} strokeWidth={1.5} />
            
            <p style={headerStyle}
              >Solve a problem for me
            </p>

            <p style={{fontSize: '1rem', color: '#666', marginTop: '1rem', lineHeight: '1.5', width: "23rem"}}
              >Take a photo of the problem you want solved or just ask the question below
            </p>
            
            <button style={{backgroundColor: 'var(--color-primary)', color: 'white', border: 'none', borderRadius: '8px', padding: '10px 20px', fontSize: '1rem', fontWeight: 'bold', cursor: 'pointer', marginTop: '1.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px'}}
              onClick={() => fileInputMobileRef.current.click()} 
              >Get Photo
            </button>
            <input
              type="file"
              ref={fileInputMobileRef}
              onChange={(e) => handleImage(e.target.files[0])}
              accept="image/*"
              capture="environment"
              style={{display: 'none'}}
            />
          </div>
      )
    }
    }
    else {
      return null
    }
  }

  const UploadOverlay = () => {
    if ( isFileOver ) {
      return (
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#EEE7FE85', zIndex: 9999, display: 'flex', alignItems: 'center', justifyContent: 'center', transition: 'background-color 0.3s', backdropFilter: 'blur(25px)', WebkitBackdropFilter: 'blur(25px)' }}
          ref={overlayRef}
        >
          <div style={{ padding: '32px', borderRadius: '8px', textAlign: 'center' }}>
            <Upload style={{ margin: '0 auto 16px auto', color: 'var(--color-primary)' }} size={48} />
            <p style={{ fontSize: '20px', fontWeight: 600, color: '#333' }}>Drop your screenshot here</p>
          </div>
        </div>
      )
    }
    else {
      return null
    }
  }

  const ErrorComponent = () => {
    if ( error ) {

      const handleErrorRetry = () => {
        setError(false);
        let updatedMessages = [...chatMessages];
        let lastUserMessage;

        if (updatedMessages[updatedMessages.length - 1].role === OPENAI_CHAT_ROLES.assistant) {
          updatedMessages.pop();
        }

        for (let i = updatedMessages.length - 1; i >= 0; i--) {
          if (updatedMessages[i].role === OPENAI_CHAT_ROLES.user) {
            lastUserMessage = updatedMessages[i];
            updatedMessages.splice(i, 1);
            break;
          }
        }

        setChatMessages(updatedMessages);

        if (lastUserMessage) {
          handleSubmit(lastUserMessage);
        }
      }


      return (
        <button style={{ position: "absolute", bottom: 10, left: "50%", transform: "translateX(-50%)", display: "flex", width: "15rem", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: '1rem', margin: 0, background: 'none', border: 'none', cursor: 'pointer', color: 'var(--color-text3)', gap: '.5rem', backgroundColor: `${COLOR_ARRAY[1]}33`, borderRadius: '100px' }}
        onClick={handleErrorRetry}
      >
      <p style={{ fontSize: '1rem', color: COLOR_ARRAY[1] }}
        >Error, click to retry
      </p>
        <RotateCcw size={16} color={COLOR_ARRAY[1]}/>
      </button>
      )
    }
  }

  return (

    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: 'var(--color-backgroundSecondary)', }}>

      <UploadOverlay />

      <div id="main" style={{ overflowY: 'auto', paddingBottom: 0 }}>
        <div style={{ maxWidth: '1000px', margin: '0 auto', width: '100%', flex: 1, display: 'flex', flexDirection: 'column', minHeight: '100%', position: 'relative' }}>
          <ScreenHeader title="Assignment solver" />
          <OpeningComponent />
          {chatMessages.length > 0 && (
            <>
              {chatMessages.map((message, index) => (
                  <SolveMessage key={index} item={message} index={index} isPhoneAndBelow={isPhoneAndBelow} />
                ))}
              </>
            )}
          <ErrorComponent />
      
        </div>
      </div>

      <div style={{ width: "100%", paddingBottom: "1rem", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", backgroundColor: 'var(--color-backgroundSecondary)', paddingTop: "1rem", }}>

        <div id="main" style={{ paddingBottom: 0, paddingTop: 0 }}>

          <div style={{ width: "100%", maxWidth: "1000px", display: 'flex', alignItems: 'center', padding: ".5rem", backgroundColor: 'var(--color-background)', borderRadius: '100px', border: '1px solid var(--color-separatorOpaque)',  }}>
            { chatMessages.length == 0 ? (
              <button 
                style={{ color: 'var(--color-text1)', background: 'none', border: 'none', cursor: 'pointer', padding: '.3rem', marginLeft: '.3rem' }}
              type="button"
              onClick={() => fileInputRef.current.click()}
            >
              <PaperclipIcon size={ isPhoneAndBelow ? 19 : 24 } />
              </button>
            ) : 
            <button style={{ height: "100%", backgroundColor: 'var(--color-background)', color: 'var(--color-text3)', borderRadius: '30px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: ".6rem", marginRight: "0rem", border: '1px solid var(--color-separatorOpaque)', fontSize: '1rem' }}
              type="button"
              onClick={() => setChatMessages([])}
              >Clear
            </button>
            }
            <input style={{ width: '100%', color: 'var(--color-text1)', padding: '0 .6rem', border: 'none', outline: 'none', backgroundColor: 'transparent', fontSize: isPhoneAndBelow ? '16px' : '1rem' }}
              type="text"
              value={chatInput}
              onChange={(e) => setChatInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Describe the homework problem"
            />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImage(e.target.files[0])}
              ref={fileInputRef}
              style={{ display: 'none' }}
              aria-label="Select image file"
            />
            <button style={{ aspectRatio: 1, backgroundColor: loadingResponse ? '#ccc' : 'var(--color-primary)', color: 'white', borderRadius: '50%', cursor: 'pointer', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              type="submit"
              onClick={handleMessage}
              disabled={loadingResponse}
            >
              <ArrowUpIcon size={ isPhoneAndBelow ? 16 : 24 } />
            </button>
          </div>
        </div>
      </div>
    </div>

  );
};

export default SolveScreen;




  /////////////////////////////////////// SCROLL TO BOTTOM ///////////////////////////////////////

  // const scrollToBottom = () => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  // };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [chatMessages]);
  // <div ref={messagesEndRef} />



  

      // <div id="wrapper" ref={overlayRef} style={{ backgroundColor: 'var(--color-backgroundSecondary)',  }}>
        
        
      //   <div id="main" style={{ overflowY: "auto", }}>

      //   <UploadOverlay />
        
      //     <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100%", width: "100%", flex: 1 }}>
      //       <div style={{ width: "100%", maxWidth: "1000px", display: 'flex', flexDirection: 'column', flex: 1, height: "100%", paddingBottom: "500px" }}>
      //         <ScreenHeader title="Homework Solver" />
      //         <OpeningComponent />
      //         {chatMessages.length > 0 && (
      //           <>
      //             {chatMessages.map((message, index) => (
      //               <SolveMessage key={index} item={message} index={index} />
      //             ))}
      //             <div ref={messagesEndRef} />
      //           </>
      //         )}
      //       </div>
      //     </div>

      //     <div style={{ position: "fixed", bottom: 0, width: "100%", paddingBottom: "30px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", backgroundColor: 'var(--color-backgroundSecondary)', }}>
      //     <div style={{ width: "100%", maxWidth: "1000px", display: 'flex', alignItems: 'center', padding: "7px", backgroundColor: 'var(--color-background)', borderRadius: '100px', border: '1px solid var(--color-separatorOpaque)',  }}>
      //       <button 
      //         style={{ color: 'var(--color-text1)', background: 'none', border: 'none', cursor: 'pointer', marginLeft: "8px" }}
      //         type="button"
      //         onClick={() => fileInputRef.current.click()}
      //       >
      //         <PaperclipIcon size={21} />
      //       </button>
      //       <input style={{ width: '100%', color: 'var(--color-text1)', padding: '0 12px', border: 'none', outline: 'none', fontSize: '18px', backgroundColor: 'transparent' }}
      //         type="text"
      //         value={chatInput}
      //         onChange={(e) => setChatInput(e.target.value)}
      //         onKeyPress={handleKeyPress}
      //         placeholder="Message SchoolGOAT"
      //       />
      //       <input
      //         type="file"
      //         onChange={(e) => handleImage(e.target.files[0])}
      //         ref={fileInputRef}
      //         style={{ display: 'none' }}
      //         aria-label="Select file"
      //       />
      //       <button style={{ backgroundColor: loadingResponse ? '#ccc' : 'var(--color-primary)', color: 'white', borderRadius: '50%', padding: '8px', cursor: 'pointer', border: 'none' }}
      //         type="submit"
      //         onClick={handleMessage}
      //         disabled={loadingResponse}
      //       >
      //         <ArrowUpIcon size={22} />
      //       </button>
      //     </div>
      //   </div>


      //   </div>



      // </div>

      // const response = await fetch(`${API}/streamSolver`, {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ user_id: user?._id, messages: [newMessage] }),
      // });

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }

      // const result = await response.json();
      // const content = result.content;
      // console.log(content)
      // setChatMessages((prev) => {
      //   const updatedMessages = [...prev];
      //   updatedMessages[updatedMessages.length - 1].content = content;
      //   return updatedMessages;
      // });


