'use client';
import { Drawer } from 'vaul';
import React, { useRef, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from '../contexts/UserProvider';
import { dbRefs, S3_URL_PREFIX, RESOURCES_UPLOAD_METHODS } from '../misc/constants';
import { initResourceObj, uploadResourceToS3 } from '../misc/utils';
import { API } from '../misc/constants';
import { Typography } from '@mui/material';
import ResourceProcessingModal from '../modals/ResourceProcessingModal';
import { useNavigate } from 'react-router-dom';

const TextUploader = ({ open, setOpen, }) => {
  const { user, checkUserPermission } = useContext(UserContext);
  const navigate = useNavigate();
  const [file, setFile] = useState('');
  const textareaRef = useRef(null);
  const [openResourceProcessingModal, setOpenResourceProcessingModal] = useState(false);
  const [ isUploading, setIsUploading ] = useState(false)

  useEffect(() => {
    if (open && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [open]);

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
    setFile('');
  };

  const uploadFile = async () => {

    setIsUploading(true)

    try {
      const doc = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
      const resource = await uploadResourceToS3({ resource_id: doc._id, file: file, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
      const sourceContent = await axios.post(`${API}/generateSourceContent`, { resource_id: doc._id, userCategories: user.categories  })
      await axios.post(`${API}/generateNotes`, { file_id: doc._id,  })
      navigate(`/app/resources/${doc._id}`)
      handleClose();
      // setOpenResourceProcessingModal(true);
    } 
    catch (error) {
      console.error('Error uploading file:', error);
      alert(error.message);
    }
    finally {
      setIsUploading(false)
    }
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
    gap: '12px',
  };

  return (
    <>
        <Drawer.Root open={open} onOpenChange={setOpen} repositionInputs={false}>
          <Drawer.Portal>
            <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }} />
            <Drawer.Content style={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'var(--color-backgroundSecondary)', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', zIndex: 1001 }} 
              aria-labelledby="drawer-title" 
              role="dialog" 
              aria-modal="true" 
              aria-describedby="drawer-description"
              onKeyDown={handleKeyDown}
            >
              <div style={{ padding: '32px', borderRadius: '8px' }}>
                <Drawer.Title className="modalTitle" style={{ marginTop: "0" }} id="drawer-title">
                  Convert Text to Notes
                </Drawer.Title>
                <p id="drawer-description" className="sr-only"
                  >Enter your text in the textarea below and click the Convert to Notes button to process it.
                </p>
                <textarea ref={textareaRef} style={{ width: '100%', minHeight: '250px', padding: '12px', borderRadius: '8px', border: '1px solid var(--color-separatorOpaque)', resize: 'vertical', fontFamily: 'inherit', fontSize: '16px', lineHeight: '1.4', color: 'var(--color-text1)', marginTop: '10px' }} placeholder="Paste your text here..." value={file} onChange={(e) => setFile(e.target.value)} aria-label="Text to convert" />
                
                { isUploading ? (
              <>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '1rem' }}>
                  <div style={{ width: '40px', height: '40px', border: '3px solid var(--color-separatorOpaque)', borderTop: '3px solid var(--color-primary)', borderRadius: '50%', animation: 'spin 1s linear infinite' }} />
                </div>
                <style>
                  {`
                    @keyframes spin {
                      0% { transform: rotate(0deg); }
                      100% { transform: rotate(360deg); }
                    }
                  `}
                </style>
              </>
            ) : (
              <div style={buttonContainerStyle}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button 
                  onClick={uploadFile} 
                  disabled={!file} 
                  variant="contained"
                  aria-label="Convert to Notes"
                  aria-disabled={!file}
                >
                  Convert to Notes
                </Button>
              </div>
            )}
                
                {/* <div style={buttonContainerStyle}>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={uploadFile} disabled={!file} variant="contained">
                    Convert to Notes
                  </Button>
                </div> */}
              </div>
            </Drawer.Content>
          </Drawer.Portal>
        </Drawer.Root>

        <ResourceProcessingModal open={openResourceProcessingModal} setOpen={setOpenResourceProcessingModal} />

    </>
  );
};

export default TextUploader;