import { Button, IconButton } from '@mui/material';
import { format } from 'date-fns';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { useNavigate } from 'react-router-dom';
import { ArrowRight, MoreVertical, FileText, File, Mic, Ellipsis, FileUp, MessageSquareText, MessageCircleMore, TextCursorInput, Zap, FileCheck, BookOpenCheck, Rabbit } from 'lucide-react';
import axios from 'axios';
import { API, RESOURCES_UPLOAD_METHODS } from '../misc/constants';
import { useBreakpoint } from '../misc/useBreakpoint';
import ResourceInfoModal from '../modals/ResourceInfoModal';

const ResourcesTable = ({ isActive = true, data = [], setData, handleRowClick }) => {
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const { isMobileAndBelow,  } = useBreakpoint();
  const [ dataDisplayed, setDataDisplayed ] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const totalItems = data.length
  const [resourceInfoModalId, setResourceInfoModalId] = useState(null);

  const iconDirectory = {
    [RESOURCES_UPLOAD_METHODS.text]: <TextCursorInput />,
    [RESOURCES_UPLOAD_METHODS.file]: <FileText />,
    [RESOURCES_UPLOAD_METHODS.recording]: <Mic />,
  }

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setDataDisplayed(data.slice(startIndex, endIndex));
  }, [data, currentPage, itemsPerPage]);

  const onPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const onNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleMoreVerticalClick = (e, item) => {
    e.stopPropagation();
    setResourceInfoModalId(item._id);
  }




  if ( !isActive ) return null

  return (
    <>
      <div style={{ backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '.5rem', overflow: 'hidden', marginBottom: '1rem', ...isMobileAndBelow && { padding: 0, border: 'none', borderRadius: 0, } }}
        role="region"
        aria-label="Notes list"
      >
        { !isMobileAndBelow &&
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "1rem", color: 'var(--color-text3)', fontWeight: "500", textTransform: "uppercase", fontSize: ".8rem", borderBottom: "1px solid var(--color-separator)", textAlign: "left", backgroundColor: "var(--color-primaryLight)" }}>
            <div style={{ width: "40px", }}>
              
            </div>
            <div style={{ flex: 1, }}>
              Title
            </div>
            <div style={{ width: "10rem", }}>
              Class
            </div>
            <div style={{ width: "10rem", }}>
              Date
            </div>
            <div style={{ display: 'flex', alignItems: 'center', width: "40px" }}>
              </div>
          </div>
        }

        { dataDisplayed && dataDisplayed.length > 0 && dataDisplayed.map((item, index) => (
          <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left', padding: isMobileAndBelow ? "1rem" : ".5rem 1rem", fontSize: '1rem', 
            borderBottom: '1px solid var(--color-separator)', cursor: 'pointer', transition: 'background-color 0.3s ease',  }}
            key={index}
            onClick={(e) => handleRowClick(e, item)}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
          >
            <div style={{ width: "40px", }}>
              {React.cloneElement(iconDirectory[item.uploadMethod], { size: 20, color: 'var(--color-text3)', style: { verticalAlign: 'middle', strokeWidth: 1 } })}
            </div>

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", flex: 1, minWidth: 0, maxWidth: "100%" }}>
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "100%" }}>
                  {item.title || "Document"}
                </span>

              { isMobileAndBelow &&
                <span style={{ fontSize: '.9rem', color: 'var(--color-text4)', marginTop: '.2rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "100%" }}
                  >{format(new Date(item.date), "MMM d, yyyy")}  •  {item.category}
                </span>
              }
            </div>

            { !isMobileAndBelow &&
            <>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", minWidth: 0, width: "10rem" }}>
                <span style={{ fontSize: '.9rem', color: 'var(--color-text4)', }}
                  >{item.category} 
                </span>
              </div>

              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", minWidth: 0, width: "10rem", }}>
                <span style={{ fontSize: '.9rem', color: 'var(--color-text4)',  }}
                  >{format(new Date(item.date), "MMM d, yyyy")} 
                </span>
              </div>
            </>
            }
            { isMobileAndBelow &&
              <div style={{ display: 'flex', alignItems: 'center', gap: '.7rem', marginLeft: '1rem', flexShrink: 0 }}>
                { item.notes?.urlText && <FileText size={18} color='var(--color-text3)' strokeWidth={1} style={{ cursor: 'pointer' }} title="notes" /> }
                { item.flashcards?.content && <Zap size={18} color='var(--color-text3)' strokeWidth={1} /> }
                { item.quiz?.content && <BookOpenCheck size={18} color='var(--color-text3)' strokeWidth={1} /> }
                { item.teach?.content && <Rabbit size={18} color='var(--color-text3)' strokeWidth={1} /> }
              </div>
            }

            <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, width: "40px" }}>
              <IconButton style={{ color: 'var(--color-text3)', borderRadius: '50%', transition: 'background-color 0.3s ease', marginLeft: 'auto',   }}
                aria-label="View notes"
                onClick={(e) => handleMoreVerticalClick(e, item)}
              >
                <Ellipsis size={20} color='var(--color-text3)' strokeWidth={1} />
              </IconButton>
            </div>

            
          </div>
        ))}

        <nav aria-label="Pagination" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', padding: "1rem" }}>
          <button
            style={{ padding: '8px 16px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', borderRadius: '20px', cursor: currentPage === 1 ? 'not-allowed' : 'pointer', fontSize: '16px', opacity: currentPage === 1 ? 0.5 : 1 }}
            onClick={onPreviousPage}
            disabled={currentPage === 1}
            aria-label="Go to previous page"
            aria-disabled={currentPage === 1}
          >
            ←
          </button>
          <span style={{ color: 'var(--color-text3)', fontSize: '14px' }} aria-live="polite" aria-atomic="true">
            Page {currentPage} of {totalPages} | {totalItems} items
          </span>
          <button
            style={{ padding: '8px 16px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', borderRadius: '20px', cursor: currentPage === totalPages ? 'not-allowed' : 'pointer', fontSize: '16px', opacity: currentPage === totalPages ? 0.5 : 1 }}
            onClick={onNextPage}
            disabled={currentPage === totalPages}
            aria-label="Go to next page"
            aria-disabled={currentPage === totalPages}
          >
            →
          </button>
        </nav>

      </div>


      <ResourceInfoModal 
        resourceInfoModalId={resourceInfoModalId} 
        setResourceInfoModalId={setResourceInfoModalId} 
        setData={setData}
      />
    </>
  );
};

export default ResourcesTable;





    //   const [searchTerm, setSearchTerm] = useState('');
    // const [bioFilter, setBioFilter] = useState('');
    // const [typeFilter, setTypeFilter] = useState('');
    // const filteredFiles = data.filter(file => 
    //   file?.title?.toLowerCase().includes(searchTerm.toLowerCase()) &&
    //   (bioFilter === '' || file.category === bioFilter) &&
    //   (typeFilter === '' || file.type === typeFilter)
    // );




      {/* <div style={{ display: 'flex', gap: '.5rem', marginBottom: '.5rem' }}>
        <input style={{ display: "flex", flexDirection: "row", flex: 1, padding: '1rem', border: '1px solid var(--color-separatorOpaque)', borderRadius: '1rem', fontSize: isMobileAndBelow ? '16px' : '1rem', color: `var(--color-text1)`, '::placeholder': { color: 'var(--color-text3)' } }}
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select style={{ padding: '1rem', border: '1px solid var(--color-separatorOpaque)', borderRadius: '1rem', fontSize: "1rem", color: `var(--color-text3)` }}
          value={bioFilter}
          onChange={(e) => setBioFilter(e.target.value)}
        >
          <option value="" hidden>Class</option>
          { user?.categories?.map((category, index) => (
            <option key={index} value={category}>{category}</option>
          ))}
        </select>
      </div> */}





        {/* <select style={{ marginRight: '10px', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', fontSize: "1rem", color: `var(--color-text3)` }}
          value={typeFilter}
          onChange={(e) => setTypeFilter(e.target.value)}
          
        >
          <option value="">Type</option>
          <option value="Document">Document</option>
          <option value="Image">Image</option>
          <option value="Video">Video</option>
        </select> */}







      //   <div style={{ backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '1rem', overflow: 'hidden', marginBottom: '1rem', ...isMobileAndBelow && { padding: 0, border: 'none', borderRadius: 0, } }}
      //   role="region"
      //   aria-label="Notes list"
      // >

      //   { dataDisplayed && dataDisplayed.length > 0 && dataDisplayed.map((item, index) => (
      //     <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left', padding: "1rem", fontSize: '1rem', 
      //       borderBottom: '1px solid var(--color-separator)', cursor: 'pointer', transition: 'background-color 0.3s ease' }}
      //       key={index}
      //       onClick={(e) => handleRowClick(e, item)}
      //       onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
      //       onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
      //     >
      //       <div style={{ minWidth: '20px', marginRight: '1rem' }}>
      //         {React.cloneElement(iconDirectory[item.uploadMethod], { size: 20, color: 'var(--color-text1)', style: { verticalAlign: 'middle', strokeWidth: 1 } })}
      //       </div>
      //       <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", flex: 1, minWidth: 0 }}>

      //         <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem', width: '100%' }}>
      //           <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      //             {item.title || "Document"}
      //           </span>
      //         </div>

      //         <span style={{ fontSize: '.9rem', color: 'var(--color-text4)', marginTop: '.2rem' }}
      //           >{format(new Date(item.date), "MMM d, yyyy")}  •  {item.category}
      //         </span>
      //       </div>

      //       {/* <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", minWidth: 0, width: "10rem" }}>
      //         <span style={{ fontSize: '.9rem', color: 'var(--color-text4)', marginTop: '.2rem' }}
      //           >{item.category}
      //         </span>
      //       </div>

      //       <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", minWidth: 0, width: "10rem" }}>
      //         <span style={{ fontSize: '.9rem', color: 'var(--color-text4)', marginTop: '.2rem' }}
      //           >{format(new Date(item.date), "MMM d, yyyy")}
      //         </span>
      //       </div> */}

      //       <div style={{ display: 'flex', alignItems: 'center', gap: '.7rem', marginLeft: '1rem', flexShrink: 0 }}>

      //         { !isMobileAndBelow &&
      //           <>
      //             { item.notes?.urlText && <FileText size={18} color='var(--color-text3)' strokeWidth={1} style={{ cursor: 'pointer' }} title="notes" /> }
      //             { item.flashcards?.content && <Zap size={18} color='var(--color-text3)' strokeWidth={1} /> }
      //             { item.quiz?.content && <BookOpenCheck size={18} color='var(--color-text3)' strokeWidth={1} /> }
      //             { item.teach?.content && <Rabbit size={18} color='var(--color-text3)' strokeWidth={1} /> }
      //           </>
      //         }

      //         <IconButton style={{ color: 'var(--color-text3)', borderRadius: '50%', transition: 'background-color 0.3s ease', marginLeft: 'auto'   }}
      //         aria-label="View notes"
      //         onClick={(e) => handleMoreVerticalClick(e, item)}
      //       >
      //         <Ellipsis size={20} color='var(--color-text3)' strokeWidth={1} />
      //       </IconButton>
      //       </div>

            
      //     </div>
      //   ))}

      //   <nav aria-label="Pagination" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', padding: "1rem" }}>
      //     <button
      //       style={{ padding: '8px 16px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', borderRadius: '20px', cursor: currentPage === 1 ? 'not-allowed' : 'pointer', fontSize: '16px', opacity: currentPage === 1 ? 0.5 : 1 }}
      //       onClick={onPreviousPage}
      //       disabled={currentPage === 1}
      //       aria-label="Go to previous page"
      //       aria-disabled={currentPage === 1}
      //     >
      //       ←
      //     </button>
      //     <span style={{ color: 'var(--color-text3)', fontSize: '14px' }} aria-live="polite" aria-atomic="true">
      //       Page {currentPage} of {totalPages} | {totalItems} items
      //     </span>
      //     <button
      //       style={{ padding: '8px 16px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', borderRadius: '20px', cursor: currentPage === totalPages ? 'not-allowed' : 'pointer', fontSize: '16px', opacity: currentPage === totalPages ? 0.5 : 1 }}
      //       onClick={onNextPage}
      //       disabled={currentPage === totalPages}
      //       aria-label="Go to next page"
      //       aria-disabled={currentPage === totalPages}
      //     >
      //       →
      //     </button>
      //   </nav>

      // </div>