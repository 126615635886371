import React, { useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { sendSlackNotification } from '../misc/utils';
import { SLACK_WEBHOOK_CHANNELS } from '../misc/constants';
import { Routes } from '../navigation/routes';
import { API, dbRefs } from '../misc/constants';

const NotesFeedbackScreen = () => {
  const { file_id } = useParams();
  const [rating, setRating] = useState(0);
  const [explanation, setExplanation] = useState('');
  const [additionalFeedback, setAdditionalFeedback] = useState('');
  const navigate = useNavigate();
  const ratingRefs = useRef([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let feedbackObj = {
        rating: rating,
        ratingExplanation: explanation,
        additionalFeedback: additionalFeedback
      }
      await axios.put(`${API}/generalUpdateOne`, {
        matchObj: { _id: file_id },
        updateObj: { $set: { [`notes.feedback`]: feedbackObj } },
        dbRef: dbRefs.resources,
      });

      const fullMessage = `-- Feedback --\nRating: ${rating}\nExplanation: ${explanation}\nAdditional Feedback: ${additionalFeedback}`;
      await sendSlackNotification(fullMessage, SLACK_WEBHOOK_CHANNELS.messages);

      navigate(Routes.LANDING)
      alert('Thank you for your feedback!');
    } 
    catch (error) {
      console.error('Error submitting feedback:', error);
      alert('There was an error submitting your feedback. Please try again.');
    }
  };

  const INPUT_FONT_SIZE = "1rem"
  const GAP_DISTANCE = "3rem"
  const label_style = { display: 'block', marginBottom: '10px', color: 'var(--color-text1)', textAlign: 'left', fontSize: '16px', padding: '0px 3px' }
  const textarea_style = { width: '100%', padding: '10px', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', backgroundColor: 'var(--color-backgroundSecondary)', color: 'var(--color-text1)', minHeight: '100px', fontSize: INPUT_FONT_SIZE, fontSize: '16px', transform: 'scale(1)', transformOrigin: 'left top', }
  const rating_label_style = { fontSize: '14px', padding: '0px 3px', color: 'var(--color-text2)' }

  const data_rating = [1, 2, 3, 4, 5]

  const handleKeyDown = (e, index) => {
    if (e.key === 'ArrowLeft' && index > 0) {
      ratingRefs.current[index - 1].focus();
      setRating(index);
    } else if (e.key === 'ArrowRight' && index < data_rating.length - 1) {
      ratingRefs.current[index + 1].focus();
      setRating(index + 2);
    }
  };

  const Tile_Rating = ({ item, index }) => {
    return (
      <button
        ref={el => ratingRefs.current[index] = el}
        type="button"
        aria-label={`Rate ${item} out of 5`}
        aria-pressed={rating === item}
        tabIndex={0}  // Explicitly make the button focusable
        style={{
          width: '40px',
          aspectRatio: '1/1',
          borderRadius: '50%',
          border: '2px solid var(--color-primary)',
          backgroundColor: rating === item ? 'var(--color-primary)' : 'transparent',
          cursor: 'pointer',
          color: rating === item ? 'var(--color-textButton)' : 'var(--color-primary)',
          fontSize: '16px',
          fontWeight: 'bold',
          padding: 0,  // Remove default padding
          margin: '0 5px',  // Add some horizontal spacing
        }}
        onClick={() => setRating(item)}
        onKeyDown={(e) => handleKeyDown(e, index)}
      >
        {item}
      </button>
    )
  }


  return (
    <div id="wrapper">          
      <main id="main">
        <div className="inner" style={{ alignItems: 'center' }}>
          <h1 style={{marginBottom: GAP_DISTANCE, color: 'var(--color-text1)', fontSize: "2rem", fontWeight: 'bold', marginTop: '2rem', }}>
            Notes Feedback
          </h1>
          
          <form onSubmit={handleSubmit} style={{maxWidth: '100%', width: "450px", }}>
            
            <div style={{marginBottom: GAP_DISTANCE}}>
              <fieldset>
                <legend style={label_style}>1. Rate the quality of your notes</legend>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1rem' }} role="group" aria-label="Rating scale">
                  <span style={rating_label_style} id="rating-poor">Poor</span>
                  {data_rating.map((item, index) => (
                    <Tile_Rating key={item} item={item} index={index} />
                  ))}
                  <span style={rating_label_style} id="rating-excellent">Excellent</span>
                </div>
              </fieldset>
            </div>
            
            <div style={{marginBottom: GAP_DISTANCE}}>
              <label htmlFor="explanation" style={label_style}>
                2. Explain your rating <span style={{color: 'var(--color-text3)'}}>(optional)</span>
              </label>
              <textarea 
                style={textarea_style}
                id="explanation"
                value={explanation}
                onChange={(e) => setExplanation(e.target.value)}
                aria-describedby="explanation-desc"
              />
              <span id="explanation-desc" style={{display: 'none'}}>Provide additional context for your rating</span>
            </div>

            <div style={{marginBottom: GAP_DISTANCE}}>
              <label htmlFor="additionalFeedback" style={label_style}>
                3. Additional feedback for us <span style={{color: 'var(--color-text3)'}}>(optional)</span>
              </label>
              <textarea 
                style={textarea_style}
                id="additionalFeedback"
                value={additionalFeedback}
                onChange={(e) => setAdditionalFeedback(e.target.value)}
                aria-describedby="additional-feedback-desc"
              />
              <span id="additional-feedback-desc" style={{display: 'none'}}>Share any other thoughts or suggestions you have</span>
            </div>

            <button 
              type="submit" 
              style={{width: '100%', padding: '.7rem', backgroundColor: 'var(--color-primary)', color: 'var(--color-textButton)', border: 'none', borderRadius: '.5rem', cursor: 'pointer', fontSize: "16px", fontWeight: '400'}}
              aria-label="Submit feedback"
            >
              Submit Feedback
            </button>
          </form>
        
        </div>
      </main>
    </div>
  );
};

export default NotesFeedbackScreen;