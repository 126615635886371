'use client';
import { useContext, useEffect } from 'react';
import axios from 'axios';
import { Drawer } from 'vaul';
import { UserContext } from '../contexts/UserProvider';
import { sendSlackNotification } from '../misc/utils';
import { SLACK_WEBHOOK_CHANNELS } from '../misc/constants';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../navigation/routes';
import SchoolGoat from '../assets/schoolgoat.webp';

const PermissionDeniedModal = () => {
  const { user, openPermissionDeniedModal, setOpenPermissionDeniedModal } = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (openPermissionDeniedModal) {
      const message = `Free trial expired modal\nEmail: ${user?.email}`;
      sendSlackNotification(message, SLACK_WEBHOOK_CHANNELS.paywall);
    }
  }, [openPermissionDeniedModal, user?.email]);

  const onClose = () => {
    setOpenPermissionDeniedModal(false)
  }

  const handleGetMembership = () => {
    setOpenPermissionDeniedModal(false)
    navigate('/app/membership')
  }

  return (
    <Drawer.Root dismissable={false} open={openPermissionDeniedModal} onOpenChange={onClose}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.4)', zIndex: 1000 }} />
        <Drawer.Content style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', position: 'fixed', bottom: 0, left: 0, right: 0, maxHeight: '90vh', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', zIndex: 1001 }}
          aria-labelledby="drawer-title"
          role="alertdialog"
          aria-describedby="drawer-description"
          aria-modal="true"
        >
          <div style={{ maxWidth: "32rem", width: '100%', margin: '0 auto', overflowY: 'auto', padding: '32px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingBottom: '16px' }}>           
            <Drawer.Title className="modalTitle"
              id="drawer-title"
              >Your free trial has expired
            </Drawer.Title>

            <Drawer.Description className="modalDescription"
              id="drawer-description"
              >Your 4 days of free access have ended. To continue unlimited usage of School Goat, become a member!
            </Drawer.Description>

            <button className="modalButton"
              onClick={handleGetMembership}
              onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-primaryLight)'}
              onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-primary)'}
              >Check it out
            </button>

          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export default PermissionDeniedModal;
