'use client';
import React, { useRef, useEffect } from 'react';
import { Drawer } from 'vaul';

const MicrophonePermissionDeniedModal = ({ open, setOpen }) => {
  const refreshButtonRef = useRef(null);
  const dismissButtonRef = useRef(null);

  useEffect(() => {
    if (open && refreshButtonRef.current) {
      setTimeout(() => refreshButtonRef.current.focus(), 100);
    }
  }, [open]);

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Drawer.Root open={open} onOpenChange={setOpen}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.4)', zIndex: 1000 }} />
        <Drawer.Content 
          style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', position: 'fixed', bottom: 0, left: 0, right: 0, maxHeight: '82vh', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', zIndex: 1001 }}
          aria-labelledby="drawer-title"
          role="alertdialog"
          aria-describedby="drawer-description"
          aria-modal="true"
        >
          <div style={{ maxWidth: '28rem', width: '100%', margin: '0 auto', overflowY: 'auto', padding: '1rem', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingBottom: '2rem' }}>
            <Drawer.Title className="modalTitle"
              id="drawer-title"
            >
              Microphone access required
            </Drawer.Title>
            <Drawer.Description id="drawer-description" style={{ lineHeight: '1.4', marginTop: '0.5rem', color: 'var(--color-text3)', fontSize: '16px' }}>
              In order to take notes in class, you need to allow microphone access. Hit 'Refresh' to reset permissions. If that doesn't work, enable microphone permission for this site in your browser settings.
            </Drawer.Description>
            <button 
              className="modalButton"
              ref={refreshButtonRef}
              onClick={handleRefresh}
              // onKeyDown={handleKeyDown}
              aria-label="Refresh page to reset microphone permissions"
            >
              Refresh
            </button>
            <button className="modalButton modalButtonSecondary"
              ref={dismissButtonRef}
              onClick={() => setOpen(false)}
              aria-label="Dismiss microphone permission request"
            >
              Dismiss
            </button>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default MicrophonePermissionDeniedModal;