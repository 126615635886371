import React, { useState, useRef, useContext } from 'react';
import { ArrowUpIcon, Upload, FileText } from 'lucide-react';
import { API } from '../misc/constants';
import axios from 'axios';
import SelectResourceModal from '../modals/SelectResourceModal';
import { useBreakpoint } from '../misc/useBreakpoint';
import { getTextFromResource } from '../misc/utils';
import { UserContext } from '../contexts/UserProvider';

const ChatGptInterface = ({ onSubmit, isActive, header, placeholder }) => {
  const { checkUserPermission } = useContext(UserContext)
  const { isPhoneAndBelow } = useBreakpoint()
  const [ input, setInput ] = useState('')
  const [ selectResourceModalOpen, setSelectResourceModalOpen ] = useState(false)
  const [ error, setError ] = useState(false)
  const fileInputRef = useRef(null);

  const data = [
    { icon: <FileText />, title: "My Files", onClick: () => {
      if (!checkUserPermission()) return;
      setSelectResourceModalOpen(true)
    } },
    { icon: <Upload />, title: "New File", onClick: () => {
      if (!checkUserPermission()) return;
      fileInputRef.current.click()
    } },
  ]

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    
    if (file && file.type === 'application/pdf') {
      try {
        await onSubmit({ file: file })
      } 
      catch (error) {
        console.error('Error uploading PDF:', error);
      } 
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      await onSubmit({ textInput: input })
    }
    catch (error) {
      console.error('Error in handleSubmit:', error);
      setError(true)
    }
  }

  if ( !isActive ) return null

  return (
    <div id="main" style={{ alignItems: "center", justifyContent: "center", height: '100%', }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "center", height: '100%', padding: '10rem 0', maxWidth: '700px', alignSelf: 'center', width: '100%', }}>
        <h1 style={{ fontSize: '2rem', fontWeight: '600', textAlign: 'center', marginBottom: '2rem', lineHeight: '1.5' }}
        >{header}
        </h1>

        <form  style={{ display: "flex", marginBottom: '20px', width: '100%', background: 'var(--color-background)', borderRadius: '100px', padding: ".5rem", alignItems: 'center' }}
          onSubmit={handleSubmit}
        >
          <input style={{ width: '100%', border: 'none', background: 'transparent', fontSize: '16px', outline: 'none', padding: ".75rem", }}
            placeholder={placeholder}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            aria-label="Search input"
          />
          <button style={{ width: '40px', height: '40px', backgroundColor: input !== '' ? 'var(--color-primary)' : 'var(--color-backgroundTertiary)', color: 'white', borderRadius: '100%', cursor: 'pointer', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', }}
            type="submit"
            aria-label="Submit search"
          >
            <ArrowUpIcon size={24} />
          </button>
        </form>
        
        <div style={{display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'center'}}>
          {data.map((item, index) => (
            <button style={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '.75rem 1rem', background: 'white', border: '1px solid var(--color-separatorOpaque)', borderRadius: '30px', cursor: 'pointer', fontSize: '16px', color: '#444', transition: 'background 0.2s',  }}
              onClick={item.onClick}
              key={index}
              onMouseEnter={(e) => e.currentTarget.style.background = 'var(--color-background)'}
              onMouseLeave={(e) => e.currentTarget.style.background = 'transparent'}
            >
              {React.cloneElement(item.icon, { size: 18, color: 'var(--color-primary)'})}
              {item.title}
            </button>
          ))}
        </div>

        { error && <p style={{ color: 'red', textAlign: 'center', marginTop: '1rem' }}>An error occurred. Please try again.</p> }
      </div>

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileUpload}
        accept=".pdf"
        style={{ display: 'none' }}
      />

      <SelectResourceModal
        open={selectResourceModalOpen} 
        setOpen={setSelectResourceModalOpen} 
        onSelect={async(item) => await onSubmit({ resourceObj: item })}
      />
    </div>
  )
}

export default ChatGptInterface