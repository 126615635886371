import './css/App.css';
import './css/Drawer.css';
import React, { useContext, useEffect, useState } from 'react';
import { Routes } from './navigation/routes';
import { UserContext, UserProvider } from './contexts/UserProvider';
import ErrorScreen from './screens/ErrorScreen';
import { createBrowserRouter, Navigate, redirect, RouterProvider } from "react-router-dom";
import ProtectedRoute from './navigation/ProtectedRoute';
import TermsScreen from './screens/TermsScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicy';
import { ThemeProvider } from '@mui/material';
import theme from './contexts/ThemeProvider';
import ReactGA from "react-ga4";
import LandingScreen from './screens/LandingScreen4';
import OptionsScreen from './screens/OptionsScreen';
import ReferralsScreen from './screens/ReferralsScreen';
import TermsPartnerProgramScreen from './screens/TermsPartnerProgramScreen';
import NotesFeedbackScreen from './screens/NotesFeedbackScreen';
import QuizScreen from './screens/QuizScreen';
import SolveScreen from './screens/SolveScreen';
import EssayScreen from './screens/EssayScreen';
import NotesInClassScreen from './screens/NotesInClassScreen';
import NotesConvertScreen from './screens/NotesConvertScreen3';
import FlashcardsScreen from './screens/FlashcardsScreen';
import EssayEvaluationScreen from './screens/EssayEvaluationScreen';
import EssayOutlineScreen from './screens/EssayOutlineScreen';
import ShortcutReadingScreen from './screens/ShortcutReadingScreen';
import MembershipScreen from './screens/MembershipScreen';
import TeachScreen from './screens/TeachScreen';
import ResourcesScreen from './screens/ResourcesScreen';
import ResourcesProfileScreen from './screens/ResourcesProfileScreen';
import EmailTest from './screens/EmailTest';
ReactGA.initialize("G-54F08M4G3Z");

const App = () => {

  const router = createBrowserRouter([

    { path: '/', element: <LandingScreen />, errorElement: <ErrorScreen /> },
    
    {
      path: Routes.APP, 
      element: <ProtectedRoute />,
      errorElement: <ErrorScreen />,
      children: [
        { index: true, element: <Navigate to={Routes.SOLVE} replace /> },
        { path: Routes.MEMBERSHIP, element: <MembershipScreen /> },
        { path: Routes.SOLVE, element: <SolveScreen /> },
        // { path: Routes.SHORTCUT_READING, element: <ShortcutReadingScreen /> },
        { path: Routes.NOTES_IN_CLASS, element: <NotesInClassScreen /> },
        { path: Routes.NOTES_CONVERT, element: <NotesConvertScreen /> },
        { path: Routes.FLASHCARDS, element: <FlashcardsScreen /> },
        { path: Routes.QUIZ, element: <QuizScreen /> },
        // { path: Routes.ESSAY, element: <EssayScreen /> },
        { path: Routes.REFERRALS, element: <ReferralsScreen /> },
        { path: Routes.OPTIONS, element: <OptionsScreen /> },
        { path: Routes.ESSAY_EVALUATION, element: <EssayEvaluationScreen /> },
        // { path: Routes.ESSAY_OUTLINE, element: <EssayOutlineScreen /> },
        { path: Routes.TEACH, element: <TeachScreen /> },
        { path: Routes.RESOURCES, element: <ResourcesScreen /> },
        { path: Routes.RESOURCES_PROFILE, element: <ResourcesProfileScreen /> },
      ]
    },
    { path: Routes.TERMS, element: <TermsScreen /> },
    { path: Routes.TERMS_PARTNER_PROGRAM, element: <TermsPartnerProgramScreen /> },
    { path: Routes.PRIVACY, element: <PrivacyPolicyScreen /> },
    { path: Routes.NOTES_FEEDBACK, element: <NotesFeedbackScreen /> },
    // { path: '/emailtest', element: <EmailTest />, errorElement: <ErrorScreen /> },
     
    {
      path: '*',
      element: <Navigate to="/" replace />
    }



  ]);

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <RouterProvider router={router} />
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;




    // {
    //   path: '/writeassist', 
    //   element: <JennyScreen />,
    //   errorElement: <ErrorScreen />,
    // },
    // {
    //   path: '/maninfinance', 
    //   element: <DataScreen />,
    //   errorElement: <ErrorScreen />,
    // },