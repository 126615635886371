export const ESSAY_PARAMETERS = {
  // adherenceToPrompt: {
  //   title: "Adherence to Prompt",
  //   description: "Check if it addresses all parts of the prompt effectively.",
  //   grade: 0
  // },
  thesis: {
    title: "Thesis",
    description: "The presence, clarity, and strength of the central argument or main point.",
    grade: 0
  },
  structure: {
    title: "Structure & Organization",
    description: "The level of structural organization of the essay. Includes introduction, body, and conclusion. Laid out in clean manner.",
    grade: 0
  },
  mechanics: {
    title: "Mechanics",
    description: "Correctness of the spelling, grammar, and punctuation.",
    grade: 0
  },
  writingStyle: {
    title: "Writing Style and Voice",
    description: "Effectiveness of writing style, including clarity, expression, and voice.",
    grade: 0
  },
  coherenceFlow: {
    title: "Coherence and Flow",
    description: "Logical progression of ideas throughout the essay.",
    grade: 0
  },
  criticalThinking: {
    title: "Critical Thinking",
    description: "Depth of analysis and insight demonstrated in the essay.",
    grade: 0
  },
  evidenceSupport: {
    title: "Evidence and Support",
    description: "Use of relevant facts, examples, or data to back up claims.",
    grade: 0
  },
  argumentStrength: {
    title: "Argument Strength",
    description: "Persuasiveness and logical reasoning of the arguments presented.",
    grade: 0
  },
  researchQuality: {
    title: "Research Quality",
    description: "Depth and credibility of sources used (for research-based essays).",
    grade: 0
  },
  citationReferencing: {
    title: "Citation and Referencing",
    description: "Proper attribution of sources and adherence to citation standards.",
    grade: 0
  },
  objectiveTone: {
    title: "Objective Tone",
    description: "Maintenance of neutrality and avoidance of bias in the writing.",
    grade: 0
  },
  creativityOriginality: {
    title: "Creativity and Originality",
    description: "Uniqueness of ideas, storytelling approach, or writing style.",
    grade: 0
  },
  settingContext: {
    title: "Setting and Context",
    description: "Establishment of time, place, and atmosphere in the writing.",
    grade: 0
  },
  characterDevelopment: {
    title: "Character Development",
    description: "Depth and believability of characters (for narrative/creative writing).",
    grade: 0
  },
  descriptiveLanguage: {
    title: "Descriptive Language",
    description: "Use of vivid and sensory details in the writing.",
    grade: 0
  },
  reflectionDepth: {
    title: "Reflection Depth",
    description: "Level of self-awareness and personal growth shown.",
    grade: 0
  },
  emotionalImpact: {
    title: "Emotional Impact",
    description: "Ability to evoke feelings in the reader.",
    grade: 0
  },
  literaryDevices: {
    title: "Literary Devices",
    description: "Use and effectiveness of literary techniques and devices.",
    grade: 0
  }
};

const GENERAL_PARAMETERS = [ ESSAY_PARAMETERS.thesis, ESSAY_PARAMETERS.structure, ESSAY_PARAMETERS.mechanics, ESSAY_PARAMETERS.writingStyle, ESSAY_PARAMETERS.coherenceFlow, ESSAY_PARAMETERS.criticalThinking, ESSAY_PARAMETERS.evidenceSupport, ]

export const ESSAY_TYPES = {
  general: {
    _id: "general",
    title: "General Essay",
    parameters: [ ...GENERAL_PARAMETERS ]
  },
  research: {
    _id: "research",
    title: "Research Paper",
    parameters: [ ...GENERAL_PARAMETERS, ESSAY_PARAMETERS.researchQuality, ESSAY_PARAMETERS.citationReferencing, ESSAY_PARAMETERS.objectiveTone ]
  },
  argumentative: {
    _id: "argumentative",
    title: "Argumentative Essay",
    parameters: [ ...GENERAL_PARAMETERS, ESSAY_PARAMETERS.argumentStrength ]
  },
  analytical: {
    _id: "analytical",
    title: "Analytical/Expository Essay",
    parameters: [ ...GENERAL_PARAMETERS, ]
  },
  narrative: {
    _id: "narrative",
    title: "Narrative Story",
    parameters: [ ESSAY_PARAMETERS.creativityOriginality, ESSAY_PARAMETERS.settingContext, ESSAY_PARAMETERS.characterDevelopment, ESSAY_PARAMETERS.descriptiveLanguage, ESSAY_PARAMETERS.emotionalImpact, ESSAY_PARAMETERS.literaryDevices, ESSAY_PARAMETERS.writingStyle, ESSAY_PARAMETERS.mechanics ]
  },
  reflection: {
    _id: "reflection",
    title: "Personal Reflection",
    parameters: [ ESSAY_PARAMETERS.creativityOriginality, ESSAY_PARAMETERS.descriptiveLanguage, ESSAY_PARAMETERS.reflectionDepth, ESSAY_PARAMETERS.emotionalImpact, ESSAY_PARAMETERS.writingStyle, ESSAY_PARAMETERS.mechanics ]
  },
  literature: {
    _id: "literature",
    title: "Literature Review",
    parameters: [ ...GENERAL_PARAMETERS, ESSAY_PARAMETERS.researchQuality, ESSAY_PARAMETERS.citationReferencing, ESSAY_PARAMETERS.objectiveTone ]
  },
  creative: {
    _id: "creative",
    title: "Creative Writing",
    parameters: [ ESSAY_PARAMETERS.creativityOriginality, ESSAY_PARAMETERS.settingContext, ESSAY_PARAMETERS.characterDevelopment, ESSAY_PARAMETERS.descriptiveLanguage, ESSAY_PARAMETERS.emotionalImpact, ESSAY_PARAMETERS.literaryDevices, ESSAY_PARAMETERS.writingStyle, ESSAY_PARAMETERS.mechanics ]
  },
  compare: {
    _id: "compare",
    title: "Compare and Contrast",
    parameters: [ ...GENERAL_PARAMETERS ]
  }
};