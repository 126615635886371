import React from 'react';
import { RotateCcw } from 'lucide-react';
import { useState } from 'react';
import axios from 'axios';
import { API } from '../misc/constants';

const buttonStyle = { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '.5rem', background: 'var(--color-primary)', border: 'none', cursor: 'pointer', padding: '1rem 1.8rem', borderRadius: '30px', color: 'var(--color-textButton)', fontSize: "1rem" }

export const GenerateComponent = ({ handleOnGenerate }) => {
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(false)

  const onSubmit = async () => {
    try {
      setLoading(true);
      setError(false);
      console.log("Setting loading to true");
      
      // Wait for handleOnGenerate to complete
      await handleOnGenerate();
      console.log("handleOnGenerate completed successfully");
    } 
    catch (error) {
      console.error("Error in handleOnGenerate:", error);
      setError(true);
    } finally {
      setLoading(false);
      console.log("Setting loading to false");
    }
  };

  const ErrorComponent = () => {
    return (
      <button style={{...buttonStyle, backgroundColor: 'var(--color-primaryLight)'}}
        onClick={handleOnGenerate}
      >
      <p style={{ fontSize: '1rem', color: 'var(--color-primary)' }}
        >Click to refresh
      </p>
        <RotateCcw size={16} color={'var(--color-primary)'}/>
      </button>
    )
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: '100%', padding: '1rem' }}>
      
      { loading ? 
          <div style={{ width: '30px', height: '30px', borderTop: '3px solid var(--color-primary)', borderRadius: '50%', animation: 'spin 1s linear infinite', marginTop: '1.5rem' }}>
            <style>{`@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }`}</style>
          </div>
      
      : error ? 
        <ErrorComponent /> 
      
      :
        <button style={{...buttonStyle, alignSelf: 'center', transition: 'background-color 0.3s ease'}} 
          onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-primaryLight)'}
          onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-primary)'}
          onClick={onSubmit}
        >
          Create New
        </button>
      
      }
    </div>
  )
}