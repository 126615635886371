import React, { useState, useEffect } from 'react';

const PrivacyPolicyScreen = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setIsLoading(true);
      const res = await fetch('https://academicweapon.s3.amazonaws.com/legal/(2024.02.09)+Privacy+Policy.txt');
      if (!res.ok) {
        throw new Error('Failed to fetch privacy policy');
      }
      const termsData = await res.text();
      setData(termsData);
    } catch (err) {
      setError('Failed to load privacy policy. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <main style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "50px", color: "var(--color-text1)"}}>
      <h1 id="privacy-policy-title">Privacy Policy</h1>
      {isLoading && <p aria-live="polite">Loading privacy policy...</p>}
      {error && <p aria-live="assertive" role="alert">{error}</p>}
      {data && (
        <article aria-labelledby="privacy-policy-title">
          <p style={{whiteSpace: "pre-wrap", fontSize: "16px", color: "var(--color-text1)"}}>
            {data}
          </p>
        </article>
      )}
    </main>
  );
};

export default PrivacyPolicyScreen;