'use client';
import { Drawer } from 'vaul';
import React, { useRef, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from '../contexts/UserProvider';
import { dbRefs, S3_URL_PREFIX, RESOURCES_UPLOAD_METHODS } from '../misc/constants';
import { initResourceObj, uploadResourceToS3 } from '../misc/utils';
import { API } from '../misc/constants';
import { Typography } from '@mui/material';
import ResourceProcessingModal from '../modals/ResourceProcessingModal';
import { useNavigate } from 'react-router-dom';

const FileUploader = ({ open, setOpen, }) => {
  const { user, checkUserPermission } = useContext(UserContext);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [ isUploading, setIsUploading ] = useState(false)
  const [openResourceProcessingModal, setOpenResourceProcessingModal] = useState(false);

  const fileInputRef = useRef(null);
  
  const validFileTypes = ['audio/mpeg', 'audio/mp3', 'audio/wav', 'audio/ogg', 'audio/m4a', 'audio/aac', 'audio/x-m4a', 'audio/mp4', 'audio/x-wav'];
  const validExtensions = ['.mp3', '.wav', '.ogg', '.m4a', '.aac', '.pdf'];

  const getFileExtension = (file) => {
    let extension = file.name.split('.').pop();
    let fullExtension = `.${extension}`
    return fullExtension
  }

  useEffect(() => {
    if ( open && file ) {
      checkFileTypeAndSize(file)
      getFileExtension(file)
    }
    else if ( !open ) {
      // onClose()
    }
  }, [open, file])


  const checkFileTypeAndSize = (file) => {
    if (
      !validFileTypes.includes(file.type) && 
      !validExtensions.some(ext => file.name.toLowerCase().endsWith(ext))
    ) {
      setFile(null)
      alert(`Invalid file type. Please select one of the following: ${validExtensions.join(', ')}. Contact support if you need more file types.`);
    }

    else if (file.size > 100 * 1024 * 1024) {
      setFile(null)
      alert('File is too large (max 100 MB). Let us know if you need more storage.');
    }
  }
 
  const handleClose = () => {
    setFile(null);
    setOpen(false);
    setIsUploading(false)
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.currentTarget.contains(e.relatedTarget)) return;
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const removeFile = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  };
  

  const uploadFile = async () => {
    if (!file) {
      alert('Please select a file first!');
      return;
    }

    try {
      setIsUploading(true)
      const extension = getFileExtension(file)

      if ( extension === '.pdf' ) {
        const doc = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
        const resource = await uploadResourceToS3({ resource_id: doc._id, file: file, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
        const sourceContent = await axios.post(`${API}/generateSourceContent`, { resource_id: doc._id, userCategories: user.categories })
        await axios.post(`${API}/generateNotes`, { file_id: doc._id,  })
        navigate(`/app/resources/${doc._id}`)
        handleClose();
        setOpenResourceProcessingModal(true);
      }
      else {
        const doc = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
        const resource = await uploadResourceToS3({ resource_id: doc._id, file: file, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
        axios.post(`${API}/generateSourceContent`, { resource_id: doc._id, userCategories: user.categories })
        handleClose();
        setOpenResourceProcessingModal(true);
      }
    } 
    catch (error) {
      alert(error.message);
    }
    finally {
      setIsUploading(false)
    }
  };


  const dropZoneStyle = {
    border: `2px dashed ${isDragging ? 'blue' : 'var(--color-backgroundTertiary)'}`,
    borderRadius: '1rem',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    minHeight: '250px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: isDragging || file ? 'rgba(0, 0, 255, 0.1)' : 'transparent',
    transition: 'background-color 0.3s, border-color 0.3s',
    marginBottom: '10px',
    marginTop: '10px',
  };

  const fileInfoStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
    gap: '12px',
  };

  const labelStyle = {
    display: 'block',
    marginTop: '20px',
    fontWeight: 'bold',
  };



  return (
    <>
    <Drawer.Root open={open} onOpenChange={setOpen}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }} />
        <Drawer.Content style={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'var(--color-backgroundSecondary)', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', zIndex: 1001 }}
          aria-labelledby="drawer-title"
          role="dialog"
          aria-modal="true"
          aria-describedby="drawer-description"
          onKeyDown={handleKeyDown}
        >
          <div style={{ padding: '32px', borderRadius: '8px', }}>

            <Drawer.Title className="modalTitle" style={{ marginTop: "0" }} id="drawer-title"
              >Convert File to Notes
            </Drawer.Title>

            <p id="drawer-description" className="sr-only"
              >Drop a file here or click to select
            </p>

            <div style={dropZoneStyle}
              tabIndex="0"
              role="button"
              aria-label={file ? `Selected file: ${file.name}. Click to change file or drop a new file.` : "Drop a file here or click to select"}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={() => fileInputRef.current.click()}
              onKeyPress={(e) => { if (e.key === 'Enter' || e.key === ' ') { fileInputRef.current.click() } }}
            >
          
              {file ? (
                <div style={fileInfoStyle}>
                  <p>{file.name}</p>
                  <IconButton onClick={(e) => { e.stopPropagation(); removeFile(); }}
                    aria-label="Remove file"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              
              ) : (
                  <>
                    <div style={{ textAlign: 'center', pointerEvents: 'none' }}>
                      <p style={{ color: 'var(--color-text3)', fontSize: '18px', fontWeight: '500', marginBottom: '8px' }}>
                        Drop a file here or click to select
                      </p>
                      <p style={{ color: 'var(--color-text3)', fontSize: '14px', display: 'inline-block' }}>
                        Supports PDFs and Audio (mp3, wav, ogg, m4a, aac)
                      </p>
                    </div>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      aria-label="Select file"
                    />
                  </>
                )}
              </div>

            { isUploading ? (
              <>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '1rem' }}>
                  <div style={{ width: '40px', height: '40px', border: '3px solid var(--color-separatorOpaque)', borderTop: '3px solid var(--color-primary)', borderRadius: '50%', animation: 'spin 1s linear infinite' }} />
                </div>
                <style>
                  {`
                    @keyframes spin {
                      0% { transform: rotate(0deg); }
                      100% { transform: rotate(360deg); }
                    }
                  `}
                </style>
              </>
            ) : (
              <div style={buttonContainerStyle}>
                <Button 
                  onClick={handleClose}
                  // onClickCapture={() => navigate('/app/resources/671e7a987a9db803cd7353c8')}
                  >Cancel
                </Button>
                <Button 
                  onClick={uploadFile} 
                  disabled={!file} 
                  variant="contained"
                  aria-label="Convert to Notes"
                  aria-disabled={!file}
                >
                  Convert to Notes
                </Button>
              </div>
            )}

          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>

    <ResourceProcessingModal open={openResourceProcessingModal} setOpen={setOpenResourceProcessingModal} />

    </>
  );
};

export default FileUploader;










// const validateAndGetAudioDuration = (file) => {
//   return new Promise((resolve, reject) => {
//     const xhr = new XMLHttpRequest();
//     xhr.open('GET', URL.createObjectURL(file), true);
//     xhr.responseType = 'arraybuffer';
//     xhr.onload = function(e) {
//       if (this.status == 200) {
//         const audioContext = new (window.AudioContext || window.webkitAudioContext)();
//         audioContext.decodeAudioData(this.response, 
//           (buffer) => {
//             // setValidationProgress(100);
//             resolve({
//               duration: buffer.duration,
//               isValid: true
//             });
//           },
//           (err) => {
//             reject(new Error('Unable to decode audio data. The file may be corrupted.'));
//           }
//         );
//       }
//     };

//     xhr.onerror = () => {
//       reject(new Error('Error occurred while reading the file.'));
//     };

//     xhr.send();
//   });
// };
