'use client';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../contexts/UserProvider';
import ScreenHeader, { ScreenHeader_Button } from '../components/ScreenHeader';
import FileUploader from '../components/FileUploader4';
import TextUploader from '../components/TextUploader';
import { BookOpen, FileText, Video, PenTool, File, Fullscreen, NotebookPen, ArrowRight, ChevronRight, MoveRight, Microscope, BookOpenText, FlaskConical } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';

const NotesConvertScreen = () => {
  const { user, checkUserPermission } = useContext(UserContext)
  const { isPhoneAndBelow } = useBreakpoint();  
  const [ openFileUploader, setOpenFileUploader ] = useState(false)
  const [ openTextUploader, setOpenTextUploader ] = useState(false)
  const fileInputRef = useRef(null);

  const onInitiateFileUpload = async () => {
    if (!checkUserPermission()) return;
    setOpenFileUploader(true)
  }

  const onInitiateTextUpload = async () => {
    if (!checkUserPermission()) return;
    setOpenTextUploader(true)
  }

  const OpeningComponent = () => {

    const containerStyle = { display: 'flex', height: "100%", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }
    const headerStyle = { fontSize: '1.2rem', fontWeight: 'bold', color: 'var(--color-text1)', lineHeight: '1.5' }
    const buttonStyle = { backgroundColor: 'var(--color-primary)', fontWeight: "600", color: 'white', borderRadius: '30px', fontSize: '1rem', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', transition: 'background-color 0.2s ease-in-out', padding: '1rem 2rem' }

    const data = [
      { title: "Textbook pages", icon: <BookOpenText />, },
      { title: "Articles", icon: <FileText /> },
      { title: "Recorded lectures", icon: <Video /> },
      { title: "Class files", icon: <PenTool /> },
      { title: "Assigned readings", icon: <BookOpen /> },
      { title: "Research papers", icon: <FlaskConical /> },
    ]

      if ( !isPhoneAndBelow ) {
        return (
          <div style={containerStyle} > 
            <NotebookPen size={60} style={{color: 'var(--color-primary)', marginBottom: '15px'}} strokeWidth={1} />

            <p style={headerStyle}
              >Turn class materials into clean notes
            </p>

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '.7rem', marginTop: '1.5rem', maxWidth: "550px", marginBottom: '2rem' }}>
              { data.map((item, index) => (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: ".8rem", gap: ".6rem", borderRadius: "30px", border: "1px solid var(--color-separatorOpaque)",  }}
                  key={index}
                >
                  {React.cloneElement(item.icon, { color: 'var(--color-primary)', size: 13 })}
                  <h4 style={{ fontSize: '1rem', color: 'var(--color-text3)', }}
                    >{item.title}
                  </h4>
                </div>
              )) }
            </div>
            
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: '1rem',  }}>
              <button style={buttonStyle}
                onClick={() => onInitiateFileUpload()}
                onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-primaryLight)'}
                onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-primary)'}
                // >File <MoveRight size={16} /> Notes
                >Upload File
              </button>

              <button style={buttonStyle}
                onClick={() => onInitiateTextUpload()}
                onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-primaryLight)'}
                onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-primary)'}
                // >Text<MoveRight size={16} />Notes
                >Paste Text
              </button>
            </div>

          </div>
        )
      }

      else {
        return (
          <div style={containerStyle} >
            <NotebookPen size={60} style={{color: 'var(--color-primary)', marginBottom: '15px'}} strokeWidth={1} />
            
            <p style={headerStyle}
              >Turn class materials into clean notes
            </p>

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '.7rem', marginTop: '1.5rem', maxWidth: "550px", marginBottom: '2rem' }}>
              { data.map((item, index) => (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: ".8rem", gap: ".6rem", borderRadius: "30px", border: "1px solid var(--color-separatorOpaque)",  }}>
                  {React.cloneElement(item.icon, { color: 'var(--color-primary)', size: 13 })}
                  <h4 style={{ fontSize: '1rem', color: 'var(--color-text3)', }}
                    >{item.title}
                  </h4>
                </div>
              )) }
            </div>
            
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: '1rem',  }}>
              <button style={buttonStyle}
                onClick={() => onInitiateFileUpload()}
                onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-primaryLight)'}
                onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-primary)'}
                // >File <MoveRight size={16} /> Notes
                >Upload File
              </button>

              <button style={buttonStyle}
                onClick={() => onInitiateTextUpload()}
                onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-primaryLight)'}
                onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-primary)'}
                // >Text<MoveRight size={16} />Notes
                >Paste Text
              </button>
            </div>

          </div>
      )
    }
  }



  return (

    <div id="wrapper" style={{ backgroundColor: 'var(--color-backgroundSecondary)' }}>          
      <main id="main">
        <div className="inner" style={{ width: "100%", overflowY: "hidden" }}>


        <ScreenHeader title="Convert to notes" />
        <OpeningComponent />

          {/* TOOLS */}
          <FileUploader open={openFileUploader} setOpen={setOpenFileUploader} />
          <TextUploader open={openTextUploader} setOpen={setOpenTextUploader} />

        </div>
      </main>
    </div>
  );  
}


export default NotesConvertScreen;
