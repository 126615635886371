// useBreakpoint.js
import { useMediaQuery } from '@mui/material';

export function useBreakpoint() {

  const BREAKPOINTS = {
    DESKTOP: '(min-width:981px)',          // Desktops, laptops
    TABLET_AND_ABOVE: '(min-width:737px)', // iPads, tablets
    TABLET_AND_BELOW: '(max-width:980px)', // iPads, tablets
    MOBILE_AND_BELOW: '(max-width:736px)', // This would catch phones in landscape mode
    PHONE_AND_BELOW: '(max-width:480px)'      // This catches most phones in portrait, including large iPhones (430px)
  };

  const isDesktop = useMediaQuery(BREAKPOINTS.DESKTOP);
  const isTabletAndAbove = useMediaQuery(BREAKPOINTS.TABLET_AND_ABOVE);
  const isTabletAndBelow = useMediaQuery(BREAKPOINTS.TABLET_AND_BELOW);
  const isMobileAndBelow = useMediaQuery(BREAKPOINTS.MOBILE_AND_BELOW);
  const isPhoneAndBelow = useMediaQuery(BREAKPOINTS.PHONE_AND_BELOW);

  return {
    isDesktop,
    isTabletAndAbove,
    isTabletAndBelow,
    isMobileAndBelow,
    isPhoneAndBelow
  };
}
