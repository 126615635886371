// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  // Customize your theme here
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#8b5cf6', // customize your primary color
      light: '#D7C8FE', // lighter shade of the main color
      dark: '#1E2580', // darker shade of the main color
    },
    secondary: {
      main: '#dc004e', // customize your secondary color
    },
  },
  // Add other customizations you might need
});

export default theme;
