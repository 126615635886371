import React, { } from 'react';
import { CircularProgress } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import SchoolGoat from '../assets/schoolgoat.webp';

import { PaperclipIcon, Refresh, RotateCcw } from 'lucide-react';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import { InlineMath, BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus, vs,  } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { COLOR_ARRAY } from '../misc/constants';
import { OPENAI_CHAT_ROLES } from '../misc/constants';
const defaultStyle = { marginTop: '8px', marginBottom: '10px', fontSize: "1rem", lineHeight: 1.8, textAlign: "left" };

const preprocessLatex = (content) => {
  return content
    .replace(/\\\[([\s\S]*?)\\\]/g, (_, p1) => `$$ ${p1.trim().replace(/\s+/g, ' ')} $$`) // Convert \[ ... \] to $$ ... $$, preserving line breaks
    .replace(/\\\(([\s\S]*?)\\\)/g, (_, p1) => `$ ${p1.trim().replace(/\s+/g, ' ')} $`)  // Convert \( ... \) to $ ... $, preserving line breaks
    .replace(/\[([^[\]]*?)\]/g, (_, p1) => `$$ ${p1.trim().replace(/\s+/g, ' ')} $$`) // Convert single [ ... ] to $$ ... $$, preserving line breaks
    .replace(/\\(?![($])([a-zA-Z]+)/g, '\\\\$1') // Escape backslashes in text, but not in LaTeX commands
    .replace(/\\\\([a-zA-Z]+)/g, '\\$1'); // Remove extra backslashes before LaTeX commands
};

const CodeBlock = ({ node, inline, className, children, ...props }) => {
  const match = /language-(\w+)/.exec(className || '');
  return !inline && match ? (
    <SyntaxHighlighter
      style={vscDarkPlus}
      customStyle={{ fontSize: "1rem", textAlign: "left", lineHeight: 1.3, marginTop: "-10px", marginBottom: "-10px", borderRadius: "10px", width: "100%",  }}
      language={match[1]}
      PreTag="div"
      {...props}
    >
      {String(children).replace(/\n$/, '')}
    </SyntaxHighlighter>
  ) : (
    <code className={className} {...props}>
      {children}
    </code>
  );
};


const SolveMessage = ({ item, index, isPhoneAndBelow }) => {
  const maxWidth = isPhoneAndBelow ? "80%" : "70%";

  if (item.role === OPENAI_CHAT_ROLES.system) {
    return null;
  }

  else if ( item.role === OPENAI_CHAT_ROLES.assistant ) {
    
    const preprocessedLatex = preprocessLatex(item?.content)

    return (

      <div style={{ display: "flex", flexDirection: "row", maxWidth: maxWidth}}>


        { !isPhoneAndBelow && (
          <img src={SchoolGoat} style={{ width: "3rem", height: "3rem", borderRadius: "100px", padding: ".3rem", marginRight: ".5rem", border: "1px solid var(--color-separatorOpaque)"}} />
        )}

        { item?.content ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", width: "100%", textAlign: "left"}}>
            <ReactMarkdown 
              remarkPlugins={[remarkBreaks, remarkMath, ]}
              rehypePlugins={[rehypeKatex, rehypeRaw]}
              components={{
                h1: ({ node, ...props }) => <h1 style={{ ...defaultStyle, fontSize: '1.6em', fontWeight: "bold" }} {...props} />,
                h2: ({ node, ...props }) => <h2 style={{ ...defaultStyle, fontSize: '1.3em', fontWeight: "bold" }} {...props} />,
                h3: ({ node, ...props }) => <h3 style={{ ...defaultStyle, fontSize: '1.25em', fontWeight: "bold" }} {...props} />,
                h4: ({ node, ...props }) => <h4 style={{ ...defaultStyle, fontSize: '1.15em', fontWeight: "bold" }} {...props} />,
                h5: ({ node, ...props }) => <h5 style={{ ...defaultStyle, fontSize: '0.83em', fontWeight: "bold" }} {...props} />,
                h6: ({ node, ...props }) => <h6 style={{ ...defaultStyle, fontSize: '0.67em', fontWeight: "bold" }} {...props} />,
                ul: ({ node, ...props }) => <ul style={{ ...defaultStyle, paddingLeft: '30px', listStyle: 'disc' }} {...props} />,
                ol: ({ node, ...props }) => <ol style={{ ...defaultStyle, paddingLeft: '30px', listStyle: 'decimal' }} {...props} />,
                blockquote: ({ node, ...props }) => <blockquote style={{ ...defaultStyle, paddingLeft: '10px', borderLeft: '3px solid #ccc' }} {...props} />,
                code: CodeBlock,
                p: ({ node, ...props }) => {
                  const content = node?.value || "";
                  return content.includes('$') ? <Latex style={defaultStyle} {...props} /> : <p style={defaultStyle} {...props}/>
                },
                li: ({ node, ...props }) => {
                  const content = node?.value || "";
                  return content.includes('$') ? <Latex style={defaultStyle} {...props} /> : <li style={{ paddingLeft: '0px', marginBottom: "5px", listStyle: 'inherit', lineHeight: 1.4 }} {...props} />;
                },
                pre: ({ node, ...props }) => <pre style={{ ...defaultStyle, backgroundColor: '#f4f4f4', padding: '10px', borderRadius: '4px', overflowX: 'auto' }} {...props} />,
                a: ({ node, ...props }) => <a style={{ color: '#007bff' }} {...props} />,
                hr: ({ node, ...props }) => <hr style={{ ...defaultStyle }} {...props} />
              }}
              >{preprocessedLatex}
            </ReactMarkdown>    
          </div>
        ) 
        
        // : item?.error ? (
        //   <button style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: '1rem', margin: 0, background: 'none', border: 'none', cursor: 'pointer', color: 'var(--color-text3)', gap: '.5rem', backgroundColor: `${COLOR_ARRAY[1]}33`, borderRadius: '100px' }}
        //     onClick={handleErrorRetry}
        //   >
        //     <p style={{ fontSize: '1rem', color: COLOR_ARRAY[1] }}
        //       >Error, click to retry
        //     </p>
        //     <RotateCcw size={16} color={COLOR_ARRAY[1]}/>
        //   </button>
        // ) 
        
        : (
          <CircularProgress size={13} color={"grey"} style={{ marginTop: "13px" }} />
        )
      }
    </div>  
  )
}

  else {

    let message = typeof item?.content === 'string' 
      ? item.content 
      : item?.content?.[0]?.image_url?.url 
      ? <PaperclipIcon size={16} />
      : ""

    return (
      <div style={{ ...defaultStyle, marginTop: "1rem", marginBottom: "1rem", backgroundColor: "var(--color-background)", padding: ".6rem 1rem", borderRadius: "2rem", alignSelf: "flex-end", maxWidth: maxWidth, textAlign: "left", lineHeight: 1.8}}
        >{message}
      </div>
    )
  }
};

export default SolveMessage;



    // const preprocessLatex = (content) => {
    //   return content
    //     // Convert \[ ... \] to $$ ... $$
    //     .replace(/\\\[([\s\S]*?)\\\]/g, (_, p1) => `$$ ${p1.replace(/\\/g, '\\\\')} $$`)
    //     // Convert \( ... \) to $ ... $
    //     .replace(/\\\(([\s\S]*?)\\\)/g, (_, p1) => `$ ${p1.replace(/\\/g, '\\\\')} $`)
    //     // Convert single [ ... ] to $$ ... $$
    //     .replace(/\[([^[\]]*?)\]/g, (_, p1) => `$$ ${p1.replace(/\\/g, '\\\\')} $$`)
    //     // Escape backslashes in text
    //     .replace(/\\(?![($])([a-zA-Z]+)/g, '\\\\$1');
    // };


        // const preprocessLatex = (content) => {
    //   return content
    //     // Convert \[ ... \] to $$\displaystyle ... $$
    //     .replace(/\\\[([\s\S]*?)\\\]/g, (_, p1) => `$$\\displaystyle ${p1.trim().replace(/\s+/g, ' ')} $$`)
    //     // Convert \( ... \) to $ ... $
    //     .replace(/\\\(([\s\S]*?)\\\)/g, (_, p1) => `$ ${p1.trim().replace(/\s+/g, ' ')} $`)
    //     // Convert single [ ... ] to $$\displaystyle ... $$
    //     .replace(/\[([^[\]]*?)\]/g, (_, p1) => `$$\\displaystyle ${p1.trim().replace(/\s+/g, ' ')} $$`)
    //     // Escape backslashes in text, but not in LaTeX commands
    //     .replace(/\\(?![($])([a-zA-Z]+)/g, '\\\\$1')
    //     // Remove extra backslashes before LaTeX commands
    //     .replace(/\\\\([a-zA-Z]+)/g, '\\$1');
    // };

// const processedContent2 = item?.content
// .replace(/\\\[([\s\S]*?)\\\]/g, '$$$1$$') 
// .replace(/\\\(([\s\S]*?)\\\)/g, '$$1$');  


// function unescapeLatex(str) {
//   return str.replace(/\\\\([[\]()])/g, '\\$1');
// }

        {/* <Latex style={{ ...defaultStyle, lineHeight: 1.8 }}
        delimiters={[
          { left: '$$', right: '$$', display: true },
          { left: '\\(', right: '\\)', display: false },
          { left: '$', right: '$', display: false },
          { left: '\\[', right: '\\]', display: true },
          { left: '\[', right: '\]', display: true },
          { left: '\(', right: '\)', display: false },
        ]}
        >{processedContent2}</Latex> */}

{/* <div style={{ marginTop: "30px"}}/>
<Markdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]} remarkOptions={remarkMathOptions}>
  {processedContent2}
</Markdown> */}

{/* <ReactMarkdown
      children={processedContent2}
      remarkPlugins={[remarkMath, ]}
      rehypePlugins={[rehypeKatex, rehypeRaw]}
    /> */}



            // h1: ({ node, ...props }) => <h1 style={{ ...defaultStyle, fontSize: '1.6em' }} {...props} />,
            // h2: ({ node, ...props }) => <h2 style={{ ...defaultStyle, fontSize: '1.3em' }} {...props} />,
            // h3: ({ node, ...props }) => <h3 style={{ ...defaultStyle, fontSize: '1.17em' }} {...props} />,
            // h4: ({ node, ...props }) => <h4 style={{ ...defaultStyle, fontSize: '1em' }} {...props} />,
            // h5: ({ node, ...props }) => <h5 style={{ ...defaultStyle, fontSize: '0.83em' }} {...props} />,
            // h6: ({ node, ...props }) => <h6 style={{ ...defaultStyle, fontSize: '0.67em' }} {...props} />,