'use client';
import { useContext } from 'react';
import axios from 'axios';
import { Drawer } from 'vaul';
import { UserContext } from '../contexts/UserProvider';
import { sendSlackNotification } from '../misc/utils';
import { SLACK_WEBHOOK_CHANNELS } from '../misc/constants';


const ContactModal = ({ open, setOpen }) => {
  const { user } = useContext(UserContext)

  const handleSubmit = async () => {
    const message = document.getElementById("message").value
    const fullMessage = `Contact: ${user.email}\nMessage: ${message}`;
    await sendSlackNotification(fullMessage, SLACK_WEBHOOK_CHANNELS.messages);
    setOpen(false)
  }

  return (
    <Drawer.Root open={open} onOpenChange={setOpen}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.4)', zIndex: 1000 }} />
        <Drawer.Content style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', position: 'fixed', bottom: 0, left: 0, right: 0, maxHeight: '82vh', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', zIndex: 1001 }}
          aria-labelledby="drawer-title"
          role="alertdialog"
          aria-describedby="drawer-description"
          aria-modal="true"
        >
          <div style={{ maxWidth: '28rem', width: '100%', margin: '0 auto', overflowY: 'auto', padding: '1rem', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingBottom: '2rem' }}>
            <Drawer.Handle />
            <Drawer.Title className="modalTitle"
              id="drawer-title"
              >Contact Us
            </Drawer.Title>
            <Drawer.Description className="modalDescription"
              id="drawer-description"
              >We want to hear from you. Let us know what's on your mind, and we'll shoot to get back to you <u>within the hour</u>!
            </Drawer.Description>
            
            <textarea  className="modalFormInput"
              style={{ minHeight: '200px', marginTop: '15px', alignItems: 'flex-start', justifyContent: 'flex-start', textAlign: 'left', verticalAlign: 'top', padding: '10px', fontSize: '16px', lineHeight: '1.4' }}
              type="text"
              id="message"
              placeholder="Message"
            />
            <button className="modalButton"
              onClick={handleSubmit}
              >Submit
            </button>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export default ContactModal;