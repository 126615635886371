import React, { useContext, useEffect, useRef, useState } from 'react';
import { API, dbRefs, RESOURCES_UPLOAD_METHODS } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import ScreenHeader from '../components/ScreenHeader';
import ResourcesTable from '../components/ResourcesTable';
import { useNavigate } from 'react-router-dom';
import { useBreakpoint } from '../misc/useBreakpoint';

const sampleData = [
  { _id: "1", title: "Intro to Psychology Lecture Notes", category: "Psychology 101", date: "2021-01-01", uploadMethod: RESOURCES_UPLOAD_METHODS.file },
  { _id: "2", title: "Calculus I - Derivatives", category: "Math 201", date: "2021-01-02", uploadMethod: RESOURCES_UPLOAD_METHODS.recording },
  { _id: "3", title: "American Civil War Essay", category: "History 202", date: "2021-01-03", uploadMethod: RESOURCES_UPLOAD_METHODS.file },
  { _id: "4", title: "Cell Biology Lab Report", category: "Biology 110", date: "2021-01-03", uploadMethod: RESOURCES_UPLOAD_METHODS.file },
  { _id: "5", title: "Spanish Conversation Practice", category: "Spanish 201", date: "2021-01-03", uploadMethod: RESOURCES_UPLOAD_METHODS.recording },
  { _id: "6", title: "Literary Analysis - Hamlet", category: "English 301", date: "2021-01-03", uploadMethod: RESOURCES_UPLOAD_METHODS.text },
  { _id: "7", title: "Chemical Reactions Study Guide", category: "Chemistry 101", date: "2021-01-03", uploadMethod: RESOURCES_UPLOAD_METHODS.text },
  { _id: "8", title: "Marketing Strategy Presentation", category: "Business 202", date: "2021-01-03", uploadMethod: RESOURCES_UPLOAD_METHODS.file },
  { _id: "9", title: "Computer Networks Assignment", category: "CS 305", date: "2021-01-03", uploadMethod: RESOURCES_UPLOAD_METHODS.file },
  { _id: "10", title: "Art History - Renaissance Period", category: "Art 201", date: "2021-01-03", uploadMethod: RESOURCES_UPLOAD_METHODS.file },
  { _id: "11", title: "Public Speaking Recording", category: "Communications 101", date: "2021-01-03", uploadMethod: RESOURCES_UPLOAD_METHODS.recording },
  { _id: "12", title: "Environmental Science Notes", category: "ENV 202", date: "2021-01-03", uploadMethod: RESOURCES_UPLOAD_METHODS.text },
  { _id: "13", title: "Sociology Research Methods", category: "Sociology 201", date: "2021-01-03", uploadMethod: RESOURCES_UPLOAD_METHODS.text  },
  { _id: "14", title: "Physics Problem Set Solutions", category: "Physics 201", date: "2021-01-03", uploadMethod: RESOURCES_UPLOAD_METHODS.file },
  { _id: "15", title: "Music Theory Analysis", category: "Music 101", date: "2021-01-03", uploadMethod: RESOURCES_UPLOAD_METHODS.file },
  { _id: "16", title: "Economics Final Study Guide", category: "Economics 202", date: "2021-01-03", uploadMethod: RESOURCES_UPLOAD_METHODS.file },

]


const ResourcesScreen = ({ }) => {
  const { user, checkUserPermission } = useContext(UserContext)
  const navigate = useNavigate()
  const { isMobileAndBelow } = useBreakpoint()
  const [ data, setData ] = useState([])
  


  ///////////////////////// GET DATA /////////////////////////
  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {

    let results = []
    
    if ( user ) {
      let result = await axios.get(API + '/generalAggregation', { params: {
        matchObj: JSON.stringify({ user_id: user?._id, }),
        // matchObj: JSON.stringify({ user_id: "x0LCyRwGuvdQxYeKTmGLjTIACEA3" }),
        sortObj: JSON.stringify({ date: -1 }),
        currentPage: 0,
        pageSize: 50,
        dbRef: dbRefs.resources
      }})
  
      results = result.data

      if ( results.length > 0 ) {
        setData(results)
      }
      else {
        let result = await axios.get(API + '/generalAggregation', { params: {
          matchObj: JSON.stringify({ user_id: "newuser" }),
          sortObj: JSON.stringify({ date: -1 }),
          dbRef: dbRefs.resources
        }})

        results = result.data
        setData(results)
      }
    }
    else {
      setData(sampleData)
    }

    
  }

  ///////////////////////// FUNCTIONS /////////////////////////

  const handleRowClick = (e, item) => {
    if (!checkUserPermission()) return;
    navigate(`/app/resources/${item._id}`)
  };

  return (
    <div id="wrapper">          
      <main id="main" style={{ ...isMobileAndBelow && { backgroundColor: 'var(--color-backgroundSecondary)', padding: 'var(--header-height) 0' } }}>
        <div className="inner">

          <ScreenHeader title="Your Resources" />
          <ResourcesTable data={data} setData={setData} handleRowClick={handleRowClick} />

        </div>
      </main>

    </div>
  );  
}


export default ResourcesScreen;

