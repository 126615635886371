'use client';
import { useContext } from 'react';
import axios from 'axios';
import { Drawer } from 'vaul';
import { UserContext } from '../contexts/UserProvider';
import { API } from '../misc/constants';


const ContentCreateModal = ({ open, setOpen, onSubmitText, onSubmitFile, header, placeholder }) => {
  const { user } = useContext(UserContext)

  const inputStyle = { border: '1px solid var(--color-separatorOpaque)', backgroundColor: 'white', width: '100%', paddingLeft: '0.75rem', paddingRight: '0.75rem', height: '3rem', outline: 'none', borderRadius: '0.5rem', fontSize: '1rem', color: 'var(--color-text1)', fontWeight: '500', fontFamily: 'inherit',  }

  const labelStyle = { fontWeight: 500, color: 'var(--color-text3)', fontSize: '16px', marginTop: '2rem', marginBottom: '0.5rem', display: 'block', fontFamily: 'inherit' }

  const buttonStyle = { backgroundColor: 'var(--color-primary)', color: 'var(--color-white)', borderRadius: '0.5rem', marginTop: '1rem', width: '100%', fontWeight: 500, fontSize: '1rem', height: '3rem', border: 'none', fontFamily: 'inherit', cursor: 'pointer' }


  const handleSubmit = async ({ textInput, file }) => {
    setOpen(false)

    if (textInput) {
      await onSubmitText(textInput)
    }
    else if (file) {
      await onSubmitFile(file)
    }
  }

  return (
    <Drawer.Root open={open} onOpenChange={setOpen}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.4)', zIndex: 1000 }} />
        <Drawer.Content style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', position: 'fixed', bottom: 0, left: 0, right: 0, maxHeight: '82vh', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', zIndex: 1001 }}
          aria-labelledby="drawer-title"
          role="alertdialog"
          aria-describedby="drawer-description"
          aria-modal="true"
        >
          <div style={{ maxWidth: '28rem', width: '100%', margin: '0 auto', overflowY: 'auto', padding: '1rem', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingBottom: '2rem' }}>
            <Drawer.Handle />
            <Drawer.Title style={{ fontWeight: 600, color: 'var(--color-text)', marginTop: '2rem', fontSize: '1.25rem' }}
              id="drawer-title"
              >{header}
            </Drawer.Title>
            {/* <Drawer.Description style={{ lineHeight: '1.4', marginTop: '0.5rem', color: 'var(--color-text3)', fontSize: '16px' }}
              id="drawer-description"
              >What would make School GOAT better for you? What do you like? Please, tell us! And we'll let you know if we can make it happen.
            </Drawer.Description> */}
            
            <textarea 
              type="text"
              id="message"
              placeholder={placeholder}
              style={{ ...inputStyle, minHeight: '200px', marginTop: '15px', alignItems: 'flex-start', justifyContent: 'flex-start', textAlign: 'left', verticalAlign: 'top', padding: '10px', fontSize: '16px', lineHeight: '1.4', fontWeight: "400" }}
            />
            <button style={buttonStyle}
              onClick={() => handleSubmit({ textInput: document.getElementById('message').value })}
              >Submit
            </button>

            <input
              type="file" 
              id="fileInput"
              accept=".pdf"
              style={{ display: 'none' }}
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  handleSubmit({ file });
                }
              }}
            />
            <button 
              style={{ ...buttonStyle, backgroundColor: 'var(--color-background)', color: 'var(--color-text1)', border: '1px solid var(--color-separatorOpaque)', fontWeight: '400' }}
              onClick={() => document.getElementById('fileInput').click()}
            >
              Select PDF file
            </button>


            
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export default ContentCreateModal;