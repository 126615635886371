import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { API, dbRefs, MIXPANEL_EVENTS, } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import { ChevronLeft, ChevronRight, ArrowRight, ArrowLeft, RotateCcw } from 'lucide-react';
import mixpanel from 'mixpanel-browser';
import { GenerateComponent } from './Resource_Components';
import { getTextFromResource } from '../misc/utils';

const cardStyle = { position: 'absolute', width: '100%', height: '100%', backfaceVisibility: 'hidden', backgroundColor: 'var(--color-backgroundSecondary)', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '1rem', fontSize: '1.4rem', padding: '4rem', lineHeight: '1.4', textAlign: 'center', border: '1px solid var(--color-separatorOpaque)', }
const buttonStyle = { background: 'var(--color-backgroundSecondary)', border: 'none', cursor: 'pointer', padding: '1rem 1.8rem', borderRadius: '30px', boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)' }


const RotateIcon = () => {
  return (
    <div style={{ position: 'absolute', bottom: '5px', right: '10px' }}>
      <RotateCcw size={20} color="var(--color-primary)" />
    </div>
  )
}

const Tile = ({ item, isFlippedRef }) => {
  return (
    <>
      <div style={{...cardStyle, transform: isFlippedRef ? 'rotateY(180deg)' : ''}}>
        {item?.term}
        <RotateIcon />
      </div>
      <div style={{...cardStyle, transform: !isFlippedRef ? 'rotateY(180deg)' : ''}}>
        {item?.description}
        <RotateIcon />
      </div>
    </>
  )
}

const Resource_Flashcards = ({ isActive = true, data = {}, setData, setRefreshData }) => {
  const { user, checkUserPermission } = useContext(UserContext)
  const [ content, setContent ] = useState([])
  const [ selectedIndex, setSelectedIndex ] = useState(0)
  const [isFlipped, setIsFlipped] = useState(false);
  const isFlippedRef = useRef(false);
  const handleFlip = () => setIsFlipped(!isFlipped);

  useEffect(() => {
    if ( isActive && data?.flashcards?.content ) {
      setContent(data?.flashcards?.content)
      setSelectedIndex(0)
    }
  }, [data, isActive])

  const handlePrev = () => {
    if (!checkUserPermission()) return;

    isFlippedRef.current = isFlipped
    setSelectedIndex((prev) => (prev > 0 ? prev - 1 : content.length - 1));

    mixpanel.track(MIXPANEL_EVENTS.flashcard_next, {
      user_id: user?._id,
      user_email: user?.email,
    });
  };

  const handleNext = () => {
    if (!checkUserPermission()) return;

    isFlippedRef.current = isFlipped
    setSelectedIndex((prev) => (prev < content.length - 1 ? prev + 1 : 0));

    mixpanel.track(MIXPANEL_EVENTS.flashcard_next, {
      user_id: user?._id,
      user_email: user?.email,
    });
  };  

  const handleOnGenerate = async () => {
    try {
      const text = await getTextFromResource(data)

      if ( text ) {
        await axios.post(`${API}/generateFlashcards`, { text: text, topic: '', resource_id: data?._id });
        setRefreshData((prev) => !prev)
      }
    }
    catch (error) {
      throw error
    }
  }


  if ( !isActive ) return null
  if ( data === null ) return null
  if ( !content || content.length === 0 ) return <GenerateComponent handleOnGenerate={handleOnGenerate} />

  return (
    <>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', width: "100%" }}>
      <div style={{ perspective: '7000px', width: "100%" }}>
        <div style={{ width: '100%', height: '30rem', position: 'relative', transition: 'transform 0.5s', transformStyle: 'preserve-3d', transform: isFlipped ? 'rotateY(180deg)' : '', cursor: 'pointer', }}
          onClick={handleFlip}
        >
          <Tile item={content?.[selectedIndex]} isFlippedRef={isFlippedRef.current} />
        </div>
      </div>

      <span style={{ fontSize: '.9rem', color: 'var(--color-text3)', fontWeight: '500' }}>{data?.title}</span>
      
      <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
        <button style={buttonStyle}
          onClick={handlePrev} 
        >
          <ArrowLeft size={28} color="var(--color-primary)" />
        </button>
        <span style={{ fontSize: '1.2rem', color: 'var(--color-text3)', width: "5rem", }}>
          {`${selectedIndex + 1} / ${content.length}`}
        </span>
        <button style={buttonStyle}
          onClick={handleNext} 
        >
          <ArrowRight size={28} color="var(--color-primary)" />
        </button>
      </div>

    </div>
    </>

  )

}

export default Resource_Flashcards;
