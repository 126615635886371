import React, { useContext, useEffect, useState, useCallback } from 'react';
import { API, dbRefs, MIXPANEL_EVENTS, } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import { Check, CheckCircle, Circle, Upload, X, RotateCcw } from 'lucide-react';
import { COLOR_ARRAY } from '../misc/constants';
import { useBreakpoint } from '../misc/useBreakpoint';
import mixpanel from 'mixpanel-browser';

const sampleQuizData = [
  {
    question: "Which scientific principle states that the entropy of an isolated system always increases over time?",
    answers: [
      { text: "First Law of Thermodynamics", isCorrect: false },
      { text: "Second Law of Thermodynamics", isCorrect: true },
      { text: "Law of Conservation of Energy", isCorrect: false },
      { text: "Newton's Third Law", isCorrect: false }
    ]
  },
  {
    question: "Who is associated with the concept of the Categorical Imperative in moral philosophy?",
    answers: [
      { text: "Jean-Paul Sartre", isCorrect: false },
      { text: "Friedrich Nietzsche", isCorrect: false },
      { text: "Immanuel Kant", isCorrect: true },
      { text: "John Locke", isCorrect: false }
    ]
  },
  {
    question: "Which psychological phenomenon describes the mental discomfort experienced when holding contradictory beliefs?",
    answers: [
      { text: "Cognitive Dissonance", isCorrect: true },
      { text: "Classical Conditioning", isCorrect: false },
      { text: "Confirmation Bias", isCorrect: false },
      { text: "Operant Conditioning", isCorrect: false }
    ]
  },
  {
    question: "What astronomical phenomenon is used to determine that the universe is expanding?",
    answers: [
      { text: "Blue Shift", isCorrect: false },
      { text: "Red Shift", isCorrect: true },
      { text: "Solar Parallax", isCorrect: false },
      { text: "Stellar Aberration", isCorrect: false }
    ]
  },
  {
    question: "Which artistic movement is characterized by small, visible brushstrokes and emphasis on light's changing qualities?",
    answers: [
      { text: "Cubism", isCorrect: false },
      { text: "Surrealism", isCorrect: false },
      { text: "Impressionism", isCorrect: true },
      { text: "Abstract Expressionism", isCorrect: false }
    ]
  },
  {
    question: "In economics, what term describes the value of the next-best alternative foregone when making a choice?",
    answers: [
      { text: "Sunk Cost", isCorrect: false },
      { text: "Opportunity Cost", isCorrect: true },
      { text: "Marginal Cost", isCorrect: false },
      { text: "Variable Cost", isCorrect: false }
    ]
  },
  {
    question: "What literary technique presents a character's thoughts and feelings as they occur, often without logical order?",
    answers: [
      { text: "Stream of Consciousness", isCorrect: true },
      { text: "Magical Realism", isCorrect: false },
      { text: "Dramatic Monologue", isCorrect: false },
      { text: "Free Verse", isCorrect: false }
    ]
  },
  {
    question: "Which organelle is known as the 'powerhouse of the cell' because it generates ATP?",
    answers: [
      { text: "Golgi Apparatus", isCorrect: false },
      { text: "Endoplasmic Reticulum", isCorrect: false },
      { text: "Mitochondria", isCorrect: true },
      { text: "Lysosome", isCorrect: false }
    ]
  },
  {
    question: "What period of geopolitical tension existed between the United States and Soviet Union from 1947-1991?",
    answers: [
      { text: "World War II", isCorrect: false },
      { text: "The Great Depression", isCorrect: false },
      { text: "The Cold War", isCorrect: true },
      { text: "The Space Race", isCorrect: false }
    ]
  },
  {
    question: "In music theory, what term describes the relationship between multiple musical lines that are harmonically interdependent but independent in rhythm?",
    answers: [
      { text: "Harmony", isCorrect: false },
      { text: "Counterpoint", isCorrect: true },
      { text: "Polyphony", isCorrect: false },
      { text: "Sonata Form", isCorrect: false }
    ]
  }
];

const colors = {
  error: {
    light: `${COLOR_ARRAY[1]}33`,
    dark: COLOR_ARRAY[1],
  },
  success: {
    light: `${COLOR_ARRAY[2]}33`,
    dark: `${COLOR_ARRAY[2]}`,
  }
}

const buttonStyle = { padding: '1rem', backgroundColor: 'var(--color-primary)', borderRadius: '100px', cursor: 'pointer', fontSize: '1rem', fontWeight: '600', color: 'var(--color-textButton)', border: '1px solid var(--color-separatorOpaque)', width: "10rem" }

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};


const Answers = ({ item,  }) => { 
  const [ selectedAnswers, setSelectedAnswers ] = useState([])
  const [shuffledAnswers, setShuffledAnswers] = useState([]);

  useEffect(() => {
    setShuffledAnswers(shuffleArray(item?.answers || []));
    setSelectedAnswers([])
  }, [item]);

  const handleAnswerSelect = (index) => {
    setSelectedAnswers([...selectedAnswers, index])
  }

  return (
    <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
      {shuffledAnswers.map((answer, index) => {

        const selected = selectedAnswers.includes(index)
        const correct = answer.isCorrect
        const darkColor = !selected ? 'var(--color-text3)' : correct ? colors.success.dark : colors.error.dark
        const lightColor = !selected ? 'var(--color-text3)' : correct ? colors.success.light : colors.error.light

        return (
          <li key={index} style={{ marginBottom: '.3rem' }}>
            <button style={{ width: '100%', textAlign: 'left', padding: '1rem', borderRadius: '.5rem', backgroundColor: selected ? lightColor : 'transparent', border: "none", cursor: 'pointer', fontSize: '1rem', display: 'flex', position: 'relative', color: selected ? darkColor : 'var(--color-text3)', gap: '1rem', alignItems: 'center', lineHeight: '1.5', justifyContent: 'flex-start' }}
              onClick={() => handleAnswerSelect(index)}
            >
              {selected ? (
                correct ? (
                  <CheckCircle size={20} style={{ color: colors.success.dark }} />
                ) : (
                  <X size={20} style={{ color: colors.error.dark }} />
                )
              ) : (
                <Circle size={20} style={{ color: 'var(--color-text3)' }} strokeWidth={1.5} />
              )}
              {answer.text}
            </button>
          </li>
        )
      })}
    </ul>
  );
};


const Study_Quiz = ({ isActive, activeDocumentId }) => {
  const { user, } = useContext(UserContext)
  const { isPhoneAndBelow } = useBreakpoint()
  const [ data, setData ] = useState([])
  const [ selectedQuestionIndex, setSelectedQuestionIndex ] = useState(0)
  const [ error, setError ] = useState(false)

  useEffect(() => {
    getData()
    setSelectedQuestionIndex(0)
  }, [activeDocumentId])

  const getData = async () => {
    try {
      let result;

      if ( activeDocumentId ) {
        result = await axios.get(`${API}/generalFindOne`, { params: { 
          queryObj: JSON.stringify({ _id: activeDocumentId }),
          dbRef: dbRefs.resources
        }})
      }
      else {
        result = await axios.get(`${API}/generalFindOne`, { params: { 
          queryObj: JSON.stringify({ user_id: user?._id, ['quiz.content']: { $exists: true } }),
          optionsObj: JSON.stringify({ sort: { date: -1 }, limit: 1 }),
          dbRef: dbRefs.resources
        }})
      }

      let results = result?.data
      let content = results?.quiz?.content

      if ( content ) {
        setData(content)
      }
      else {
        setData([...sampleQuizData])
      }
      setError(false)
    }
    catch (error) {
      console.error(error)
      setData([...sampleQuizData])
      // setError(error)
    }
  }

  const onNextQuestion = () => {
    if (selectedQuestionIndex < data.length - 1) {
      setSelectedQuestionIndex((prevIndex) => (prevIndex + 1))
    }
    else {
      setSelectedQuestionIndex(0)
    }

    mixpanel.track(MIXPANEL_EVENTS.quiz_next, {
      user_id: user?._id,
      user_email: user?.email,
    });
  }

  const onPreviousQuestion = () => {
    if (selectedQuestionIndex > 0) {
      setSelectedQuestionIndex((prevIndex) => (prevIndex - 1))
    }
    
    mixpanel.track(MIXPANEL_EVENTS.quiz_next, {
      user_id: user?._id,
      user_email: user?.email,
    });
  }

  const ErrorComponent = () => {
    return (
      <button style={{ display: "flex", width: "15rem", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: '1rem', margin: 0, background: 'none', border: 'none', cursor: 'pointer', gap: '.5rem', backgroundColor: `var(--color-primaryLight)`, borderRadius: '100px', alignSelf: 'center', marginTop: '2rem' }}
        onClick={getData}
      >
      <p style={{ fontSize: '1rem', color: 'var(--color-primary)' }}
        >Click to refresh
      </p>
        <RotateCcw size={16} color={'var(--color-primary)'}/>
      </button>
    )
  }


  if ( !isActive ) return null
  if ( error ) return <ErrorComponent />
  if ( !data || data.length === 0 ) return null

  return (
    <div style={{ textAlign: "left", alignItems: "flex-start", padding: isPhoneAndBelow ? '1.5rem' : '2rem', backgroundColor: 'white', borderRadius: '1rem', border: '1px solid var(--color-separatorOpaque)',  }}>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1.5rem' }}>
        <span style={{ fontSize: '.9rem', color: 'var(--color-text3)' }}>Question {selectedQuestionIndex + 1} of { data?.length || 0 }</span>
      </div>

      <h2 style={{ fontSize: '1.1rem', fontWeight: 'bold', marginBottom: '1rem', lineHeight: '1.5' }}
        >{data[selectedQuestionIndex]?.question}
      </h2>

      <Answers item={data[selectedQuestionIndex]} index={selectedQuestionIndex} />

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem', gap: '.5rem' }}>
        {selectedQuestionIndex > 0 && (
          <button style={{...buttonStyle, backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', color: 'var(--color-text3)'}} 
            onClick={() => onPreviousQuestion()}
            >Previous
          </button>
        )}
        <button style={{ ...buttonStyle, }} 
          onClick={() => onNextQuestion()}
          >Next
        </button>
      </div>
    </div>
    )

}

export default Study_Quiz;


            {/* <button style={buttonStyle} 
              onClick={() => setShowResult(true)}
              >Explain
            </button> */}