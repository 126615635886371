import React, { useContext, useEffect, useState } from 'react';
import { API, dbRefs, RESOURCES_UPLOAD_METHODS } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import ScreenHeader from '../components/ScreenHeader';
import { getTextFromResource, initResourceObj, uploadResourceToS3 } from '../misc/utils';
import { ChevronDown, Edit, Plus } from 'lucide-react';
import SelectResourceModal from '../modals/SelectResourceModal';
import ContentCreateModal from '../modals/ContentCreateModal';
import Resource_Flashcards from '../components/Resource_Flashcards';

const sampleData = [
  { term: 'Classical Conditioning', description: 'A learning process where a neutral stimulus becomes associated with a naturally occurring stimulus to produce a similar response. Example: Pavlov\'s dogs salivating at the sound of a bell.' },
  { term: 'Cognitive Dissonance', description: 'Mental discomfort experienced when holding contradictory beliefs or ideas, often leading to changes in attitudes or behaviors to reduce the discomfort.' },
  { term: 'Mitochondria', description: 'The powerhouse of the cell - organelles that generate most of the cell\'s supply of ATP (adenosine triphosphate), used as a source of chemical energy.' },
  { term: 'Natural Selection', description: 'The process by which organisms better adapted to their environment survive and produce more offspring, driving evolution over time.' },
  { term: 'Industrial Revolution', description: 'Period of rapid industrialization during the late 18th and early 19th centuries, marked by the transition from manual production to machine manufacturing, beginning in Great Britain.' },
  { term: 'Cold War', description: 'Period of geopolitical tension and competition between the United States and Soviet Union from 1947-1991, characterized by proxy wars and an arms race.' },
  { term: 'Magical Realism', description: 'A literary style that incorporates fantastic or mythical elements into otherwise realistic fiction. Examples include works by Gabriel García Márquez.' },
  { term: 'Stream of Consciousness', description: 'A narrative technique that presents a character\'s thoughts and feelings as they occur, often without logical order or syntax. Used by authors like James Joyce and Virginia Woolf.' },
  { term: 'Opportunity Cost', description: 'The value of the next-best alternative foregone when making a choice. Example: Studying for an exam instead of working means losing potential wages.' },
  { term: 'Supply and Demand', description: 'Economic model describing how the price of a good is determined by the interaction between buyers\' willingness to pay and sellers\' willingness to provide the good.' },
  { term: 'Entropy', description: 'A measure of disorder or randomness in a system. The Second Law of Thermodynamics states that entropy of an isolated system always increases over time.' },
  { term: 'Valence Electrons', description: 'Electrons in the outer shell of an atom that can participate in forming chemical bonds with other atoms.' },
  { term: 'Categorical Imperative', description: 'Immanuel Kant\'s fundamental principle of ethics: act only according to rules you could will to become universal laws.' },
  { term: 'Existentialism', description: 'Philosophical movement emphasizing individual existence, freedom, and choice. Key figures include Jean-Paul Sartre and Simone de Beauvoir.' },
  { term: 'Pythagorean Theorem', description: 'In a right triangle, the square of the hypotenuse (longest side) equals the sum of squares of the other two sides: a² + b² = c².' },
  { term: 'Derivative', description: 'A measure of how a function changes as its input changes. Represents the slope of the tangent line at any point on a curve.' },
  { term: 'Chiaroscuro', description: 'The use of strong contrasts between light and dark in art, especially effective for creating volume and dramatizing scenes.' },
  { term: 'Impressionism', description: 'Late 19th-century art movement characterized by small, visible brushstrokes and emphasis on light\'s changing qualities. Notable artists include Monet and Renoir.' },
  { term: 'Social Stratification', description: 'The hierarchical arrangement of social classes, castes, and strata within a society, often leading to differential access to resources and opportunities.' },
  { term: 'Cultural Relativism', description: 'The principle that beliefs and practices of a society should be understood within their own cultural context rather than judged by the standards of another.' },
  { term: 'Red Shift', description: 'The lengthening of light waves from an object moving away from the observer, used to determine that the universe is expanding.' },     
  { term: 'Event Horizon', description: 'The boundary around a black hole beyond which nothing, not even light, can escape its gravitational pull.' },
  { term: 'Counterpoint', description: 'The relationship between multiple musical lines that are harmonically interdependent but independent in rhythm and melodic contour.' }, 
  { term: 'Sonata Form', description: 'A musical structure consisting of exposition, development, and recapitulation, commonly used in classical symphonies and sonatas.' },
];

const FlashcardsScreen = () => {
  const { user, checkUserPermission } = useContext(UserContext)
  const [ data, setData ] = useState(null)
  const [ activeDocumentId, setActiveDocumentId ] = useState('')
  const [ selectResourceModalOpen, setSelectResourceModalOpen ] = useState(false)
  const [ contentCreateModalOpen, setContentCreateModalOpen ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ loading, setLoading ] = useState(false)

  const subheaderData = [
    { title: loading ? 'Loading...' : data?.title || 'All flashcards', icon: <ChevronDown/>, onClick: () => {
      if (!checkUserPermission()) return;
      setSelectResourceModalOpen(true)
    } },
    // { title: 'Edit test', icon: <Edit/>, onClick: () => setSelectResourceModalOpen(true) },
    { title: 'Create New', icon: <Plus/>, onClick: () => {
      if (!checkUserPermission()) return;
      setContentCreateModalOpen(true)
    } },
  ]


  useEffect(() => {
    getData()
  }, [activeDocumentId])
  
  const getData = async () => {
    let results;

    try {
      if ( user ) {
        if ( activeDocumentId ) {
          const result = await axios.get(`${API}/generalFindOne`, { params: { queryObj: JSON.stringify({ _id: activeDocumentId }), dbRef: dbRefs.resources }})
          results = result?.data
          console.log('activeid', results)
        }
        else {
          const result = await axios.get(`${API}/generalFindOne`, { params: { 
            queryObj: JSON.stringify({ user_id: user?._id, ['flashcards.content']: { $exists: true } }),
            optionsObj: JSON.stringify({ sort: { date: -1 }, limit: 1 }),
            dbRef: dbRefs.resources
          }})
          results = result?.data

          if ( !results?._id ) {
            results = { _id: "Demo", title: "Example Flashcards", flashcards: { content: sampleData } }
          }
        }
      }
      else {
        results = { _id: "Demo", title: "Example Flashcards", flashcards: { content: sampleData } }
      }
      setData(results)
    }
    catch (error) {
      setError(true)
    }
  }


  ////////////////////////////// CREATE //////////////////////////////

  const handleCreateContent = async ({ resourceObj, file, textInput }) => {      
    if (!checkUserPermission()) return;

    try {
      setLoading(true)
      let topic = ''
      let text = ''

      if ( resourceObj ) {
        if ( resourceObj?.flashcards?.content ) {
          setActiveDocumentId(resourceObj._id)
          return
        }
        else {
          text = await getTextFromResource(resourceObj)
          await axios.post(`${API}/generateFlashcards`, { text: text, topic: topic, resource_id: resourceObj._id });
          setActiveDocumentId(resourceObj._id)
          return
        }
      }

      else if ( file ) {
        const doc = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
        const resource = await uploadResourceToS3({ resource_id: doc._id, file, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
        const sourceContent = await axios.post(`${API}/generateSourceContent`, { resource_id: doc._id, userCategories: user.categories  })
        text = sourceContent?.data?.content
        await axios.post(`${API}/generateFlashcards`, { text: text, topic: topic, resource_id: doc._id });
        setActiveDocumentId(doc._id)
        return
      }
      else if ( textInput ) {
        
        const doc = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
        const resource = await uploadResourceToS3({ resource_id: doc._id, file: textInput, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
        const sourceContent = await axios.post(`${API}/generateSourceContent`, { resource_id: doc._id, userCategories: user.categories  })
        topic = textInput
        await axios.post(`${API}/generateFlashcards`, { text: text, topic: topic, resource_id: doc._id });
        setActiveDocumentId(doc._id)
        return
      }
      
    } 
    catch (error) {
      setError(true)
    }
    finally {
      setLoading(false)
    }
  }

  ////////////////////////////// EXISTING TABLE //////////////////////////////
  


  return (
    <div id="wrapper">          
      <div id="main">
        <div className="inner" >

          <ScreenHeader title="Flashcards" />

          <div style={{ display: 'flex', gap: '.5rem', marginBottom: '1rem' }}>
            { subheaderData.map((item, index) => (
              <div key={index} style={{ flex: 1, padding: '1rem', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '30px', border: '1px solid var(--color-separatorOpaque)', color: 'var(--color-text1)', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between', minWidth: 0 }}
              onClick={item.onClick}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
            >
              <span style={{ fontSize: '1rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginRight: '1rem' }}>{ item.title } </span>
              {React.cloneElement(item.icon, { size: 20, color: 'var(--color-text3)', strokeWidth: 1.5, flexShrink: 0 })}
            </div>
            )) }
          </div>


          { loading ? 
            <div style={{ display: 'flex', justifyContent: 'center', }}>
              <div style={{ width: '30px', height: '30px', borderTop: '3px solid var(--color-primary)', borderRadius: '50%', animation: 'spin 1s linear infinite', marginTop: '1.5rem' }}>
                <style>{`@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }`}</style>
              </div>
            </div>


          : error ?
            <p style={{ color: 'red', textAlign: 'center', marginTop: '1rem' }}>An error occurred. Please try again.</p>
          :
            <Resource_Flashcards
              data={data}
              setData={setData}
              setRefreshData={() => {}}
            />
          }

 
          <SelectResourceModal
            open={selectResourceModalOpen}
            setOpen={setSelectResourceModalOpen}
            onSelect={(item) => handleCreateContent({ resourceObj: item })}
          />

          <ContentCreateModal
            open={contentCreateModalOpen}
            setOpen={setContentCreateModalOpen}
            onSubmitText={(item) => handleCreateContent({ textInput: item })}
            onSubmitFile={(item) => handleCreateContent({ file: item })}
            header="Create flashcards on..."
            placeholder="Example: the process of cellular mitosis"
          />

        </div>
      </div>
    </div>
  );  
}


export default FlashcardsScreen;
