import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, LinearProgress } from '@mui/material';

export const AudioRecorder_CustomButton = ({ onClick, text, color, style, icon }) => {
  return (
    <Button
      sx={[{
        backgroundColor: color || `var(--color-backgroundSecondary)`,
        color: `var(--color-text3)`,
        fontSize: "1rem",
        fontWeight: "500",
        textTransform: "capitalize",
        borderRadius: "10px",
        padding: ".8rem 1.2rem",
        minWidth: "120px",
        transition: 'transform 0.3s ease-out',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          backgroundColor: color || `var(--color-backgroundSecondary)`,
          transform: 'scale(1.05)',
        },
      }, style]}
      variant="contained"
      onClick={onClick}
      aria-label={text}
    >
      {/* Removed the extra div wrapper since Button already has flex properties */}
      {icon && (
        <span 
          className="button-icon" 
          aria-hidden="true" 
          style={{ 
            fontSize: '1rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {icon}
        </span>
      )}
      <span style={{ display: 'flex', alignItems: 'center' }}>{text}</span>
    </Button>
  )
}