import React, { useContext, useEffect, useState, useCallback } from 'react';
import { API, dbRefs, RESOURCES_UPLOAD_METHODS, S3_URL_PREFIX } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import ScreenHeader from '../components/ScreenHeader';
import Subheader from '../components/Subheader';
import { v4 as uuidv4 } from 'uuid';
import { getTextFromResource, initResourceObj, uploadResourceToS3 } from '../misc/utils';
import DocumentCreate from '../components/DocumentCreate';
import ResourcesTable from '../components/ResourcesTable';
import Resource_Quiz from '../components/Resource_Quiz';
import ChatGptInterface from '../components/ChatGPTInterface';
import { ChevronDown, Edit, Plus } from 'lucide-react';
import SelectResourceModal from '../modals/SelectResourceModal';
import ContentCreateModal from '../modals/ContentCreateModal';

const sampleQuizData = [
  {
    question: "Which scientific principle states that the entropy of an isolated system always increases over time?",
    answers: [
      { text: "First Law of Thermodynamics", isCorrect: false },
      { text: "Second Law of Thermodynamics", isCorrect: true },
      { text: "Law of Conservation of Energy", isCorrect: false },
      { text: "Newton's Third Law", isCorrect: false }
    ]
  },
  {
    question: "Who is associated with the concept of the Categorical Imperative in moral philosophy?",
    answers: [
      { text: "Jean-Paul Sartre", isCorrect: false },
      { text: "Friedrich Nietzsche", isCorrect: false },
      { text: "Immanuel Kant", isCorrect: true },
      { text: "John Locke", isCorrect: false }
    ]
  },
  {
    question: "Which psychological phenomenon describes the mental discomfort experienced when holding contradictory beliefs?",
    answers: [
      { text: "Cognitive Dissonance", isCorrect: true },
      { text: "Classical Conditioning", isCorrect: false },
      { text: "Confirmation Bias", isCorrect: false },
      { text: "Operant Conditioning", isCorrect: false }
    ]
  },
  {
    question: "What astronomical phenomenon is used to determine that the universe is expanding?",
    answers: [
      { text: "Blue Shift", isCorrect: false },
      { text: "Red Shift", isCorrect: true },
      { text: "Solar Parallax", isCorrect: false },
      { text: "Stellar Aberration", isCorrect: false }
    ]
  },
  {
    question: "Which artistic movement is characterized by small, visible brushstrokes and emphasis on light's changing qualities?",
    answers: [
      { text: "Cubism", isCorrect: false },
      { text: "Surrealism", isCorrect: false },
      { text: "Impressionism", isCorrect: true },
      { text: "Abstract Expressionism", isCorrect: false }
    ]
  },
  {
    question: "In economics, what term describes the value of the next-best alternative foregone when making a choice?",
    answers: [
      { text: "Sunk Cost", isCorrect: false },
      { text: "Opportunity Cost", isCorrect: true },
      { text: "Marginal Cost", isCorrect: false },
      { text: "Variable Cost", isCorrect: false }
    ]
  },
  {
    question: "What literary technique presents a character's thoughts and feelings as they occur, often without logical order?",
    answers: [
      { text: "Stream of Consciousness", isCorrect: true },
      { text: "Magical Realism", isCorrect: false },
      { text: "Dramatic Monologue", isCorrect: false },
      { text: "Free Verse", isCorrect: false }
    ]
  },
  {
    question: "Which organelle is known as the 'powerhouse of the cell' because it generates ATP?",
    answers: [
      { text: "Golgi Apparatus", isCorrect: false },
      { text: "Endoplasmic Reticulum", isCorrect: false },
      { text: "Mitochondria", isCorrect: true },
      { text: "Lysosome", isCorrect: false }
    ]
  },
  {
    question: "What period of geopolitical tension existed between the United States and Soviet Union from 1947-1991?",
    answers: [
      { text: "World War II", isCorrect: false },
      { text: "The Great Depression", isCorrect: false },
      { text: "The Cold War", isCorrect: true },
      { text: "The Space Race", isCorrect: false }
    ]
  },
  {
    question: "In music theory, what term describes the relationship between multiple musical lines that are harmonically interdependent but independent in rhythm?",
    answers: [
      { text: "Harmony", isCorrect: false },
      { text: "Counterpoint", isCorrect: true },
      { text: "Polyphony", isCorrect: false },
      { text: "Sonata Form", isCorrect: false }
    ]
  }
];

const QuizScreen = () => {
  const { user, checkUserPermission } = useContext(UserContext)
  const [ data, setData ] = useState(null)
  const [ activeDocumentId, setActiveDocumentId ] = useState('')
  const [ selectResourceModalOpen, setSelectResourceModalOpen ] = useState(false)
  const [ contentCreateModalOpen, setContentCreateModalOpen ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ loading, setLoading ] = useState(false)

  const subheaderData = [
    { title: loading ? 'Loading...' : data?.title || 'All tests', icon: <ChevronDown/>, onClick: () => {
      if (!checkUserPermission()) return;
      setSelectResourceModalOpen(true)
    } },
    // { title: 'Edit test', icon: <Edit/>, onClick: () => setSelectResourceModalOpen(true) },
    { title: 'Create New', icon: <Plus/>, onClick: () => {
      if (!checkUserPermission()) return;
      setContentCreateModalOpen(true)
    } },
  ]


  useEffect(() => {
    getData()
  }, [activeDocumentId])
  
  const getData = async () => {
    let results;

    try {
      if ( user ) {
        if ( activeDocumentId ) {
          const result = await axios.get(`${API}/generalFindOne`, { params: { queryObj: JSON.stringify({ _id: activeDocumentId }), dbRef: dbRefs.resources }})
          results = result?.data
          console.log('activeid', results)
        }
        else {
          const result = await axios.get(`${API}/generalFindOne`, { params: { 
            queryObj: JSON.stringify({ user_id: user?._id, ['quiz.content']: { $exists: true } }),
            optionsObj: JSON.stringify({ sort: { date: -1 }, limit: 1 }),
            dbRef: dbRefs.resources
          }})
          results = result?.data

          if ( !results?._id ) {
            results = { _id: "Demo", title: "Example Quiz", quiz: { content: sampleQuizData } }
          }
        }
      }
      else {
        results = { _id: "Demo", title: "Example Quiz", quiz: { content: sampleQuizData } }
      }
      setData(results)
    }
    catch (error) {
      setError(true)
    }
  }


  ////////////////////////////// CREATE //////////////////////////////

  const handleCreateContent = async ({ resourceObj, file, textInput }) => {      
    setLoading(true)
    try {
      let topic = ''
      let text = ''

      if ( resourceObj ) {
        if ( resourceObj?.quiz?.content ) {
          setActiveDocumentId(resourceObj._id)
          return
        }
        else {
          text = await getTextFromResource(resourceObj)
          await axios.post(`${API}/generateQuiz`, { text: text, topic: topic, resource_id: resourceObj._id });
          setActiveDocumentId(resourceObj._id)
          return
        }
      }

      else if ( file ) {
        const doc = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
        const resource = await uploadResourceToS3({ resource_id: doc._id, file, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
        const sourceContent = await axios.post(`${API}/generateSourceContent`, { resource_id: doc._id, userCategories: user.categories  })
        text = sourceContent?.data?.content
        await axios.post(`${API}/generateQuiz`, { text: text, topic: topic, resource_id: doc._id });
        setActiveDocumentId(doc._id)
        return
      }
      else if ( textInput ) {
        
        const doc = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
        const resource = await uploadResourceToS3({ resource_id: doc._id, file: textInput, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
        const sourceContent = await axios.post(`${API}/generateSourceContent`, { resource_id: doc._id, userCategories: user.categories  })
        topic = textInput
        await axios.post(`${API}/generateQuiz`, { text: text, topic: topic, resource_id: doc._id });
        setActiveDocumentId(doc._id)
        return
      }
      
    } 
    catch (error) {
      setError(true)
    }
    finally {
      setLoading(false)
    }
  }

  ////////////////////////////// EXISTING TABLE //////////////////////////////
  


  return (
    <div id="wrapper">          
      <div id="main">
        <div className="inner" >

          <ScreenHeader title="Practice tests" />

          <div style={{ display: 'flex', gap: '.5rem', marginBottom: '1rem' }}>
            { subheaderData.map((item, index) => (
              <div key={index} style={{ flex: 1, padding: '1rem', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '30px', border: '1px solid var(--color-separatorOpaque)', color: 'var(--color-text1)', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between', minWidth: 0 }}
                onClick={item.onClick}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
              >
                <span style={{ fontSize: '1rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginRight: '1rem' }}>{ item.title } </span>
                {React.cloneElement(item.icon, { size: 20, color: 'var(--color-text3)', strokeWidth: 1.5, flexShrink: 0 })}
              </div>
            )) }
          </div>


          { loading ? 
            <div style={{ display: 'flex', justifyContent: 'center', }}>
              <div style={{ width: '30px', height: '30px', borderTop: '3px solid var(--color-primary)', borderRadius: '50%', animation: 'spin 1s linear infinite', marginTop: '1.5rem' }}>
                <style>{`@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }`}</style>
              </div>
            </div>

          : error ?
            <p style={{ color: 'red', textAlign: 'center', marginTop: '1rem' }}>An error occurred. Please try again.</p>
          :
            <Resource_Quiz
              data={data}
              setData={setData}
              setRefreshData={() => {}}
            />
          }

 
          <SelectResourceModal
            open={selectResourceModalOpen}
            setOpen={setSelectResourceModalOpen}
            // onSelect={(item) => setActiveDocumentId(item._id)}
            onSelect={(item) => handleCreateContent({ resourceObj: item })}
          />

          <ContentCreateModal
            open={contentCreateModalOpen}
            setOpen={setContentCreateModalOpen}
            onSubmitText={(item) => handleCreateContent({ textInput: item })}
            onSubmitFile={(item) => handleCreateContent({ file: item })}
            header="Test me on..."
            placeholder="Example: the causes of World War I"
          />

        </div>
      </div>
    </div>
  );  
}


export default QuizScreen;






// const handleCreateDocument = async (selectedNotesId, selectedFile, selectedText, optionsFiles) => {      
//   try {
//     let resource_id = uuidv4()
//     let topic = ''
//     let text = ''

//     if ( selectedNotesId ) {
//       const file = optionsFiles.find(f => f._id === selectedNotesId)

//       if ( file?.quiz?.content ) {
//         setActiveDocumentId(file._id)
//         return
//       }
//       else {
//         text = await getTextFromResource(file)
//       }
//     }

//     else if ( selectedFile ) {
//       await initResourceObj({ user, resource_id, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
//       await uploadResourceToS3({ resource_id, file: selectedFile, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
//       const sourceContent = await axios.post(`${API}/generateSourceContent`, { resource_id, userCategories: user.categories  })
//       text = sourceContent?.data?.content
//     }
//     else if ( selectedText ) {
//       await initResourceObj({ user, resource_id, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
//       await uploadResourceToS3({ resource_id, file: selectedText, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
//       await axios.post(`${API}/generateSourceContent`, { resource_id, userCategories: user.categories  })
//       topic = selectedText
//     }

//     const result = await axios.post(`${API}/generateQuiz`, { text: text, topic: topic, resource_id: resource_id });
//     const results = result?.data?.content?.content

//     if ( results ) {
//       await axios.put(`${API}/generalUpdateOne`, {
//         matchObj: { _id: resource_id },
//         updateObj: { $set: { ['quiz.content']: results } },
//         dbRef: dbRefs.resources,
//       });

//       setActiveDocumentId(resource_id)
//     }
//     else {
//       throw new Error('No questions found')
//     }
//   } 
//   catch (error) {
//     throw error
//   }
// }