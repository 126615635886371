import React from 'react';
import { useBreakpoint } from '../misc/useBreakpoint';

export const ScreenHeader_Button = ({ title, icon, onClick }) => {
  return (
    <button style={{padding: "5px 10px", cursor: 'pointer', borderRadius: '20px', display: 'flex', alignItems: 'center', border: '1px solid var(--color-separatorOpaque)', backgroundColor: 'transparent', transition: 'all 0.3s', fontSize: '14px', fontWeight: '500', color: 'var(--color-text3)', marginLeft: "4px", gap: "5px"}}
      aria-label={title}
      onClick={onClick}
      onMouseOver={(e) => { e.currentTarget.style.transform = 'scale(1.05)'; }}
      onMouseOut={(e) => { e.currentTarget.style.transform = 'scale(1)'; }}
    >
      {icon && <span aria-hidden="true">{icon}</span>}
      <span >{title}</span>
    </button>
  )
}

const ScreenHeader = ({ title, leftChildren, children, style }) => {
  const { isDesktop, isTabletAndBelow } = useBreakpoint();

  if ( isDesktop ) {
  return (
    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "1.5rem", width: "100%", ...style, }}>

      <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", }}>
        { title &&
          <span style={{ color: `var(--color-text3)`, textAlign: "left", fontSize: "1.25rem", fontWeight: "600", marginRight: "5px",  }}
            >{title}
          </span>
        }
        { leftChildren }
      </div>
      {children}
      </div>
    );
  }
  else if ( isTabletAndBelow && ( leftChildren || children ) ) {
    return (
      <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "1.5rem", width: "100%", ...style, }}>
        {leftChildren}
        {children}
      </div>
    );
  }
}

export default ScreenHeader;