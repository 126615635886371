import React, { useState, useEffect } from 'react';

const TermsPartnerProgramScreen = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setIsLoading(true);
      const res = await fetch('https://academicweapon.s3.amazonaws.com/legal/Partner+Program+Terms+(2024.10.08).txt');
      if (!res.ok) {
        throw new Error('Failed to fetch partner program agreement');
      }
      const termsData = await res.text();
      setData(termsData);
    } 
    catch (err) {
      setError(err.message);
    } 
    finally {
      setIsLoading(false);
    }
  }

  return (
    <main style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "50px"}}>
      <h1 id="partner-program-title">Partner Program Agreement</h1>
      {isLoading && <p aria-live="polite">Loading partner program agreement...</p>}
      {error && <p aria-live="assertive" role="alert">Error: {error}</p>}
      {data && (
        <section aria-labelledby="partner-program-title">
          <p  style={{whiteSpace: "pre-wrap", fontSize: "16px", color: "var(--color-text1)", margin: "0 auto", padding: "20px", }}
            tabIndex="0" 
            role="region" 
            aria-label="Partner Program Agreement content"
          >
            {data}
          </p>
        </section>
      )}
    </main>
  );
};

export default TermsPartnerProgramScreen;